import { Injectable, HostListener } from '@angular/core';
import { CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { Location } from '@angular/common';
import { CommonService } from '../_services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router:Router,
        private auth:AuthenticationService,
        private route:ActivatedRoute,
        private location : Location,
        private common:CommonService
    ) { }
   
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        localStorage.setItem('url',state['url'])
        if (this.auth.hasAccessToken() ) {
            return true;
        }else{
            //this.router.navigate(['/login']);
            window.location.href=''+this.common.ssoUrl+''
        }
        
    }

    
}


