import { RouteInfo } from './navigation.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/starter', title: 'Home',titleHi: 'होम', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/aboutus', title: 'About Us',titleHi: 'हमारे बारे में', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    }, 
    {
        path: '/organisationalstructure', title: 'Organisational Structure',titleHi: 'संगठनात्मक संरचना', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/auditcalender', title: 'Audit Calender',titleHi: 'ऑडिट कैलेंडर', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/reports', title: 'Reports',titleHi: 'रिपोर्ट', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/downloads', title: 'Downloads',titleHi: 'डाउनलोड', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/contactus', title: 'Contact Us',titleHi: 'हमसे संपर्क करें', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/training', title: 'Video Tutorials',titleHi: 'वीडियो ट्यूटोरियल', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    } 
    
           
];

