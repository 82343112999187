import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AumlistComponent } from './dashboard/aumlist/aumlist.component';
import { AuthGuard } from './_guards/auth.guard';


export const Approutes: Routes = [
    { path: 'view-audit-report/:viewId/:lang', loadChildren: './view-audit-report/view-audit-report.module#ViewAuditReportModule',canActivate: [AuthGuard] },
    { path: 'tour-request-note/:viewId', loadChildren: './tour-request-note/tour-request-note.module#TourRequestNoteModule',canActivate: [AuthGuard] },
    { path: 'tour-approve-note/:viewId', loadChildren: './tour-approve-note/tour-approve-note.module#TourApproveNoteModule',canActivate: [AuthGuard] },
    { path: 'sso-login', loadChildren: './sso-login/sso-login.module#SsoLoginModule' },

{
    path: '',
    component: FullComponent,
    children: [
         { path: '', redirectTo: '/starter', pathMatch: 'full' },
        { path: 'starter', loadChildren: './starter/starter.module#StarterModule' },
        {path: 'aboutus', loadChildren: './aboutus/aboutus.module#AboutusModule' },
        { path: 'organisationalstructure', loadChildren: './organisationalstructure/organisationalstructure.module#OrganisationalstructureModule' },
        { path: 'auditcalender', loadChildren: './auditcalender/auditcalender.module#AuditcalenderModule' },
        { path: 'reports', loadChildren: './reports/reports.module#ReportsModule' },
        { path: 'downloads', loadChildren: './downloads/downloads.module#DownloadsModule' },
        { path: 'contactus', loadChildren: './contactus/contactus.module#ContactusModule' },
        { path: 'login', loadChildren: './login/login.module#LoginModule' },
        {path: 'rules-guidelines-list/:id', loadChildren: './starter/eorlsection/rules-guideline-list/rules-guideline-list.module#RulesGuidelineListModule' },
        {path: 'other-content-details/:id', loadChildren: './starter/eorlsection/other-content-details/other-content-details.module#OtherContentDetailsModule' },
        {path: 'all-latest-news', loadChildren: './starter/eorlsection/all-latest-news/all-latest-news.module#AllLatestNewsModule' },
        {path: 'latest-news-details/:id', loadChildren: './starter/eorlsection/latest-news-details/latest-news-details.module#LatestNewsDetailsModule' },
        {path: 'events-page', loadChildren: './starter/eorlsection/events-page/events-page.module#EventsPageModule' },
        { path: 'sso-user-profile', loadChildren: './sso-user-profile/sso-user-profile.module#SsoUserProfileModule' },
        {path : 'training', loadChildren : './training/training.module#TrainingModule'},
        {path : 'videos', loadChildren : './training/videos/videos.module#VideosModule'},




    ]
},

{
    path: '',
    component: DashboardComponent,
    children: [
        { path: '', redirectTo: '/dashboardstarter', pathMatch: 'full' },
        
        { path: 'dashboardstarter', loadChildren: './dashboard/dashboardmain/dashboardmain.module#DashboardmainModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

        { path: 'unit-dashboardstarter', loadChildren: './dashboard/unit-dashboardstarter/unit-dashboardstarter.module#UnitDashboardstarterModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

		{ path: 'finance-dashboardstarter', loadChildren: './dashboard/finance-dashboardstarter/finance-dashboardstarter.module#FinanceDashboardstarterModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

		{ path: 'party-dashboardstarter', loadChildren: './dashboard/party-dashboardstarter/party-dashboardstarter.module#PartyDashboardstarterModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

		{ path: 'allocated-unit', loadChildren: './dashboard/allocated-unit/allocated-unit.module#AllocatedUnitModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

		{ path: 'unit-audited-till-present-chart', loadChildren: './dashboard/unit-audited-till-present-chart/unit-audited-till-present-chart.module#UnitAuditedTillPresentChartModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

        { path: 'audit-stage-wise-chart/:districtId', loadChildren: './dashboard/audit-stage-wise-chart/audit-stage-wise-chart.module#AuditStageWiseChartModule' },

        { path: 'district-audit-stage-wise-chart/:regionId', loadChildren: './dashboard/district-audit-stage-wise-chart/district-audit-stage-wise-chart.module#DistrictAuditStageWiseChartModule' },

		{ path: 'recovered-amout-unit-typewise-particular-year', loadChildren: './dashboard/recovered-amout-unit-typewise-particular-year/recovered-amout-unit-typewise-particular-year.module#RecoveredAmoutUnitTypewiseParticularYearModule' },

		{ path: 'total-audited-unit-risk-wise-chart', loadChildren: './dashboard/total-audited-unit-risk-wise-chart/total-audited-unit-risk-wise-chart.module#TotalAuditedUnitRiskWiseChartModule' },

		{ path: 'outstanding-para-by-category-type-chart', loadChildren: './dashboard/outstanding-para-by-category-type-chart/outstanding-para-by-category-type-chart.module#OutstandingParaByCategoryTypeChartModule' },

		{ path: 'outstanding-paras-audit-yearwise-particular-unit-type', loadChildren: './dashboard/outstanding-paras-audit-yearwise-particular-unit-type/outstanding-paras-audit-yearwise-particular-unit-type.module#OutstandingParasAuditYearwiseParticularUnitTypeModule' },

		{ path: 'para-analysis-audit-year-wise-chart', loadChildren: './dashboard/para-analysis-audit-year-wise-chart/para-analysis-audit-year-wise-chart.module#ParaAnalysisAuditYearWiseChartModule' },

		{ path: 'observation-type-wise-year-wise-chart/:observationTypeId', loadChildren: './dashboard/observation-type-wise-year-wise-chart/observation-type-wise-year-wise-chart.module#ObservationTypeWiseYearWiseChartModule' },

		{ path: 'observation-amount-para-catagory-wise-chart', loadChildren: './dashboard/observation-amount-para-catagory-wise-chart/observation-amount-para-catagory-wise-chart.module#ObservationAmountParaCatagoryWiseChartModule' },

		{ path: 'outstanding-number-of-paras-administrative-department-wise', loadChildren: './dashboard/outstanding-number-of-paras-administrative-department-wise/outstanding-number-of-paras-administrative-department-wise.module#OutstandingNumberOfParasAdministrativeDepartmentWiseModule' },

		{ path: 'recovery-amount-of-complied-paras-unit-type-wise-of-selected-administration-dept', loadChildren: './dashboard/recovery-amount-of-complied-paras-unit-type-wise-of-selected-administration-dept/recovery-amount-of-complied-paras-unit-type-wise-of-selected-administration-dept.module#RecoveryAmountOfCompliedParasUnitTypeWiseOfSelectedAdministrationDeptModule' },

		{ path: 'unit-type-wise-pending-paras', loadChildren: './dashboard/unit-type-wise-pending-paras/unit-type-wise-pending-paras.module#UnitTypeWisePendingParasModule' },

		{ path: 'observation-amount-of-pending-paras-unit-type-wise-of-selected-administration-dept', loadChildren: './dashboard/observation-amount-of-pending-paras-unit-type-wise-of-selected-administration-dept/observation-amount-of-pending-paras-unit-type-wise-of-selected-administration-dept.module#ObservationAmountOfPendingParasUnitTypeWiseOfSelectedAdministrationDeptModule' },

		{ path: 'audit-status-chart', loadChildren: './dashboard/audit-status/audit-status.module#AuditStatusModule' },

		{ path: 'audit-status-rowise-chart', loadChildren: './dashboard/audit-status-rowise-chart/audit-status-rowise-chart.module#AuditStatusRowiseChartModule',canActivate: [AuthGuard] },

		{ path: 'district-wise-audit-status-chart', loadChildren: './dashboard/district-wise-audit-status-chart/district-wise-audit-status-chart.module#DistrictWiseAuditStatusChartModule' },

		{ path: 'audit-party-rowise', loadChildren: './dashboard/audit-party-rowise/audit-party-rowise.module#AuditPartyRowiseModule' },

		{ path: 'flying-squad-details-rowise', loadChildren: './dashboard/flying-squad-details-rowise/flying-squad-details-rowise.module#FlyingSquadDetailsRowiseModule' },

		{ path: 'total-outstanding-paras-administrative-department-wise-chart', loadChildren: './dashboard/total-outstanding-paras-chart/total-outstanding-paras-chart.module#TotalOutstandingParasChartModule' },

		{ path: 'observation-amount-of-pending-paras-administration-dept-wise', loadChildren: './dashboard/observation-amount-of-pending-paras-administration-dept-wise/observation-amount-of-pending-paras-administration-dept-wise.module#ObservationAmountOfPendingParasAdministrationDeptWiseModule' },

		{ path: 'party-details-rowise', loadChildren: './dashboard/party-details-rowise/party-details-rowise.module#PartyDetailsRowiseModule' },

		{ path: 'observation-amount-of-pending-paras-unit-type-wise', loadChildren: './dashboard/observation-amount-of-pending-paras-unit-type-wise/observation-amount-of-pending-paras-unit-type-wise.module#ObservationAmountOfPendingParasUnitTypeWiseModule' },

		{ path: 'observation-amount-outstanding-paras-unit-type-wise-year', loadChildren: './dashboard/observaction-amout-outstanding-paras-unit-type-wise-year/observaction-amout-outstanding-paras-unit-type-wise-year.module#ObservactionAmoutOutstandingParasUnitTypeWiseYearModule' },

		{ path: 'total-outstanding-paras-unit-type-wise-chart', loadChildren: './dashboard/total-outstanding-paras-unit-type-wise-chart/total-outstanding-paras-unit-type-wise-chart.module#TotalOutstandingParasUnitTypeWiseChartModule' },

		{ path: 'compliance-noncompliance-status-para-category-wise-chart', loadChildren: './dashboard/compliance-noncompliance-status-para-category-wise-chart/compliance-noncompliance-status-para-category-wise-chart.module#ComplianceNoncomplianceStatusParaCategoryWiseChartModule' },

		{ path: 'observation-amount-unit-observation-type-wise-chart', loadChildren: './dashboard/observation-amount-unit-observation-type-wise-chart/observation-amount-unit-observation-type-wise-chart.module#ObservationAmountUnitObservationTypeWiseChartModule' },

        { path: 'chart-table', loadChildren: './dashboard/chart-table/chart-table.module#ChartTableModule' },

        { path: 'check-data', loadChildren: './dashboard/check-data/check-data.module#CheckDataModule',canActivate: [AuthGuard] },

        { path: 'tour-note-list', loadChildren: './dashboard/tour-note-list/tour-note-list.module#TourNoteListModule',canActivate: [AuthGuard] },

        { path: 'add-tour-note', loadChildren: './dashboard/add-tour-note/add-tour-note.module#AddTourNoteModule',canActivate: [AuthGuard] },

        { path: 'tour-note-edit/:id', loadChildren: './dashboard/tour-note-edit/tour-note-edit.module#TourNoteEditModule',canActivate: [AuthGuard] },
        
        { path: 'trash', loadChildren: './dashboard/trash/trash.module#TrashModule',canActivate: [AuthGuard] },

        { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule',canActivate: [AuthGuard] },

        { path: 'aumlist', component: AumlistComponent ,canActivate: [AuthGuard]},

        { path: 'rolelist',loadChildren: './dashboard/rolelist/rolelist.module#RolelistModule',canActivate: [AuthGuard]},

        { path: 'createrole/:id', loadChildren:'./dashboard/createrole/createrole.module#CreateroleModule',canActivate: [AuthGuard]},

        { path: 'createrole', loadChildren:'./dashboard/createrole/createrole.module#CreateroleModule',canActivate: [AuthGuard]},


        { path: 'assesmentparameters',loadChildren: './dashboard/assesmentparameters/assesmentparameters.module#AssesmentparametersModule',canActivate: [AuthGuard]},

        { path: 'createassessmentparameters/:id', loadChildren:'./dashboard/createassessmentparameters/createassessmentparameters.module#CreateassessmentparametersModule',canActivate: [AuthGuard]},

        { path: 'createassessmentparameters', loadChildren:'./dashboard/createassessmentparameters/createassessmentparameters.module#CreateassessmentparametersModule',canActivate: [AuthGuard]},


        // { path: 'ChangePassword', loadChildren:'./dashboard/change-password/change-password.module#ChangePasswordModule',canActivate: [AuthGuard]},


        { path: 'permission/:id', loadChildren:'./dashboard/setpermission/setpermission.module#SetpermissionModule',canActivate: [AuthGuard]},
        { path: 'permission', loadChildren:'./dashboard/setpermission/setpermission.module#SetpermissionModule',canActivate: [AuthGuard]},


        { path: 'auditpartylist', loadChildren:'./dashboard/audit-party-list/audit-party-list.module#AuditPartyListModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always'},

        { path: 'createauditparty/:id', loadChildren:'./dashboard/createauditparty/createauditparty.module#createauditpartyModule',canActivate: [AuthGuard]},

        { path: 'createauditparty', loadChildren:'./dashboard/createauditparty/createauditparty.module#createauditpartyModule',canActivate: [AuthGuard]},

        { path: 'party-formation-new', loadChildren: './dashboard/party-formation-new/party-formation-new.module#PartyFormationNewModule',canActivate: [AuthGuard] },

        { path: 'show-risk-params', loadChildren:'./dashboard/riskassesmentauditunit/riskassesmentauditunit.module#RiskassesmentauditunitModule',canActivate: [AuthGuard]},


        // { path: 'auditplaninglist',loadChildren:'./dashboard/audit-planing-list/audit-planing-list.module#AuditPlaningListModule',canActivate: [AuthGuard]},

        // { path: 'addauditplanning',loadChildren:'./dashboard/riskassesauditunit/riskassesauditunit.module#RiskassesauditunitModule',canActivate: [AuthGuard]},


        { path: 'preassesmentlist',loadChildren:'./dashboard/preassesmentparameterlist/preassesmentparameters.module#PreassesmentparametersModule',canActivate: [AuthGuard]},

        { path: 'createpreassessmentparameters/:id', loadChildren:'./dashboard/createpreassessmentparameters/createpreassessmentparameters.module#CreatepreassessmentparametersModule',canActivate: [AuthGuard]},

        // /auditunitlist
        { path: 'auditcalendar/:id',loadChildren:'./dashboard/audit-calendar/audit-calendar.module#AuditCalendarModule',canActivate: [AuthGuard]},

        { path: 'auditcalendar',loadChildren:'./dashboard/audit-calendar/audit-calendar.module#AuditCalendarModule',canActivate: [AuthGuard]},

        // { path: 'audit-parties-mapped/:id/:PlanYear/:status', loadChildren:'./dashboard/audit-parties-mapped/audit-parties-mapped.module#AuditPartiesMappedModule',canActivate: [AuthGuard]},

        // { path: 'communication-to-units-parties/:id', loadChildren:'./dashboard/communicate-to-unit-party/communicate-to-unit-party.module#CommunicateToUnitPartyModule',canActivate: [AuthGuard]},


        { path: 'auditunitlist',loadChildren:'./dashboard/audit-unit-list/audit-unit-list.module#AuditUnitListModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always'},

        { path: 'createauditunit/:id', loadChildren:'./dashboard/create-audit-unit/create-audit-unit.module#CreateAuditUnitModule',canActivate: [AuthGuard]},

        { path: 'addnewplan', loadChildren:'./dashboard/add-new-plan/add-new-plan.module#AddNewPlanModule',canActivate: [AuthGuard]},

        { path: 'createauditunit', loadChildren:'./dashboard/create-audit-unit/create-audit-unit.module#CreateAuditUnitModule',canActivate: [AuthGuard]},

        { path: 'processheadlist', loadChildren:'./dashboard/processheadlist/processheadlist.module#ProcessheadlistModule',canActivate: [AuthGuard]},

        { path: 'addprocesshead',loadChildren:'./dashboard/addprocesshead/addprocesshead.module#AddprocessheadModule',canActivate: [AuthGuard]},

        { path: 'addprocesshead/:id', loadChildren:'./dashboard/addprocesshead/addprocesshead.module#AddprocessheadModule',canActivate: [AuthGuard]},

        { path: 'processlist', loadChildren:'./dashboard/processlist/processlist.module#ProcesslistModule',canActivate: [AuthGuard]},

        { path: 'addprocesslist',loadChildren:'./dashboard/addprocesslist/addprocesslist.module#AddprocesslistModule',canActivate: [AuthGuard]},

        { path: 'addprocesslist/:id', loadChildren:'./dashboard/addprocesslist/addprocesslist.module#AddprocesslistModule',canActivate: [AuthGuard]},

        { path: 'audit-unit-type-list', loadChildren:'./dashboard/audit-unit-type-list/audit-unit-type-list.module#AuditUnitTypeListModule',canActivate: [AuthGuard]},

        { path: 'add-audit-unit-type',loadChildren:'./dashboard/add-audit-unit-type/add-audit-unit-type.module#AddAuditUnitTypeModule',canActivate: [AuthGuard]},

        { path: 'add-audit-unit-type/:id', loadChildren:'./dashboard/add-audit-unit-type/add-audit-unit-type.module#AddAuditUnitTypeModule',canActivate: [AuthGuard]},

        // { path: 'audit-unit-selected/:planId/:regionId/:unitTypeId/:PlanYear/:status', loadChildren: './dashboard/audit-unit-selected/audit-unit-selected.module#AuditUnitSelectedModule',canActivate: [AuthGuard] },

        // { path: 'route-map/:planId', loadChildren: './dashboard/route-map/route-map.module#RouteMapModule',canActivate: [AuthGuard] },

        // { path: 'audit-unit-selected', loadChildren: './dashboard/audit-unit-selected/audit-unit-selected.module#AuditUnitSelectedModule',canActivate: [AuthGuard] },

        { path: 'process-question-management', loadChildren: './dashboard/process-question-management/process-question-management-list.module#ProcessQuestionManagementModule',canActivate: [AuthGuard] },
        { path: 'create-questions/:id', loadChildren: './dashboard/create-questions/create-questions.module#CreateQuestionsModule',canActivate: [AuthGuard] },
        { path: 'create-questions', loadChildren: './dashboard/create-questions/create-questions.module#CreateQuestionsModule',canActivate: [AuthGuard] },


        { path: 'execution-question-management', loadChildren: './dashboard/execution-question-management/execution-question-management.module#ExecutionQuestionManagementModule',canActivate: [AuthGuard] },
        { path: 'create-execution-questions/:id', loadChildren: './dashboard/create-execution-questions/create-execution-questions.module#CreateExecutionQuestionsModule',canActivate: [AuthGuard] },
        { path: 'create-execution-questions', loadChildren: './dashboard/create-execution-questions/create-execution-questions.module#CreateExecutionQuestionsModule',canActivate: [AuthGuard] },


        { path: 'workAllocation', loadChildren: './dashboard/workallocation/workallocation.module#WorkallocationModule',canActivate: [AuthGuard] },


        { path: 'risk-rating-configuration', loadChildren: './dashboard/risk-rating-configuration/risk-rating-configuration.module#RiskRatingConfigurationModule',canActivate: [AuthGuard] },

        { path: 'openingMeeting', loadChildren: './dashboard/opening-meeting-list/opening-meeting-list.module#OpeningMeetingListModule',canActivate: [AuthGuard] },

        { path: 'opening-meeting/:id', loadChildren: './dashboard/open-meeting/open-meeting.module#OpenMeetingModule',canActivate: [AuthGuard] },

        { path: 'opening-meeting', loadChildren: './dashboard/open-meeting/open-meeting.module#OpenMeetingModule',canActivate: [AuthGuard] },

        { path: 'execution-checklist', loadChildren: './dashboard/execution-checklist/execution-checklist.module#ExecutionChecklistModule',canActivate: [AuthGuard] },

        { path: 'add-execution-checklist/:id', loadChildren: './dashboard/add-execution-checklist/add-execution-checklist.module#AddExecutionChecklistModule',canActivate: [AuthGuard] },

        { path: 'add-execution-checklist', loadChildren: './dashboard/add-execution-checklist/add-execution-checklist.module#AddExecutionChecklistModule',canActivate: [AuthGuard] },

        { path: 'execution-checklist-question/:planAuditPartyMapId/:processId/:subProcessId/:checkListTypeId', loadChildren: './dashboard/execution-checklist-question/execution-checklist-question.module#ExecutionChecklistQuestionModule',canActivate: [AuthGuard] },

        { path: 'execution-checklist-question-memo/:id', loadChildren: './dashboard/execution-checklist-question-memo/execution-checklist-question-memo.module#ExecutionChecklistQuestionMemoModule',canActivate: [AuthGuard] },

        { path: 'risk-assesment-process', loadChildren: './dashboard/risk-assesment-process/risk-assesment-process.module#RiskAssesmentProcessModule',canActivate: [AuthGuard] },

        { path: 'add-risk-assesment-process', loadChildren: './dashboard/add-risk-assesment-process/add-risk-assesment-process.module#AddRiskAssesmentProcessModule',canActivate: [AuthGuard] },


        { path: 'risk-assesment-process-question/:planAuditPartyMapId/:processId', loadChildren: './dashboard/risk-assesment-process-question/risk-assesment-process-question.module#RiskAssesmentProcessQuestionModule',canActivate: [AuthGuard] },

        { path: 'convertMemoToPara', loadChildren: './dashboard/convert-memo-to-para/convert-memo-to-para.module#ConvertMemoToParaModule',canActivate: [AuthGuard] },

        { path: 'workManagement', loadChildren: './dashboard/work-management/work-management.module#WorkManagementModule',canActivate: [AuthGuard] },

        { path: 'create-work', loadChildren: './dashboard/create-work/create-work.module#CreateWorkModule',canActivate: [AuthGuard] },
        { path: 'create-work/:id', loadChildren: './dashboard/create-work/create-work.module#CreateWorkModule',canActivate: [AuthGuard] },

        { path: 'UserManagement', loadChildren: './dashboard/user-management/user-management.module#UserManagementModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
        

        { path: 'create-user', loadChildren: './dashboard/create-user/create-user.module#CreateUserModule',canActivate: [AuthGuard] },
        { path: 'create-user/:id', loadChildren: './dashboard/create-user/create-user.module#CreateUserModule',canActivate: [AuthGuard] },

        { path: 'memolist', loadChildren: './dashboard/audit-memo/audit-memo.module#AuditMemoModule',canActivate: [AuthGuard] },
        { path: 'memolistch', loadChildren: './dashboard/audit-memo/audit-memo.module#AuditMemoModule',canActivate: [AuthGuard] },
        { path: 'viewMemo/:id', loadChildren: './dashboard/view-memo/view-memo.module#ViewMemoModule',canActivate: [AuthGuard] },

        { path: 'paralist', loadChildren: './dashboard/para-list/para-list.module#ParaListModule',canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },

        { path: 'viewPara/:id', loadChildren: './dashboard/view-para/view-para.module#ViewParaModule',canActivate: [AuthGuard] },

        { path: 'forbidden', loadChildren: './dashboard/forbidden/forbidden.module#ForbiddenModule',canActivate: [AuthGuard] },

        { path: 'audit-report', loadChildren: './dashboard/audit-report/audit-report.module#AuditReportModule',canActivate: [AuthGuard] },

        { path: 'close-meeting', loadChildren: './dashboard/close-meeting-list/close-meeting-list.module#CloseMeetingListModule' },

        { path: 'add-close-meeting/:id', loadChildren: './dashboard/close-meeting/close-meeting.module#CloseMeetingModule',canActivate: [AuthGuard] },

        { path: 'add-close-meeting', loadChildren: './dashboard/close-meeting/close-meeting.module#CloseMeetingModule',canActivate: [AuthGuard] },

        { path: 'inbox', loadChildren: './dashboard/inbox/inbox.module#InboxModule',canActivate: [AuthGuard] },

        { path: 'sendmessage', loadChildren: './dashboard/sendmessage/sendmessage.module#SendmessageModule',canActivate: [AuthGuard] },

        { path: 'edit-report/:id', loadChildren: './dashboard/edit-report/edit-report.module#EditReportModule',canActivate: [AuthGuard] },

        { path: 'sent-items', loadChildren: './dashboard/sent-items/sent-items.module#SentItemsModule',canActivate: [AuthGuard] },

        { path: 'view-inbox/:id', loadChildren: './dashboard/view-inbox/view-inbox.module#ViewInboxModule',canActivate: [AuthGuard] },

        { path: 'inbox-para-compliance-details/:id', loadChildren: './dashboard/inbox-para-compliance-details/inbox-para-compliance-details.module#InboxParaComplianceDetailsModule',canActivate: [AuthGuard] },

        { path: 'inbox-submit-para-compliance-details/:id', loadChildren: './dashboard/inbox-submit-para-compliance-details/inbox-submit-para-compliance-details.module#InboxSubmitParaComplianceDetailsModule',canActivate: [AuthGuard] },

        { path: 'view-sent-item/:id', loadChildren: './dashboard/view-sent-item/view-sent-item.module#ViewSentItemModule',canActivate: [AuthGuard] },

        { path: 'my-team-task', loadChildren: './dashboard/team-task/team-task.module#TeamTaskModule',canActivate: [AuthGuard] },

        { path: 'view-team-task/:id', loadChildren: './dashboard/view-team-task/view-team-task.module#ViewTeamTaskModule',canActivate: [AuthGuard] },

        { path: 'view-work-allocation/:id', loadChildren: './dashboard/view-work-allocation/view-work-allocation.module#ViewWorkAllocationModule',canActivate: [AuthGuard] },

        { path: 'settings', loadChildren: './dashboard/setting/setting.module#SettingModule',canActivate: [AuthGuard] },

        { path: 'submit-para-compliance', loadChildren: './dashboard/para-compliance/para-compliance.module#ParaComplianceModule',canActivate: [AuthGuard] },

        { path: 'view-para-compliance/:id', loadChildren: './dashboard/view-para-compliance/view-para-compliance.module#ViewParaComplianceModule',canActivate: [AuthGuard] },

        { path: 'compliance', loadChildren: './dashboard/compliance/compliance.module#ComplianceModule',canActivate: [AuthGuard] },

        { path: 'view-compliance/:id', loadChildren: './dashboard/view-compliance/view-compliance.module#ViewComplianceModule',canActivate: [AuthGuard] },

        { path: 'due-list', loadChildren: './dashboard/due-list/due-list.module#DueListModule',canActivate: [AuthGuard] },

        { path: 'view-due-list/:id/:unitId', loadChildren: './dashboard/view-due-list/view-due-list.module#ViewDueListModule',canActivate: [AuthGuard] },

        { path: 'overdue-list', loadChildren: './dashboard/overdue-list/overdue-list.module#OverdueListModule',canActivate: [AuthGuard] },

        { path: 'view-overdue-list/:id/:unitId', loadChildren: './dashboard/view-overdue-list/view-overdue-list.module#ViewOverdueListModule',canActivate: [AuthGuard] },

        { path: 'daily-diary-list', loadChildren: './dashboard/daily-diary-list/daily-diary-list.module#DailyDiaryListModule',canActivate: [AuthGuard] },

        { path: 'add-daily-diary/:id', loadChildren: './dashboard/add-daily-diary/add-daily-diary.module#AddDailyDiaryModule',canActivate: [AuthGuard] },

        { path: 'add-daily-diary', loadChildren: './dashboard/add-daily-diary/add-daily-diary.module#AddDailyDiaryModule',canActivate: [AuthGuard] },

        { path: 'view-daily-diary/:id', loadChildren: './dashboard/view-daily-diary/view-daily-diary.module#ViewDailyDiaryModule',canActivate: [AuthGuard] },

        { path: 'route-map', loadChildren: './dashboard/route-map/route-map.module#RouteMapModule',canActivate: [AuthGuard] },

        { path: 'daily-diary-calender', loadChildren: './dashboard/daily-diary-calendar/daily-diary-calendar.module#DailyDiaryCalendarModule',canActivate: [AuthGuard] },

        { path: 'fs-compliance-list', loadChildren: './dashboard/fs-compliance-list/fs-compliance-list.module#FsComplianceListModule',canActivate: [AuthGuard] },

        { path: 'set_risk_assessment', loadChildren: './dashboard/set-risk-assessment/set-risk-assessment.module#SetRiskAssessmentModule',canActivate: [AuthGuard] },

        { path: 'grievance-list', loadChildren: './dashboard/grievance-list/grievance-list.module#GrievanceListModule',canActivate: [AuthGuard] },

        { path: 'add-grievance', loadChildren: './dashboard/add-grievance/add-grievance.module#AddGrievanceModule',canActivate: [AuthGuard] },

        { path: 'my-grievances', loadChildren: './dashboard/my-grievances/my-grievances.module#MyGrievancesModule',canActivate: [AuthGuard] },

        { path: 'view-grievance/:id', loadChildren: './dashboard/view-grievance/view-grievance.module#ViewGrievanceModule',canActivate: [AuthGuard] },

        { path: 'due-para-details/:id', loadChildren: './dashboard/due-para-details/due-para-details.module#DueParaDetailsModule',canActivate: [AuthGuard] },

        { path: 'overdue-para-details/:id', loadChildren: './dashboard/overdue-para-details/overdue-para-details.module#OverdueParaDetailsModule',canActivate: [AuthGuard] },

        { path: 'view-grievance-list/:id', loadChildren: './dashboard/view-grievance-list/view-grievance-list.module#ViewGrievanceListModule',canActivate: [AuthGuard] },

        { path: 'communicate-draft-para', loadChildren: './dashboard/communicate-draft-para/communicate-draft-para.module#CommunicateDraftParaModule',canActivate: [AuthGuard] },

        { path: 'report-published-para-list', loadChildren: './dashboard/publish-para/publish-para.module#PublishParaModule',canActivate: [AuthGuard] },

        { path: 'report-para-compliance-list', loadChildren: './dashboard/publish-para-compliance/publish-para-compliance.module#PublishParaComplianceModule',canActivate: [AuthGuard] },

        { path: 'comply-dp', loadChildren: './dashboard/comply-draft-para/comply-draft-para.module#ComplyDraftParaModule',canActivate: [AuthGuard] },

        { path: 'view-comply-draft-para/:id', loadChildren: './dashboard/view-comply-draft-para/view-comply-draft-para.module#ViewComplyDraftParaModule',canActivate: [AuthGuard] },

        { path: 'view-communicate-draft-para/:id', loadChildren: './dashboard/view-communicate-draft-para/view-communicate-draft-para.module#ViewCommunicateDraftParaModule',canActivate: [AuthGuard] },

        { path: 'view-publish-para/:id', loadChildren: './dashboard/view-publish-para/view-publish-para.module#ViewPublishParaModule',canActivate: [AuthGuard] },

        { path: 'manage-published-para', loadChildren: './dashboard/manage-publish-para/manage-publish-para.module#ManagePublishParaModule',canActivate: [AuthGuard] },

        { path: 'answer-manage-publish-para/:id', loadChildren: './dashboard/answer-manage-publish-para/answer-manage-publish-para.module#AnswerManagePublishParaModule',canActivate: [AuthGuard] },

        { path: 'view-manage-publish-para/:id', loadChildren: './dashboard/view-manage-publish-para/view-manage-publish-para.module#ViewManagePublishParaModule',canActivate: [AuthGuard] },

        { path: 'view-profile', loadChildren: './dashboard/view-profile/view-profile.module#ViewProfileModule',canActivate: [AuthGuard]},

        { path: 'archived-document', loadChildren: './dashboard/archived-document/archived-document.module#ArchivedDocumentModule',canActivate: [AuthGuard]},

        { path: 'document-catalogue', loadChildren: './dashboard/document-management/document-management.module#DocumentManagementModule',canActivate: [AuthGuard]},

        { path: 'add-document-catalogue/:id', loadChildren:'./dashboard/add-document-catalogue/add-document-catalogue.module#AddDocumentCatalogueModule',canActivate: [AuthGuard]},

        { path: 'add-document-catalogue', loadChildren: './dashboard/add-document-catalogue/add-document-catalogue.module#AddDocumentCatalogueModule',canActivate: [AuthGuard]},

        { path: 'view-fs-compliance/:id', loadChildren: './dashboard/view-fs-compliance/view-fs-compliance.module#ViewFsComplianceModule',canActivate: [AuthGuard]},

        { path: 'view-publish-para-compliance/:id', loadChildren: './dashboard/view-publish-para-compliance/view-publish-para-compliance.module#ViewPublishParaComplianceModule',canActivate: [AuthGuard]},

        { path: 'due-compliance', loadChildren: './dashboard/due-compliance/due-compliance.module#DueComplianceModule',canActivate: [AuthGuard]},

        { path: 'mis-overdue-list', loadChildren: './dashboard/mis-overdue-list/mis-overdue-list.module#MisOverdueListModule',canActivate: [AuthGuard]},

        { path: 'pending-audit-report', loadChildren: './dashboard/pending-audit-report/pending-audit-report.module#PendingAuditReportModule',canActivate: [AuthGuard]},

        { path: 'audit-report-issued', loadChildren: './dashboard/audit-report-issued/audit-report-issued.module#AuditReportIssuedModule',canActivate: [AuthGuard]},

        { path: 'audit-report-issued-regional-view/:regionId/:yearId', loadChildren: './dashboard/audit-report-issued-regional-view/audit-report-issued-regional-view.module#AuditReportIssuedRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'audit-report-issued-party-view/:districtId/:regionId/:yearId', loadChildren: './dashboard/audit-report-issued-party-view/audit-report-issued-party-view.module#AuditReportIssuedPartyViewModule',canActivate: [AuthGuard]},

        { path: 'audit-report-issued-unit-type-view/:partyId/:districtId/:regionId/:yearId', loadChildren: './dashboard/audit-report-issued-unit-type-view/audit-report-issued-unit-type-view.module#AuditReportIssuedUnitTypeViewModule',canActivate: [AuthGuard]},

        { path: 'audit-report-issued-unit-view/:unitTypeId/:partyId/:districtId/:regionId/:yearId', loadChildren: './dashboard/audit-report-issued-unit-view/audit-report-issued-unit-view.module#AuditReportIssuedUnitViewModule',canActivate: [AuthGuard]},

        { path: 'pending-paras-and-reports', loadChildren: './dashboard/pending-paras-and-reports/pending-paras-and-reports.module#PendingParasAndReportsModule',canActivate: [AuthGuard]},

        { path: 'ro-balance-sheet-report', loadChildren: './dashboard/ro-balance-sheet-report/ro-balance-sheet-report.module#ROBalanceSheetReportModule',canActivate: [AuthGuard]},

        { path: 'serious-para-compliance-overdue', loadChildren: './dashboard/serious-para-compliance-overdue/serious-para-compliance-overdue.module#SeriousParaComplianceOverdueModule',canActivate: [AuthGuard]},

        { path: 'serious-para-compliance-overdue-regional-view/:regionId/:yearId', loadChildren: './dashboard/serious-para-compliance-overdue-regional-view/serious-para-compliance-overdue-regional-view.module#SeriousParaComplianceOverdueRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'serious-para-compliance-overdue-unitType-view/:unitTypeId/:regionId/:yearId', loadChildren: './dashboard/serious-para-compliance-overdue-unitType-view/serious-para-compliance-overdue-unitType-view.module#SeriousParaComplianceOverdueUnitTypeViewModule',canActivate: [AuthGuard]},

        { path: 'category-unitType-total-obj-amount', loadChildren: './dashboard/category-unitType-total-obj-amount/category-unitType-total-obj-amount.module#CategotyUnitTypeTotalObjAmountModule',canActivate: [AuthGuard]},

        { path: 'category-unitType-total-obj-amount-regional-view/:regionId/:yearId', loadChildren: './dashboard/category-unitType-total-obj-amount-regional-view/category-unitType-total-obj-amount-regional-view.module#CategotyUnitTypeTotalObjAmountRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'category-unitType-total-obj-amount-unitType-view/:unitTypeId/:regionId/:yearId', loadChildren: './dashboard/category-unitType-total-obj-amount-unitType-view/category-unitType-total-obj-amount-unitType-view.module#CategotyUnitTypeTotalObjAmountUnitTypeViewModule',canActivate: [AuthGuard]},

        { path: 'category-unitType-total-obj-amount-unit-view/:unitId/:unitTypeId/:regionId/:yearId', loadChildren: './dashboard/category-unitType-total-obj-amount-unit-view/category-unitType-total-obj-amount-unit-view.module#CategotyUnitTypeTotalObjAmountUnitViewModule',canActivate: [AuthGuard]},

        { path: 'first-compliance-due-regional-view/:regionId/:yearId', loadChildren: './dashboard/first-compliance-due-regional-view/first-compliance-due-regional-view.module#FirstComplianceDueRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'first-compliance-due-party-view/:partyId/:regionId/:yearId', loadChildren: './dashboard/first-compliance-due-party-view/first-compliance-due-party-view.module#FirstComplianceDuePartyViewModule',canActivate: [AuthGuard]},

        { path: 'first-compliance-due-unit-type-view/:districtId/:partyId/:regionId/:yearId', loadChildren: './dashboard/first-compliance-due-unit-type-view/first-compliance-due-unit-type-view.module#FirstComplianceDueUnitTypeViewModule',canActivate: [AuthGuard]},

        { path: 'first-compliance-due-unit-view/:unitTypeId/:districtId/:partyId/:regionId/:yearId', loadChildren: './dashboard/first-compliance-due-unit-view/first-compliance-due-unit-view.module#FirstComplianceDueUnitViewModule',canActivate: [AuthGuard]},

        { path: 'audit-report-issued-regional-view/:regionId', loadChildren: './dashboard/audit-report-issued-regional-view/audit-report-issued-regional-view.module#AuditReportIssuedRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'first-compliance-due', loadChildren: './dashboard/first-compliance-due/first-compliance-due.module#FirstComplianceDueModule',canActivate: [AuthGuard]},

        { path: 'categorywise-compliance-due', loadChildren: './dashboard/categorywise-compliance-due/categorywise-compliance-due.module#CategoryWiseComplianceDueModule',canActivate: [AuthGuard]},

        { path: 'categorywise-statusof-serious-paras', loadChildren: './dashboard/categorywise-statusof-serious-paras/categorywise-statusof-serious-paras.module#CategoryWiseStatusofSeriousParasModule',canActivate: [AuthGuard]},

        { path: 'categorywise-statusof-serious-paras-regional-view/:regionId/:yearId', loadChildren: './dashboard/categorywise-statusof-serious-paras-regional-view/categorywise-statusof-serious-paras-regional-view.module#CategoryWiseStatusofSeriousParasRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'categorywise-statusof-serious-paras-unit-type-view/:districtId/:regionId/:yearId', loadChildren: './dashboard/categorywise-statusof-serious-paras-unit-type-view/categorywise-statusof-serious-paras-unit-type-view.module#CategoryWiseStatusofSeriousParasUnitTypeViewModule',canActivate: [AuthGuard]},

        { path: 'categorywise-statusof-serious-paras-unit-view/:unitTypeId/:districtId/:regionId/:yearId', loadChildren: './dashboard/categorywise-statusof-serious-paras-unit-view/categorywise-statusof-serious-paras-unit-view.module#CategoryWiseStatusofSeriousParasUnitViewModule',canActivate: [AuthGuard]},

        { path: 'categorywise-compliance-due-regional-view/:regionId/:yearId', loadChildren: './dashboard/categorywise-compliance-due-regional-view/categorywise-compliance-due-regional-view.module#CategoryWiseComplianceDueRegionalViewModule',canActivate: [AuthGuard]},

        { path: 'categorywise-compliance-due-party-view/:partyId/:regionId/:yearId', loadChildren: './dashboard/categorywise-compliance-due-party-view/categorywise-compliance-due-party-view.module#CategoryWiseComplianceDuePartyViewModule',canActivate: [AuthGuard]},

        { path: 'categorywise-compliance-due-unit-type-view/:districtId/:partyId/:regionId/:yearId', loadChildren: './dashboard/categorywise-compliance-due-unit-type-view/categorywise-compliance-due-unit-type-view.module#CategoryWiseComplianceDueUnitTypeViewModule',canActivate: [AuthGuard]},

        { path: 'categorywise-compliance-due-unit-view/:unitTypeId/:districtId/:partyId/:regionId/:yearId', loadChildren: './dashboard/categorywise-compliance-due-unit-view/categorywise-compliance-due-unit-view.module#CategoryWiseComplianceDueUnitViewModule',canActivate: [AuthGuard]},

        { path: 'audit-man-days', loadChildren: './dashboard/audit-man-days/audit-man-days.module#AuditManDaysModule',canActivate: [AuthGuard]},

        { path: 'update-audit-man-days', loadChildren: './dashboard/update-audit-man-days/update-audit-man-days.module#UpdateAuditManDaysModule',canActivate: [AuthGuard]},

        { path: 'budget-account-head', loadChildren: './dashboard/budget-account/budget-account.module#BudgetAccountModule',canActivate: [AuthGuard]},

        { path: 'add-budget-account/:id', loadChildren: './dashboard/add-budget-account/add-budget-account.module#AddBudgetAccountModule',canActivate: [AuthGuard] },

        { path: 'add-budget-account', loadChildren: './dashboard/add-budget-account/add-budget-account.module#AddBudgetAccountModule',canActivate: [AuthGuard] },

        { path: 'para-observation-template', loadChildren: './dashboard/para-observation-template/para-observation-template.module#ParaObservationTemplateModule',canActivate: [AuthGuard]},

        { path: 'add-para-observation-template/:id', loadChildren: './dashboard/add-para-observation-template/add-para-observation-template.module#AddParaObservationTemplateModule',canActivate: [AuthGuard] },

        { path: 'add-para-observation-template', loadChildren: './dashboard/add-para-observation-template/add-para-observation-template.module#AddParaObservationTemplateModule',canActivate: [AuthGuard] },

        { path: 'point-of-discussion', loadChildren: './dashboard/point-discussion-management/point-discussion-management.module#PointDiscussionManagementModule',canActivate: [AuthGuard]},

        { path: 'add-point-discussion/:id', loadChildren: './dashboard/add-point-discussion/add-point-discussion.module#AddPointDiscussionModule',canActivate: [AuthGuard] },

        { path: 'add-point-discussion', loadChildren: './dashboard/add-point-discussion/add-point-discussion.module#AddPointDiscussionModule',canActivate: [AuthGuard] },

        { path: 'observation-type-management', loadChildren: './dashboard/observation-type-management/observation-type-management.module#ObservationTypeManagementModule',canActivate: [AuthGuard]},

        { path: 'add-observation-type/:id', loadChildren: './dashboard/add-observation-type/add-observation-type.module#AddObservationTypeModule',canActivate: [AuthGuard] },

        { path: 'add-observation-type', loadChildren: './dashboard/add-observation-type/add-observation-type.module#AddObservationTypeModule',canActivate: [AuthGuard] },

        { path: 'entity-management', loadChildren: './dashboard/entity-management/entity-management.module#EntityManagementModule',canActivate: [AuthGuard]},

        { path: 'add-entity', loadChildren: './dashboard/add-entity-management/add-entity-management.module#AddEntityManagementModule',canActivate: [AuthGuard] },

        { path: 'api-transaction', loadChildren: './dashboard/api-transaction/api-transaction.module#ApiTransactionModule',canActivate: [AuthGuard]},
        { path: 'api-transaction-detail/:id', loadChildren: './dashboard/api-transaction-detail/api-transaction-detail.module#ApiTransactionDetailModule',canActivate: [AuthGuard]},

        { path: 'materiality', loadChildren: './dashboard/materiality/materiality.module#MaterialityModule',canActivate: [AuthGuard]},
        { path: 'materiality-details/:id', loadChildren: './dashboard/materiality-details/materiality-details.module#MaterialityDetailsModule',canActivate: [AuthGuard]},

        { path: 'excel-transaction', loadChildren: './dashboard/excel-transaction/excel-transaction.module#ExcelTransactionModule',canActivate: [AuthGuard]},
        { path: 'excel-transaction-detail/:id', loadChildren: './dashboard/excel-transaction-detail/excel-transaction-detail.module#ExcelTransactionDetailModule',canActivate: [AuthGuard]},
        { path: 'transaction-summary', loadChildren: './dashboard/transaction-summary/transaction-summary.module#TransactionSummaryModule',canActivate: [AuthGuard]},
        { path: 'transaction-summary-detail/:id', loadChildren: './dashboard/transaction-summary-detail/transaction-summary-detail.module#TransactionSummaryDetailModule',canActivate: [AuthGuard]},

        { path: 'classification-of-audit-area', loadChildren: './dashboard/classification-audit/classification-audit.module#ClassificationAuditModule',canActivate: [AuthGuard]},

        { path: 'classification-audit-summary/:id', loadChildren: './dashboard/classification-audit-summary/classification-audit-summary.module#ClassificationAuditSummaryModule',canActivate: [AuthGuard]},

        { path: 'program-management', loadChildren: './dashboard/program-management/program-management.module#ProgramManagementModule',canActivate: [AuthGuard]},

        { path: 'create-program/:id', loadChildren: './dashboard/create-program/create-program.module#CreateProgramModule',canActivate: [AuthGuard] },

        { path: 'create-program', loadChildren: './dashboard/create-program/create-program.module#CreateProgramModule',canActivate: [AuthGuard] },

        { path: 'view-entity/:id', loadChildren: './dashboard/view-entity/view-entity.module#ViewEntityModule',canActivate: [AuthGuard]},

        { path: 'event-management', loadChildren: './dashboard/event-management/event-management.module#EventManagementModule',canActivate: [AuthGuard]},

        { path: 'add-event/:id', loadChildren: './dashboard/add-event/add-event.module#AddEventModule',canActivate: [AuthGuard] },

        { path: 'add-event', loadChildren: './dashboard/add-event/add-event.module#AddEventModule',canActivate: [AuthGuard] },

        { path: 'latest-news', loadChildren: './dashboard/latest-news/latest-news.module#LatestNewsModule',canActivate: [AuthGuard]},

        { path: 'add-news/:id', loadChildren: './dashboard/add-news/add-news.module#AddNewsModule',canActivate: [AuthGuard] },

        { path: 'add-news', loadChildren: './dashboard/add-news/add-news.module#AddNewsModule',canActivate: [AuthGuard] },

        { path: 'home-page-content', loadChildren: './dashboard/home-page-content/home-page-content.module#HomePageContentModule',canActivate: [AuthGuard]},

        { path: 'rules-guidelines', loadChildren: './dashboard/rules-guidelines/rules-guidelines.module#RulesGuidelinesModule',canActivate: [AuthGuard]},

        { path: 'add-rules-guidelines/:id', loadChildren: './dashboard/add-rules-guidelines/add-rules-guidelines.module#AddRulesGuidelinesModule',canActivate: [AuthGuard] },

        { path: 'add-rules-guidelines', loadChildren: './dashboard/add-rules-guidelines/add-rules-guidelines.module#AddRulesGuidelinesModule',canActivate: [AuthGuard] },

        { path: 'other-links', loadChildren: './dashboard/other-link/other-link.module#OtherLinkModule',canActivate: [AuthGuard]},

        { path: 'add-other-link/:id', loadChildren: './dashboard/add-other-link/add-other-link.module#AddOtherLinkModule',canActivate: [AuthGuard] },

        { path: 'add-other-link', loadChildren: './dashboard/add-other-link/add-other-link.module#AddOtherLinkModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-fs-compliance/:id', loadChildren: './dashboard/inbox-view-fs-compliance/inbox-view-fs-compliance.module#InboxViewFsComplianceModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-comply-draft-para/:id', loadChildren: './dashboard/inbox-view-comply-draft-para/inbox-view-comply-draft-para.module#InboxViewComplyDraftParaModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-communicate-draft-para/:id', loadChildren: './dashboard/inbox-view-communicate-draft-para/inbox-view-communicate-draft-para.module#InboxViewCommunicateDraftParaModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-publish-para/:id', loadChildren: './dashboard/inbox-view-publish-para/inbox-view-publish-para.module#InboxViewPublishParaModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-publish-para-compliance/:id', loadChildren: './dashboard/inbox-view-publish-para-compliance/inbox-view-publish-para-compliance.module#InboxViewPublishParaComplianceModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-manage-publish-para/:id', loadChildren: './dashboard/inbox-view-manage-publish-para/inbox-view-manage-publish-para.module#InboxViewManagePublishParaModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-memo/:id', loadChildren: './dashboard/inbox-view-memo/inbox-view-memo.module#InboxViewMemoModule',canActivate: [AuthGuard] },

        { path: 'inbox-view-para/:id', loadChildren: './dashboard/inbox-view-para/inbox-view-para.module#InboxViewParaModule',canActivate: [AuthGuard] },

        { path: 'audit-report-format', loadChildren: './dashboard/audit-report-format/audit-report-format.module#AuditReportFormatModule',canActivate: [AuthGuard]},

        { path: 'help-module',loadChildren: './dashboard/help-management/help-management.module#HelpManagementModule',canActivate: [AuthGuard]},

        { path: 'createHelp/:id', loadChildren:'./dashboard/create-help/create-help.module#CreateHelpModule',canActivate: [AuthGuard]},

        { path: 'upload-prapatra',loadChildren: './dashboard/upload-prapatra/upload-prapatra.module#UploadPrapatraModule',canActivate: [AuthGuard]},

        { path: 'audit-fee-management',loadChildren: './dashboard/audit-fee-management/audit-fee-management.module#AuditFeeManagementModule',canActivate: [AuthGuard]},

        { path: 'add-audit-fee/:id', loadChildren: './dashboard/add-audit-fee/add-audit-fee.module#AddAuditFeeModule',canActivate: [AuthGuard] },

        { path: 'add-audit-fee', loadChildren: './dashboard/add-audit-fee/add-audit-fee.module#AddAuditFeeModule',canActivate: [AuthGuard] },

        { path: 'certification-audit-plan', loadChildren: './dashboard/certification-audit-planing/certification-audit-planing.module#CertificationAuditPlaningModule',canActivate: [AuthGuard] },

        { path: 'add-certification-audit-plan', loadChildren: './dashboard/add-certification-audit-plan/add-certification-audit-plan.module#AddCertificationAuditPlanModule',canActivate: [AuthGuard] },

        { path: 'audit-unit-selected-certification/:planId', loadChildren:'./dashboard/audit-unit-selected-certification/audit-unit-selected-certification.module#AuditUnitSelectedCertificationModule',canActivate: [AuthGuard]},

        { path: 'view-plans/:regionId/:yearId', loadChildren:'./dashboard/view-plans/view-plans.module#ViewPlansModule',canActivate: [AuthGuard]},

        { path: 'audit-fees-collection', loadChildren:'./dashboard/audit-fee-collection/audit-fee-collection.module#AuditFeeCollectionModule',canActivate: [AuthGuard]},

        { path: 'add-audit-fees-collection/:id', loadChildren:'./dashboard/add-audit-fees-collection/add-audit-fees-collection.module#AddAuditFeesCollectionModule',canActivate: [AuthGuard]},

        { path: 'audit-trail/:id/:shortCode', loadChildren:'./dashboard/audit-trail/audit-trail.module#AuditTrailModule',canActivate: [AuthGuard]},

        { path: 'audit-certification-man-day', loadChildren:'./dashboard/audit-certification-man-day/audit-certification-man-day.module#AuditCertificationManDayModule',canActivate: [AuthGuard]},

        { path: 'audit-certification-parties-mapped/:planId', loadChildren:'./dashboard/audit-certification-parties-mapped/audit-certification-parties-mapped.module#AuditCertificationPartiesMappedModule',canActivate: [AuthGuard]},

        { path: 'memo-observation-template', loadChildren:'./dashboard/memo-observation-template/memo-observation-template.module#MemoObservationTemplateModule',canActivate: [AuthGuard]},

        { path: 'add-memo-observation-template/:id', loadChildren: './dashboard/add-memo-observation-template/add-memo-observation-template.module#AddMemoObservationTemplateModule',canActivate: [AuthGuard] },

        { path: 'add-memo-observation-template', loadChildren: './dashboard/add-memo-observation-template/add-memo-observation-template.module#AddMemoObservationTemplateModule',canActivate: [AuthGuard] },

        { path: 'outsatanding-first-compliance', loadChildren: './dashboard/outstanding-first-compliance/outstanding-first-compliance.module#OutstandingFirstComplianceModule',canActivate: [AuthGuard]},

        { path: 'audit-certificate', loadChildren: './dashboard/audit-certificate/audit-certificate.module#AuditCertificateModule',canActivate: [AuthGuard]},

        { path: 'add-audit-certificate/:id', loadChildren: './dashboard/add-audit-certificate/add-audit-certificate.module#AddAuditCertificateModule',canActivate: [AuthGuard]},

        { path: 'add-audit-certificate', loadChildren: './dashboard/add-audit-certificate/add-audit-certificate.module#AddAuditCertificateModule',canActivate: [AuthGuard]},

        { path: 'flying-squad-question-management', loadChildren: './dashboard/flying-squad-question-management/flying-squad-question-management.module#FlyingSquadQuestionManagementModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-question-management/:id', loadChildren: './dashboard/add-flying-squad-question-management/add-flying-squad-question-management.module#AddFlyingSquadQuestionManagementModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-question-management', loadChildren: './dashboard/add-flying-squad-question-management/add-flying-squad-question-management.module#AddFlyingSquadQuestionManagementModule',canActivate: [AuthGuard]},

        { path: 'audit-unit-executed-years', loadChildren: './dashboard/audit-unit-executed-years/audit-unit-executed-years.module#AuditUnitExecutedYearsModule',canActivate: [AuthGuard]},

        { path: 'flying-squad-party-management', loadChildren: './dashboard/flying-squad-party-management/flying-squad-party-management.module#FlyingSquadPartyManagementModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-party-management/:id', loadChildren: './dashboard/add-flying-squad-party-management/add-flying-squad-party-management.module#AddFlyingSquadPartyManagementModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-party-management', loadChildren: './dashboard/add-flying-squad-party-management/add-flying-squad-party-management.module#AddFlyingSquadPartyManagementModule',canActivate: [AuthGuard]},

        { path: 'audit-certificate-calendar/:id',loadChildren:'./dashboard/audit-certificate-calendar/audit-certificate-calendar.module#AuditCertificateCalendarModule',canActivate: [AuthGuard]},

        { path: 'audit-certificate-calendar',loadChildren:'./dashboard/audit-certificate-calendar/audit-certificate-calendar.module#AuditCertificateCalendarModule',canActivate: [AuthGuard]},

        { path: 'audit-certificate-route-map/:planId', loadChildren: './dashboard/audit-certificate-route-map/audit-certificate-route-map.module#AuditCertificateRouteMapModule',canActivate: [AuthGuard] },

        { path: 'audit-certificate-communication/:id', loadChildren:'./dashboard/audit-certificate-communication/audit-certificate-communication.module#AuditCertificateCommunicationModule',canActivate: [AuthGuard]},

        { path: 'flying-squad-plan-management', loadChildren: './dashboard/flying-squad-plan-management/flying-squad-plan-management.module#FlyingSquadPlanManagementModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-plan-management/:id', loadChildren: './dashboard/add-flying-squad-plan-management/add-flying-squad-plan-management.module#AddFlyingSquadPlanManagementModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-plan-management', loadChildren: './dashboard/add-flying-squad-plan-management/add-flying-squad-plan-management.module#AddFlyingSquadPlanManagementModule',canActivate: [AuthGuard]},

        { path: 'risk-based-planning',loadChildren:'./dashboard/risk-based-audit-planning/risk-based-audit-planning.module#RiskBasedAuditPlanningModule',canActivate: [AuthGuard]},

        { path: 'risk-based-audit-unit-selected/:planId', loadChildren: './dashboard/risk-based-audit-unit-selected/risk-based-audit-unit-selected.module#RiskBasedAuditUnitSelectedModule',canActivate: [AuthGuard] },

        { path: 'risk-based-route-map/:planId', loadChildren: './dashboard/risk-based-route-map/risk-based-route-map.module#RiskBasedRouteMapModule',canActivate: [AuthGuard] },

        { path: 'risk-based-audit-calendar/:id',loadChildren:'./dashboard/risk-based-audit-calendar/risk-based-audit-calendar.module#RiskBasedAuditCalendarModule',canActivate: [AuthGuard]},

        { path: 'risk-based-audit-parties-mapped/:planId', loadChildren:'./dashboard/risk-based-audit-parties-mapped/risk-based-audit-parties-mapped.module#RiskBasedAuditPartiesMappedModule',canActivate: [AuthGuard]},

        { path: 'risk-based-communication/:id', loadChildren:'./dashboard/risk-based-communication/risk-based-communication.module#RiskBasedCommunicationModule',canActivate: [AuthGuard]},


        { path: 'add-risk-based-audit-plan', loadChildren: './dashboard/add-risk-based-audit-plan/add-risk-based-audit-plan.module#AddRiskBasedAuditPlanModule',canActivate: [AuthGuard]},

       /* { path: 'add-risk-based-audit-plan-modified', loadChildren: './dashboard/add-risk-based-audit-plan-modified/add-risk-based-audit-plan-modified.module#AddRiskBasedAuditPlanModule',canActivate: [AuthGuard]}, */
	     
		{ path: 'add-risk-based-audit-plan-modified', loadChildren: './modified/add-risk-based-audit-plan-modified/add-risk-based-audit-plan-modified.module#AddRiskBasedAuditPlanModule',canActivate: [AuthGuard]},
        
        { path: 'risk-based-audit-unit-selected-modified/:planId', loadChildren: './modified/risk-based-audit-unit-selected-modified/risk-based-audit-unit-selected.module#RiskBasedAuditUnitSelectedModule',canActivate: [AuthGuard] },

        { path: 'risk-based-planning-modified',loadChildren:'./modified/risk-based-audit-planning-modified/risk-based-audit-planning.module#RiskBasedAuditPlanningModule',canActivate: [AuthGuard]},


        { path: 'flying-squad-execution-management', loadChildren: './dashboard/flying-squad-execution/flying-squad-execution.module#FlyingSquadExecutionModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-execution/:id', loadChildren: './dashboard/add-flying-squad-execution/add-flying-squad-execution.module#AddFlyingSquadExecutionModule',canActivate: [AuthGuard]},

        { path: 'add-flying-squad-execution', loadChildren: './dashboard/add-flying-squad-execution/add-flying-squad-execution.module#AddFlyingSquadExecutionModule',canActivate: [AuthGuard]},

        { path: 'view-flying-squad-execution/:id', loadChildren: './dashboard/view-flying-squad-execution/view-flying-squad-execution.module#ViewFlyingSquadExecutionModule',canActivate: [AuthGuard]},

        { path: 'view-flying-squad-plan/:id', loadChildren: './dashboard/view-flying-squad-plan/view-flying-squad-plan.module#ViewFlyingSquadPlanModule',canActivate: [AuthGuard]},


        /**
         * Date:        07-12-2020
         * Author:      Pinaki Majumder
         */
        {
            path: 'classification-of-key-audit-area',
            loadChildren: './dashboard/classification-key-audit/classification-key-audit.module#ClassificationKeyAuditModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'classification-of-key-audit-list/:id',
            loadChildren: './dashboard/classification-key-audit-list/classification-key-audit-list.module#ClassificationKeyAuditListModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'classification-of-key-audit-question/:id/:id',
            loadChildren: './dashboard/classification-key-audit-question/classification-key-audit-question.module#ClassificationKeyAuditQuestionModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'selection-of-transaction',
            loadChildren: './dashboard/selection-of-transaction/selection-of-transaction.module#SelectionOfTransactionModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'selection-of-transaction-details/:id',
            loadChildren: './dashboard/selection-of-transaction-details/selection-of-transaction-details.module#SelectionOfTransactionDetailsModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'selected-transaction-details/:param1/:param2/:param3',
            loadChildren: './dashboard/selected-transaction-details/selected-transaction-details.module#SelectedTransactionDetailsModule',
            canActivate: [AuthGuard]
        },

        /**
           * Date:   25-09-2022
           * Author: Sudhanshu Shrivastava
           */
         {
          path: 'year-table',
          loadChildren: './dashboard/year-table/year-table.module#YearTableModule',
          canActivate: [AuthGuard]
         },
         {
          path: 'year-add',
          loadChildren: './dashboard/year-add/year-add.module#YearAddModule',
          canActivate: [AuthGuard]
         },
         {
          path: 'year-add/:id',
          loadChildren:'./dashboard/year-add/year-add.module#YearAddModule',
          canActivate: [AuthGuard]
         },

         /**12-10-2022 */
        {
            path: 'holiday-list',
            loadChildren:'./dashboard/holiday-list/holiday-list.module#HolidayListModule',
            canActivate: [AuthGuard]
        },
        /**20-10-2022 */
        {
            path: 'holiday-list-add',
            loadChildren:'./dashboard/holiday-list-add/holiday-list-add.module#HolidayListAddModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'holiday-list-add/:id',
            loadChildren:'./dashboard/holiday-list-add/holiday-list-add.module#HolidayListAddModule',
            canActivate: [AuthGuard]
        },
        /**
         * Date: 08-Dec-2020
         * Author: Ghulam Moinuddin
         */
        {
            path: 'external-api-sync',
            loadChildren: './dashboard/external-api-sync/external-api-sync.module#ExternalApiSyncModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'external-api-sync-district',
            loadChildren: './dashboard/external-api-sync-district/external-api-sync-district.module#ExternalApiSyncDistrictModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'external-api-sync-audit-unit',
            loadChildren: './dashboard/external-api-sync-audit-unit/external-api-sync-audit-unit.module#ExternalApiSyncAuditUnitModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'external-api-sync-focus-area',
            loadChildren: './dashboard/external-api-sync-focus-area/external-api-sync-focus-area.module#ExternalApiSyncFocusAreaModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'external-api-auditarea-budgethead-map',
            loadChildren: './dashboard/external-api-auditarea-budgethead-map/external-api-auditarea-budgethead-map.module#ExternalApiAuditareaBudgetheadMapModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'external-api-sync-map-params',
            loadChildren: './dashboard/external-api-sync-map-params/external-api-sync-map-params.module#ExternalApiSyncMapParamsModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'management-letter',
            loadChildren: './dashboard/management-representation-letter/management-representation-letter.module#ManagementRepresentationLetter',
            canActivate: [AuthGuard]
        },
        {
            path: 'management-letter-details/:id',
            ///home/ghulam/Desktop/Docker/var/NodeJs/ams_fe/src/app/dashboard/management-representation-details/management-representation-details.module.ts
            loadChildren: './dashboard/management-representation-details/management-representation-details.module#ManagementRepresentationDetailsModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'planning-memorandum',
            loadChildren: './dashboard/audit-planning-memorandum/audit-planning-memorandum.module#AuditPlanningMemorandumModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'planning-memorandum-details/:id',
            loadChildren: './dashboard/audit-planning-memorandum-details/audit-planning-memorandum-details.module#AuditPlanningMemorandumDetailsModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'information-sheet',
            loadChildren: './dashboard/information-sheet/information-sheet.module#InformationSheetModule',
            canActivate: [AuthGuard]
        },

        // Create By Bhawana 19-02-2025
        { 
            path: 'createregion/:id', 
            loadChildren:'./dashboard/create-region/create-region.module#CreateRegionModule',
            canActivate: [AuthGuard]
        },

        { 
            path: 'createregion', 
            loadChildren:'./dashboard/create-region/create-region.module#CreateRegionModule',
            canActivate: [AuthGuard]
        },

        { 
            path: 'createdistrict', 
            loadChildren:'./dashboard/create-district/create-district.module#CreateDistrictModule',
            canActivate: [AuthGuard]
        },

        { 
            path: 'regionlist',
            loadChildren:'./dashboard/region-list/region-list.module#RegionListModule',
            canActivate: [AuthGuard],runGuardsAndResolvers: 'always'
        },
        
        { 
            path: 'districtlist',
            loadChildren:'./dashboard/district-list/district-list.module#DistrictListModule',
            canActivate: [AuthGuard],runGuardsAndResolvers: 'always'
        },


    ]
},

{
    path: '**',
    redirectTo: '/starter'
}];

