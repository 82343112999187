import { Headers } from '@angular/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class AuditService {

    private apiUrl = `${environment.apiUrl}/audit-reports`;

    eventEmitter = new EventEmitter();
    constructor(
        private http: HttpClient
    ) { }


    /// Change Password ///
    changePassword(param) {
        return this.http.post(`${environment.apiUrl}/user/changepassword`, param);
    }

    /// For Role ///

    insertRoleData(roleData) {
        return this.http.post(`${environment.apiUrl}/role/create`, roleData);
    }

    getAllRole(param) {
        return this.http.get(`${environment.apiUrl}/role/list`, { params: param });
    }
    getRoleById(id) {
        return this.http.get(`${environment.apiUrl}/role/${id}`);
    }
    updateRoleData(roleData) {
        return this.http.put(`${environment.apiUrl}/role/update`, roleData);
    }
    deleteRole(id) {
        return this.http.delete(`${environment.apiUrl}/role/delete/${id}`);
    }



    /// for Assesment Parament ///

    getAllParameters(param) {
        return this.http.get(`${environment.apiUrl}/risk/list`, { params: param });
    }

    deleteParameter(id) {
        return this.http.delete(`${environment.apiUrl}/risk/delete/${id}`);
    }

    insertRiskData(riskData) {
        return this.http.post(`${environment.apiUrl}/risk/create`, riskData);
    }

    riskBasedAuditPlanManagePlanHistory(param){
        return this.http.post(`${environment.apiUrl}/financial-audit-planning/manage-plan-history`, param);
    }

    updateRiskData(riskData) {
        return this.http.put(`${environment.apiUrl}/risk/edit`, riskData);
    }
    getMeasuringUnitList() {
        return this.http.get(`${environment.apiUrl}/risk/measuringunitlist`);
    }
    getAuditUnitList() {
        return this.http.get(`${environment.apiUrl}/audittype/list`);
    }
    getRiskAuditUnitTypeList() {
        return this.http.get(`${environment.apiUrl}/risk/unit-type-list`);
    }
    getRiskById(id) {
        return this.http.get(`${environment.apiUrl}/risk/${id}`);
    }
    approveRiskParameter(param) {
        return this.http.put(`${environment.apiUrl}/risk/approve`, param);
    }
    sentApprovalId(param) {
        return this.http.put(`${environment.apiUrl}/risk/add-to-planning-year`, param);
    }


    //// Audit Unit ////

    getAllYearList() {
      return this.http.get(`${environment.apiUrl}/year/list-till-current-execution`);
  }

    getAllAuditUnit(param) {
        return this.http.get(`${environment.apiUrl}/auditunit/list`, { params: param });
    }

    getAuditSearch(searchParam) {
        return this.http.get(`${environment.apiUrl}/auditunit/list?searchString=` + searchParam);
    }

    getAllAuditUnitByParty(param) {
      return this.http.get(`${environment.apiUrl}/auditunit/auditUnitListByParty`, { params: param });
    }

    approveAuditUnit(param) {
        return this.http.put(`${environment.apiUrl}/auditunit/approve`, param);
    }

    insertAuditUnitData(auditUnitData) {
        return this.http.post(`${environment.apiUrl}/auditunit/create`, auditUnitData);
    }

    updateAuditUnitData(auditUnitData) {
        return this.http.put(`${environment.apiUrl}/auditunit/update`, auditUnitData);
    }

    deleteAuditUnit(id) {
        return this.http.delete(`${environment.apiUrl}/auditunit/delete/${id}`);
    }

    getAuditUnitById(id) {
        return this.http.get(`${environment.apiUrl}/auditunit/${id}`);
    }

    getAuditUnitTypeList() {
        return this.http.get(`${environment.apiUrl}/auditUnitType/basiclist`);
    }
    regionList() {
        return this.http.get(`${environment.apiUrl}/region/basiclist`);
    }

    districtList(regionId) {
        return this.http.get(`${environment.apiUrl}/district/basiclist/${regionId}`);
    }

    blockList(districtId) {
        return this.http.get(`${environment.apiUrl}/block/basiclist/${districtId}`);
    }
    parentList(param) {
        return this.http.get(`${environment.apiUrl}/auditunit/parentlist/`, { params: param });
    }
    inchargeList(param) {
        return this.http.get(`${environment.apiUrl}/auditunit/inchargelist/`, { params: param });
    }

    //// Permission ////
    getPermission(id) {
        return this.http.get(`${environment.apiUrl}/permission/${id}`);
    }
    insertPermissionData(permissionData) {
        return this.http.post(`${environment.apiUrl}/permission/create`, permissionData);
    }

    //// Audit Party ////
    insertPartyData(partyData) {
        return this.http.post(`${environment.apiUrl}/auditParty/create`, partyData);
    }


    partyFormation(partyData) {
      console.log("Create PARTY DATA Service ", partyData);
        return this.http.post(`${environment.apiUrl}/auditParty/auditPartyFormation`, partyData);
    }

    updatePartyData(auditPartyData) {
        return this.http.put(`${environment.apiUrl}/auditParty/edit`, auditPartyData);
    }

    updateCreatedByInTables(auditPartyData) {
        return this.http.put(`${environment.apiUrl}/auditParty/updateCreatedBy`, auditPartyData);
    }

    getAllAuditParty(param) {
      console.log("Param Service ",param);
        return this.http.get(`${environment.apiUrl}/auditParty/listByROAndYear`, { params: param });
    }

    downloadAllAuditParty(param) {
        return this.http.get(`${environment.apiUrl}/auditParty/list`, { params: param });
    }

    deleteParty(id) {
        return this.http.delete(`${environment.apiUrl}/auditParty/delete/${id}`);
    }

    getInCharage(id) {
        return this.http.get(`${environment.apiUrl}/auditParty/inchargelist/available?officeId=` + id);
    }

    getEditInCharage(officeId, id) {
        return this.http.get(`${environment.apiUrl}/auditParty/inchargelist/available?auditPartyId=` + id + `&officeId=` + officeId);
    }
    getOfficeList() {
        return this.http.get(`${environment.apiUrl}/auditParty/officelist`);
    }
    getMemberList(id) {
        return this.http.get(`${environment.apiUrl}/auditParty/partymemberslist/available?officeId=` + id);
    }
    getEditMemberList(officeId, id) {
        return this.http.get(`${environment.apiUrl}/auditParty/partymemberslist/available?auditPartyId=` + id + `&officeId=` + officeId);
    }
    getPartyDataById(id) {
        return this.http.get(`${environment.apiUrl}/auditParty/${id}`);
    }
    approveAuditParty(param) {
        return this.http.put(`${environment.apiUrl}/auditParty/approve`, param);
    }



    //// Audit Planing ////

    getAuditPlans(param) {
        return this.http.get(`${environment.apiUrl}/audit-plan/list`, { params: param });
    }
    getAuditYearList() {
        return this.http.get(`${environment.apiUrl}/audit-plan/get-audit-year`);
    }
    getAuditStatus() {
        return this.http.get(`${environment.apiUrl}/audit-plan/get-all-status`);
    }

    getAllPreParameters(param) {
        return this.http.get(`${environment.apiUrl}/planrisk/list`, { params: param });
    }

    approvePreRiskParameter(param) {
        return this.http.put(`${environment.apiUrl}/planrisk/approve`, param);
    }

    deletePreParameter(id) {
        return this.http.delete(`${environment.apiUrl}/planrisk/deleteAuditRiskParam/${id}`);
    }

    getPreassesmentById(id) {
        return this.http.get(`${environment.apiUrl}/planrisk/editlist/${id}`);
    }

    updatePreAssesmentData(param) {
        return this.http.put(`${environment.apiUrl}/planrisk/edit`, param)
    }

    partiesMappedList(param, id) {
        return this.http.get(`${environment.apiUrl}/planning/map/${id}`, { params: param });
    }

    communicationPart(param, id) {
        return this.http.get(`${environment.apiUrl}/planning/communicationPart/${id}`, { params: param });
    }

    showParam(param) {
        return this.http.get(`${environment.apiUrl}/preplan/showparam`, { params: param });
    }

    sentMail(param) {
        return this.http.post(`${environment.apiUrl}/planning/send-planning-mail`, param);
    }

    getCalendarData(param) {
        return this.http.get(`${environment.apiUrl}/audit-plan/calendar`, { params: param });
    }

    fetchShowParamList(param) {
        return this.http.get(`${environment.apiUrl}/preplan/list`, { params: param });
    }

    uploadExcelRiskParam(param) {
        return this.http.post(`${environment.apiUrl}/preplan/upload-excel`, param);
    }

    downloadExcelRiskParam(param) {
        // const headers = new HttpHeaders().set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        return this.http.get(`${environment.apiUrl}/preplan/download-excel`, { params: param, responseType: 'blob' });
    }
    downloadPdf(id) {
        // const headers = new HttpHeaders().set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        return this.http.get(`${environment.apiUrl}/planning/pdf-download/${id}`, { responseType: 'blob' });
    }

    getAllProcessHeadList(param) {
        return this.http.get(`${environment.apiUrl}/check-list-type/list`, { params: param });
    }

    getAllProcessList(param) {
        return this.http.get(`${environment.apiUrl}/riskprocess/list`, { params: param });
    }

    getProcessList(param) {
        return this.http.get(`${environment.apiUrl}/riskAssessemntProcessQuestions/processlist`, { params: param });
    }

    getExecutionProcessList(param) {
        return this.http.get(`${environment.apiUrl}/executionChecklistQuestionManagement/processlist`, { params: param });
    }

    getAuditParty(param) {
        return this.http.get(`${environment.apiUrl}/auditParty/list-for-calendar`, { params: param });
    }

    deleteProcessHead(id) {
        return this.http.delete(`${environment.apiUrl}/check-list-type/delete/${id}`);
    }
    approveProcessHead(param) {
        return this.http.put(`${environment.apiUrl}/check-list-type/approve`, param);
    }

    approveProcess(param) {
        return this.http.put(`${environment.apiUrl}/riskprocess/approve`, param);
    }

    deleteProcess(id) {
        return this.http.delete(`${environment.apiUrl}/riskprocess/delete/${id}`);
    }

    insertProcessHeadData(param) {
        return this.http.post(`${environment.apiUrl}/check-list-type/create`, param);
    }

    updateProcessHeadData(param) {
        return this.http.put(`${environment.apiUrl}/check-list-type/edit`, param);
    }
    getProcessHeadById(id) {
        return this.http.get(`${environment.apiUrl}/check-list-type/${id}`);
    }

    saveRiskParam(params) {
        return this.http.post(`${environment.apiUrl}/preplan/save`, params);
    }

    updateProcessData(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/riskprocess/update', data, { headers: headers });
    }

    insertProcessData(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/riskprocess/create', data, { headers: headers });
    }

    getProcessById(id) {
        return this.http.get(`${environment.apiUrl}/riskprocess/${id}`);
    }

    getParentList(param) {
        return this.http.get(`${environment.apiUrl}/riskprocess/parentlist`, { params: param });
    }
    updateAuditUnitTypeData(data, headers) {
        //return this.http.put(`${environment.apiUrl}/auditUnitType/edit`, param);
        return this.http.put(`${environment.apiUrl}` + '/auditUnitType/edit', data, { headers: headers });
    }
    InsertAuditUnitTypeData(data, headers) {
        // return this.http.post(`${environment.apiUrl}/auditUnitType/create`, param);
        return this.http.post(`${environment.apiUrl}` + '/auditUnitType/create', data, { headers: headers });
    }
    getAuditUnitTypeById(id) {
        return this.http.get(`${environment.apiUrl}/auditUnitType/editlist/${id}`);
    }
    getParantAuditUnitList() {
        return this.http.get(`${environment.apiUrl}/auditUnitType/basiclist`);
    }

    getAllAuditUnitType(param) {
        return this.http.get(`${environment.apiUrl}/auditUnitType/list`, { params: param });
    }

    deleteAuditUnitType(id) {
        return this.http.delete(`${environment.apiUrl}/auditUnitType/deleteAuditUnitType/${id}`);
    }

    approveAuditUnitType(param) {
        return this.http.put(`${environment.apiUrl}/auditUnitType/approveAuditUnitType`, param);
    }

    performanceTechnicalAuditor(param) {
        return this.http.get(`${environment.apiUrl}/auditParty/get-technical-auditor/`, { params: param });
    }

    editPlan(id) {
        return this.http.get(`${environment.apiUrl}/planning/edit-map/${id}`);
    }

    UnmappedParty(id) {
        return this.http.get(`${environment.apiUrl}/planning/get-unmapped-party/${id}`);
    }

    saveData(params) {
        return this.http.post(`${environment.apiUrl}/preplan/saveplan`, params);
    }

    auditPlanApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/audit-plan/approve-reject`, param);
    }

    sendForApprove(id) {
        return this.http.put(`${environment.apiUrl}/audit-plan/send-for-approval/${id}`, {});
    }

    deleteAuditPlan(id) {
        return this.http.delete(`${environment.apiUrl}/audit-plan/delete/${id}`);
    }

    mapParty(param) {
        return this.http.post(`${environment.apiUrl}/planning/map-party`, param);
    }

    getQuestions(param) {
        return this.http.get(`${environment.apiUrl}/riskAssessemntProcessQuestions/list`, { params: param });
    }

    createQuestion(param) {
        return this.http.post(`${environment.apiUrl}/riskAssessemntProcessQuestions/create`, param);
    }

    editQuestion(id) {
        return this.http.get(`${environment.apiUrl}/riskAssessemntProcessQuestions/editlist/${id}`);
    }

    updateQuestion(param) {
        return this.http.put(`${environment.apiUrl}/riskAssessemntProcessQuestions/edit`, param);
    }

    deleteQuestions(id) {
        return this.http.delete(`${environment.apiUrl}/riskAssessemntProcessQuestions/delete/${id}`);
    }

    getExecutionQuestions(param) {
        return this.http.get(`${environment.apiUrl}/executionChecklistQuestionManagement/list`, { params: param });
    }

    createExecutionQuestion(param) {
        return this.http.post(`${environment.apiUrl}/executionChecklistQuestionManagement/create`, param);
    }

    updateExecutionQuestion(param) {
        return this.http.put(`${environment.apiUrl}/executionChecklistQuestionManagement/edit`, param);
    }

    editExecutionQuestion(id) {
        return this.http.get(`${environment.apiUrl}/executionChecklistQuestionManagement/editlist/${id}`);
    }

    deleteExecutionQuestions(id) {
        return this.http.delete(`${environment.apiUrl}/executionChecklistQuestionManagement/delete/${id}`);
    }

    approveExecutionQuestion(param) {
        return this.http.put(`${environment.apiUrl}/executionChecklistQuestionManagement/approve`, param);
    }

    getAvailableMember(param) {
        return this.http.get(`${environment.apiUrl}/auditParty/partymemberslist/available`, { params: param });
    }

    approveQuestionProcess(param) {
        return this.http.put(`${environment.apiUrl}/riskAssessemntProcessQuestions/approve`, param);
    }

    getRiskRating() {
        return this.http.get(`${environment.apiUrl}/riskrating/list`);
    }

    getSubProcessList(param) {
        return this.http.get(`${environment.apiUrl}/riskAssessemntProcessQuestions/childProcesslist`, { params: param });
    }

    getExecutionSubProcessList(param) {
        return this.http.get(`${environment.apiUrl}/executionChecklistQuestionManagement/childProcesslist`, { params: param });
    }

    updateRiskRate(param) {
        return this.http.put(`${environment.apiUrl}/riskrating/update`, param);
    }

    getAuditUnitMeetingList(id) {
        if (id == 0) {
            return this.http.get(`${environment.apiUrl}/openingMeeting/auditUnitlist`);
        } else {
            let param = {
                meetingId: id
            };
            return this.http.get(`${environment.apiUrl}/openingMeeting/auditUnitlist`, { params: param });
        }
    }

    addOpenMeeting(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/openingMeeting/addOpeningMeeting', data, { headers: headers });
    }

    getWorkAllocation(param) {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/listByAuditUnit`, { params: param });
    }

    getAuditUnit() {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/auditUnitList`);
    }
    getParaAuditUnit(param) {
        return this.http.get(`${environment.apiUrl}/para/filter-auditUnitlist`, { params: param });
    }

    getMember() {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/memberList`);
    }

    getWorkList() {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/workList`);
    }

    // createWorkAllocation(param) {
    //     return this.http.post(`${environment.apiUrl}/WorkAllocation/addWorkAllocation`, param);
    // }

    createWorkAllocation(param) {
        return this.http.post(`${environment.apiUrl}/WorkAllocation/addWorkAllocationToMultiUser`, param);
    }

    getYearList() {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/yearListPara`);
    }

    getUnitByYear(param) {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/auditUnitListOfYear`, { params: param });
    }
    getAuditPartyList(param) {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/partyList`, { params: param });
    }

    openingMeetingList(param) {
        return this.http.get(`${environment.apiUrl}/openingMeeting/list`, { params: param });
    }

    openingMeetingEditList(id) {
        return this.http.get(`${environment.apiUrl}/openingMeeting/editlist/${id}`);
    }

    getMeetingAuditYear() {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/yearList`);
    }

    auditUnitByYearId(id) {
        let param = {};
        param['auditYearId'] = id;
        return this.http.get(`${environment.apiUrl}/openingMeeting/auditUnitlistByYear`, { params: param });

        //return this.http.get(`${environment.apiUrl}/openingMeeting/list`,{params:param});
    }

    deleteMeeting(id) {
        return this.http.delete(`${environment.apiUrl}/openingMeeting/delete/${id}`);
    }

    updateMeeting(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/openingMeeting/edit', data, { headers: headers });
    }

    workManagementList(param) {
        return this.http.get(`${environment.apiUrl}/work/list`, { params: param });
    }

    workManagementApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/work/approve`, param);
    }

    deleteWork(id) {
        return this.http.delete(`${environment.apiUrl}/work/delete/${id}`);
    }

    createWork(param) {
        return this.http.post(`${environment.apiUrl}/work/create`, param);
    }

    editworkManagement(id) {
        return this.http.get(`${environment.apiUrl}/work/${id}`);
    }

    updateWork(data) {
        return this.http.put(`${environment.apiUrl}` + '/work/update', data);
    }

    userManagementList(param) {
        return this.http.get(`${environment.apiUrl}/user/list`, { params: param });
    }

    userManagementApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/user/approve`, param);
    }

    deleteUser(id) {
        return this.http.delete(`${environment.apiUrl}/user/delete/${id}`);
    }

    createUser(param) {
        return this.http.post(`${environment.apiUrl}/user/create`, param);
    }

    userDistrictList(param) {
        return this.http.get(`${environment.apiUrl}/user/districtList`, { params: param });
    }

    userRoleList() {
        return this.http.get(`${environment.apiUrl}/user/roleList`);
    }

    userOfficeList() {
        return this.http.get(`${environment.apiUrl}/user/officeList`);
    }

    userDedignationList() {
        return this.http.get(`${environment.apiUrl}/user/designationList`);
    }

    // userEdit(id) {
    //     return this.http.get(`${environment.apiUrl}/user/editlist/${id}`);
    // }

    updateUser(data) {
        return this.http.put(`${environment.apiUrl}` + '/user/edit', data);
    }

    auditmemoList(param) {
        return this.http.get(`${environment.apiUrl}/auditmemo/memoList`, { params: param });
    }

    getmemoType() {
        return this.http.get(`${environment.apiUrl}/auditmemo/memoTypeList`);
    }

    getObservationType() {
        return this.http.get(`${environment.apiUrl}/auditmemo/observationTypeList`);
    }

    auditParentProcess() {
        return this.http.get(`${environment.apiUrl}/auditmemo/auditParentProcessList`);
    }

    memoReferenceList(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/memoReferenceList?auditPlanMappingId=${id}`);
    }

    memoSubProcess(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/auditChildProcessList/${id}`);
    }

    memoAuditUnit() {
        return this.http.get(`${environment.apiUrl}/auditmemo/auditUnit`);
    }

    addMemo(data, headers) {
      console.log("ADD MEMO Data and headers",data, headers);
        return this.http.post(`${environment.apiUrl}` + '/auditmemo/addMemo', data, { headers: headers });
    }


    addReportDetails(formData) {
      console.log("Audit Service Form Data ",formData);
      return this.http.put(`${environment.apiUrl}/auditreport/edit-format-details`, formData);
    //   return this.http.put(`${environment.apiUrl}/auditreport/edit-format-details/${formData.get('reportId')}`, formData);
  }

    addAuditDetails(param) {
      console.log("Service Param addAuditDetails ",param);
      return this.http.put(`${environment.apiUrl}/auditreport/add-audit-details`, param);
    }

    deleteMemo(id) {
        return this.http.delete(`${environment.apiUrl}/auditmemo/delete/${id}`);
    }

    editMemoList(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/editMemolist/${id}`);
    }

    updateMemoList(data, headers) {
      console.log("Memo Data----Headers------ ",data, headers);
        return this.http.put(`${environment.apiUrl}` + '/auditmemo/edit-memo', data, { headers: headers });
    }

    approverejectMemo(param) {
        return this.http.put(`${environment.apiUrl}/auditmemo/approve-reject`, param);
    }

    memoResponse(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/auditmemo/memoResponse', data, { headers: headers });
    }
    getPreviousYearParaProcessList() {
        return this.http.get(`${environment.apiUrl}/para-without-plan-mapping/process-list`);
    }

    getAllProcesses() {
        return this.http.get(`${environment.apiUrl}/two-tier-planning/process-list`);
    }
    getParaProcessList(id) {
        if (id == 0) {
            return this.http.get(`${environment.apiUrl}/convert-to-para/process-list`);
        } else {
            let param = {
                paraId: id
            };
            return this.http.get(`${environment.apiUrl}/convert-to-para/process-list`, { params: param });
        }
    }
    getAllSubProcesses(id) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/subprocess-list/${id}`);

    }

    getYear() {
        return this.http.get(`${environment.apiUrl}/execution-checklist/year-list`);
    }

    getUnit(param) {
        return this.http.get(`${environment.apiUrl}/two-tier-planning/audit-unit-list/for-list`, { params: param });
    }

    getUnitList() {
        return this.http.get(`${environment.apiUrl}/two-tier-planning/audit-unit-list/for-create`);
    }

    getQuestion(param) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/question-list`, { params: param });
    }

    saveChecklistQuestion(param) {
        return this.http.post(`${environment.apiUrl}/execution-checklist/save-answers`, param);
    }

    getChecklist(param) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/list`, { params: param });
    }

    getProcesses() {
        return this.http.get(`${environment.apiUrl}/execution-checklist/process-list`);
    }
    getProcessDetails(param) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/details`, { params: param });
    }

    getNoQuestionList(param) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/no-answer-list`, { params: param });
    }

    deleteWorkAllocation(id) {
        return this.http.delete(`${environment.apiUrl}/WorkAllocation/delete/${id}`);
    }

    getWorkById(id) {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/editlist/${id}`);
    }
    editWorkAllocation(param) {
        return this.http.put(`${environment.apiUrl}/WorkAllocation/edit`, param);
    }

    getRiskProcessQuestion(param) {
        return this.http.get(`${environment.apiUrl}/two-tier-planning/process-questions`, { params: param });
    }

    saveRiskProcessQuestion(param) {
        return this.http.post(`${environment.apiUrl}/two-tier-planning/post-answers`, param);
    }

    getRiskAssesmentProcess(param) {
        return this.http.get(`${environment.apiUrl}/two-tier-planning/list`, { params: param });
    }

    getMemoParaUnitList(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/audit-unit-list`, { params: param });
    }
    getmemoParaYearList() {
        return this.http.get(`${environment.apiUrl}/convert-to-para/year-list`);
    }
    getmemoParaList(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/memo-list`, { params: param });
    }
    getConvertPara(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/memo-list`, { params: param });
    }

    // getObservationType(){
    //     return this.http.get(`${environment.apiUrl}/auditmemo/observationTypeList`);
    // }

    addMemoPara(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/convert-to-para/create-para', data, { headers: headers });
    }

    getCategory() {
        return this.http.get(`${environment.apiUrl}/convert-to-para/basic-list`);
    }

    getParaDataById(id) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/para/${id}`);
    }
    getPreviousYearParaDataById(id) {
        return this.http.get(`${environment.apiUrl}/para-without-plan-mapping/para/${id}`);
    }

    getParaList(param) {
        return this.http.get(`${environment.apiUrl}/para/list`, { params: param });
    }
    editPara(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/convert-to-para/update-para', data, { headers: headers });
    }

    editPriviousYearPara(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/para-without-plan-mapping/update-para-without-planmapping', data, { headers: headers });
    }

    getMemoDetails(id) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/memoDetail/${id}`);
    }

    memoDetails(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/details/${id}`);
    }

    // downloadAuditMemoFile(id) {
    //     return this.http.get(`${environment.apiUrl}/auditmemo/get-file/${id}`, { params: {}, responseType: 'blob' });
    // }
    downloadAuditMemoFile(id) {
        return this.http.get(`${environment.apiUrl}/file/download?fileName=${id}`, { params: {}, responseType: 'blob' });
    }

    downloadFile(id) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/get-file/${id}`, { params: {}, responseType: 'blob' });
    }

    getRegionalOffice() {
        return this.http.get(`${environment.apiUrl}/region/basiclist`);
    }
    deletePara(param) {
        return this.http.delete(`${environment.apiUrl}/para/delete/`, { params: param });
    }

    approvePara(param) {
        return this.http.get(`${environment.apiUrl}/para/approve-reject`, { params: param });
    }
    approveReviewPara(param) {
        return this.http.get(`${environment.apiUrl}/para/review`, { params: param });
    }

    paraDetails(id) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/para-trail/${id}`);
    }

    openingMeetingPdfDownload(id) {
        return this.http.get(`${environment.apiUrl}/openingMeeting/pdf-download/${id}`, { params: {}, responseType: 'blob' });
    }

    closeMeetingPdfDownload(id) {
        return this.http.get(`${environment.apiUrl}/closingMeeting/pdf-download/${id}`, { params: {}, responseType: 'blob' });
    }

    openingMeetingSendMail(params) {
        return this.http.post(`${environment.apiUrl}/openingMeeting/send-openingMeeting-mail`, params);
    }

    closeMeetingSendMail(params) {
        return this.http.post(`${environment.apiUrl}/closingMeeting/send-closingMeeting-mail`, params);
    }

    getYearAuditmemo() {
        return this.http.get(`${environment.apiUrl}/auditmemo/year-list`);
    }

    getAuditUnitAuditmemo(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/audit-unit-list/for-filter?yearId=${id}`);
    }

    addCloseMeeting(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/closingMeeting/addClosingMeeting', data, { headers: headers });
    }

    editCloseMeeting(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/closingMeeting/edit', data, { headers: headers });
    }

    deleteCloseMeeting(id) {
        return this.http.delete(`${environment.apiUrl}/closingMeeting/delete/${id}`);
    }

    getAuditUnitCloseMeetingList(id) {
        //return this.http.get(`${environment.apiUrl}/closingMeeting/auditUnitlist`);
        if (id == 0) {
            return this.http.get(`${environment.apiUrl}/closingMeeting/auditUnitlist`);
        } else {
            let param = {
                meetingId: id
            }
            return this.http.get(`${environment.apiUrl}/closingMeeting/auditUnitlist`, { params: param });
        }
    }
    openingCloseMeetingList(param) {
        return this.http.get(`${environment.apiUrl}/closingMeeting/list`, { params: param });
    }

    CloseMeetingEditList(id) {
        return this.http.get(`${environment.apiUrl}/closingMeeting/editlist/${id}`);
    }

    getAuditReport(param) {
        return this.http.get(`${environment.apiUrl}/auditreport/auditreportlist`, { params: param });
    }

    downloadPDF(fileName: string): Observable<Blob> {
      console.log("FileName ", fileName);
      return this.http.get(`${environment.apiUrl}/auditreport/pdf/`+ fileName, { responseType: 'blob' });
    }

    getAuditReportData(id) {
        return this.http.get(`${environment.apiUrl}/auditreport/audit-details/${id}`,);
    }

    sendAuditReportForApproval(param) {
      console.log("Send for Approval Param ",param);
        return this.http.get(`${environment.apiUrl}/auditreport/sent-for-approval`, { params: param });
    }

    ApproveRejectAuditReport(data) {
        return this.http.put(`${environment.apiUrl}` + '/auditreport/approve-reject', data);
    }

    getInboxData(param) {
        return this.http.get(`${environment.apiUrl}/inbox/list`, { params: param });
    }

    deleteInbox(id) {
        return this.http.delete(`${environment.apiUrl}/inbox/delete/${id}`);
    }

    inboxReply(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/inbox/reply', data, { headers: headers });
    }


    getRegion() {
        return this.http.get(`${environment.apiUrl}/sent/office-list`);
    }
    getDistrict(param) {
        return this.http.get(`${environment.apiUrl}/sent/district-list`, { params: param });
    }
    getRole() {
        return this.http.get(`${environment.apiUrl}/sent/role-list`);
    }
    getUser(param) {
        return this.http.get(`${environment.apiUrl}/sent/user-list`, { params: param });
    }
    insertSentItemData(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/sent/create', data, { headers: headers });
    }

    getTrashData(param) {
        return this.http.get(`${environment.apiUrl}/inbox/list`, { params: param });
    }

    getTourNoteData(param) {
        return this.http.get(`${environment.apiUrl}/tourNote/list`, { params: param });
     }
     getTourNoteDetailingData(id) {
        return this.http.get(`${environment.apiUrl}/tourNote/tour/${id}`);
     }
     addTourNote(param) {
        return this.http.post(`${environment.apiUrl}/tourNote/addTourNote`, param);
     }
     tourNoteChangeStatus(param) {
        return this.http.post(`${environment.apiUrl}/tourNote/action`, param);
     }




    retrieveDeleteItems(param) {
        return this.http.post(`${environment.apiUrl}` + '/inbox/retrieve/' + param.id, param);
    }

    getSentItems(param) {
        return this.http.get(`${environment.apiUrl}/sent/list`, { params: param });
    }

    deleteSentItems(id) {
        return this.http.delete(`${environment.apiUrl}/sent/delete/${id}`);
    }

    getInboxDataById(param) {
        return this.http.get(`${environment.apiUrl}/inbox/inboxInformation`, { params: param });
    }

    getSentItemById(param) {
        return this.http.get(`${environment.apiUrl}/inbox/inboxInformation`, { params: param });
    }

    getTeamTaskdata(param) {
        return this.http.get(`${environment.apiUrl}/myTeamTask/list`, { params: param });
    }

    getTaskById(id) {
        return this.http.get(`${environment.apiUrl}/myTeamTask/detail/${id}`);
    }

    editReportDetails(id) {
        return this.http.get(`${environment.apiUrl}/auditreport/edit-details/${id}`);
    }

    addofficialMet(param) {
        return this.http.post(`${environment.apiUrl}/auditreport/add-met-members`, param);
    }

    editofficialMet(param) {
        return this.http.put(`${environment.apiUrl}/auditreport/edit-met-members`, param);
    }

    deleteMetMemers(param) {
        return this.http.post(`${environment.apiUrl}/auditreport/delete-met-members`, param);
    }

    updateCoverage(param) {
        return this.http.put(`${environment.apiUrl}/auditreport/edit-coverage`, param);
    }

    addObservationCompliance(param) {
        return this.http.post(`${environment.apiUrl}/auditreport/add-observation_compliance`, param);
    }

    editObservationCompliance(param) {
        return this.http.put(`${environment.apiUrl}/auditreport/edit-observation_compliance`, param);
    }

    deleteCompliance(id) {
        return this.http.delete(`${environment.apiUrl}/auditreport/delete/${id}`);
    }

    addAuditScope(param) {
        return this.http.post(`${environment.apiUrl}/auditreport/add-audit-scope`, param);
    }

    updateAuditScope(param) {
        return this.http.put(`${environment.apiUrl}/auditreport/edit-audit-scope`, param);
    }

    deleteAuditScope(id) {
        return this.http.delete(`${environment.apiUrl}/auditreport/delete-audit-scope/${id}`);
    }

    
    deleteReportFile(formData) {
        return this.http.put(`${environment.apiUrl}/auditreport/deletereportfile/${formData.get('reportId')}`, formData);
    }
    
    getInboxNotification() {
        return this.http.get(`${environment.apiUrl}/inbox/notification`);
    }
    createPara(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/para/create-para', data, { headers: headers });
    }

    createPreviousYearPara(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/para-without-plan-mapping/create-para-without-planmapping', data, { headers: headers });
    }

    getParaAuditUnitList() {
        return this.http.get(`${environment.apiUrl}/para/auditUnitlist`);
    }
    getPreviousYearParaAuditUnitList() {
        return this.http.get(`${environment.apiUrl}/para-without-plan-mapping/unit-list`);
    }
    dropAuditMemo(param) {
        return this.http.put(`${environment.apiUrl}/auditmemo/drop/`, param);
    }

    viewWorkAllocation(id) {
        return this.http.get(`${environment.apiUrl}/WorkAllocation/view/${id}`);
    }

    SentForReview(param) {
        return this.http.get(`${environment.apiUrl}/para/sent-for-review`, { params: param });
    }

    SentForApproval(param) {
        return this.http.get(`${environment.apiUrl}/para/sent-for-approval`, { params: param });
    }
    

  // Method to send report to Admin for approval
    sendReportForAdminApproval(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/send-for-admin-approval/${reportId}`, { });
  }

  // Method to approve report by Admin
  approveReportByAdmin(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/approve-by-admin/${reportId}`, {});
  }

  // Method to reject report by Admin
  rejectReportByAdmin(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/reject-by-admin/${reportId}`, {});
  }

  // Method to send report to Section RO
  sendReportToSectionRO(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/send-to-section-ro/${reportId}`, {});
  }

  // Method to approve report by Section RO
  approveReportBySectionRO(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/approve-by-section-ro/${reportId}`, {});
  }

  // Method to reject report by Section RO
  rejectReportBySectionRO(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/reject-by-section-ro/${reportId}`, {});
  }

  // Method for final approval or processing
  finalApprovalOrProcessing(reportId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/final-approval-or-processing/${reportId}`, {});
  }

    
    getSettingView() {
        return this.http.get(`${environment.apiUrl}/setting/view`);
    }
    updateSettings(param) {
        return this.http.put(`${environment.apiUrl}/setting/update`, param);
    }

    getParaComplyList(param) {
        return this.http.get(`${environment.apiUrl}/paraCompliance/list`, { params: param });
    }

    getparaListBasedOnUnitIds(param){
      return this.http.get(`${environment.apiUrl}/paraCompliance/para-list-based-on-unit-ids`, { params: param });

    }
    getParaComplyCategory() {
        return this.http.get(`${environment.apiUrl}/convert-to-para/basic-list`);
    }
    getParaComplyYear() {
        return this.http.get(`${environment.apiUrl}/paraCompliance/year`);
    }

    addParaComply(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/paraCompliance/createComply', data, { headers: headers });
    }
    editParaComply(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/paraCompliance/compliance-for-edit', data, { headers: headers });
    }
    getComplyEditData(id) {
        return this.http.get(`${environment.apiUrl}/paraCompliance/compliance-for-edit/${id}`);
    }

    getComplyList(param) {
        return this.http.get(`${environment.apiUrl}/paraComplianceApproval/list`, { params: param });
    }

    getComplyListBasedOnAuditId(param) {
      return this.http.get(`${environment.apiUrl}/paraComplianceApproval/para-list-based-on-unit-ids`, { params: param });
    }

    auditUnitList(param) {
        return this.http.get(`${environment.apiUrl}/paraComplianceApproval/auditUnitList`, { params: param });
    }

    complianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/paraComplianceApproval/complianceList/${id}`);
    }

    paraComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/paraCompliance/complianceApprovalList/${id}`);
    }

    approveCompliance(data) {
        return this.http.put(`${environment.apiUrl}` + '/paraComplianceApproval/approve', data);
    }

    /* Sankar */
    approveUserManagement(param) {
        return this.http.put(`${environment.apiUrl}/user/approve`, param);
    }

    /******** */

    getDueYearList() {
        return this.http.get(`${environment.apiUrl}/complianceparadue/year-list`);
    }
    getDueAuditUnitTypeList() {
        return this.http.get(`${environment.apiUrl}/complianceparadue/audit-unit-type`);
    }
    dueAuditUnitList(param) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/audit-unit-list`, { params: param });
    }
    getDueList(param) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/list`, { params: param });
    }
    getDueParaList(param) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/duepara`, { params: param });
    }

    getOverDueList(param) {
        return this.http.get(`${environment.apiUrl}/overdue/list`, { params: param });
    }

    getOverDueParaList(param) {
        return this.http.get(`${environment.apiUrl}/overdue/overduepara`, { params: param });
    }

    dueParaPdfDownload(param) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/pdf-download`, { params: param, responseType: 'blob' });
    }

    dueListSendMail(param) {
        return this.http.post(`${environment.apiUrl}/complianceparadue/send-due-para-mail`, param);
    }

    getAllDiary(param) {
        return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/list`, { params: param });
    }

    getRegionalOfficeByYear(param) {
      return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/getAllRegionalOfficeByYear`, { params: param });
  }

    getBasicAuditUnitList() {
        return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/auditUnitList`);
    }

    getAllMembers() {
        return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/memeberList`);
    }

    getAllWorks(param) {
        return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/workList`, { params: param });
    }

    addDailyDiary(param) {
        return this.http.post(`${environment.apiUrl}/dailyDiaryMaintanance/create`, param);
    }
    dailyDiaryEditList(id) {
        return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/editDetails/${id}`);
    }
    updateDailyDiary(param) {
        return this.http.put(`${environment.apiUrl}/dailyDiaryMaintanance/edit`, param);
    }
    deleteDailyDiary(id) {
        return this.http.delete(`${environment.apiUrl}/dailyDiaryMaintanance/deleteDailyDiary/${id}`);
    }
    overDueListSendMail(param) {
        return this.http.post(`${environment.apiUrl}/overdue/send-over-due-mail`, param);
    }
    getChecklistType() {
        return this.http.get(`${environment.apiUrl}/execution-checklist/checklist-type-list`);
    }

    getDailyDiaryView(id) {
        return this.http.get(`${environment.apiUrl}/dailyDiaryMaintanance/view/${id}`);
    }
    getMapData(id) {
        return this.http.get(`${environment.apiUrl}/audit-plan/route-map/${id}`);
    }
    covertFS(param) {
        return this.http.put(`${environment.apiUrl}/overdue/convert-compliance`, param);
    }

    getFsCompliance(param) {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/fsParaList`, { params: param });
    }

    approveFsCompliance(param) {
        return this.http.put(`${environment.apiUrl}/fsParaCompliance/approve`, param);
    }

    getFsComplianceYear() {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/yearList`);
    }

    getFsUnitType() {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/unitTypelist`);
    }

    addFsCompliance(data, headers) {
        return this.http.post(`${environment.apiUrl}/fsParaCompliance/createComply`, data, { headers: headers });
    }

    requestUnitIncharge(param) {
        return this.http.put(`${environment.apiUrl}/preplan/request-unit-incharge`, param);
    }

    getRequestUnitIncharge(param) {
        return this.http.get(`${environment.apiUrl}/set-risk-assessment/showparam`, { params: param });
    }
    saveRequestUnitIncharge(param) {
        return this.http.put(`${environment.apiUrl}/set-risk-assessment/saveparam`, param);
    }
    dailyCalendar(param) {
        return this.http.get(`${environment.apiUrl}/dailyDiaryCalender/list`, { params: param });
    }

    getChecklistTypeList() {
        return this.http.get(`${environment.apiUrl}/executionChecklistQuestionManagement/checklist-type-list`);
    }

    getGrievanceList(param) {
        return this.http.get(`${environment.apiUrl}/grievance/resolve-grievance-list`, { params: param });
    }

    getAdministrativeOfficeList() {
        return this.http.get(`${environment.apiUrl}/administrative-office/basiclist`);
    }

    getMyGrievances(param) {
        return this.http.get(`${environment.apiUrl}/grievance/list`, { params: param });
    }

    addCommunicate(data, headers) {
        return this.http.post(`${environment.apiUrl}/grievance/communication`, data, { headers: headers });
    }
    getGrievamceType() {
        return this.http.get(`${environment.apiUrl}/grievance/type-list`);
    }
    addGrievance(data, headers) {
        return this.http.post(`${environment.apiUrl}/grievance/create`, data, { headers: headers });
    }
    getPriority() {
        return this.http.get(`${environment.apiUrl}/grievance/priority-list`);
    }

    fsComplianceSendMail(param) {
        return this.http.post(`${environment.apiUrl}/fsParaCompliance/sendFSComplianceMail`, param);
    }

    fsCompliancePdfDownload(param) {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/zip-download`, { params: param, responseType: 'blob' });
    }
    closeGrievance(param) {
        return this.http.put(`${environment.apiUrl}/grievance/close`, param);
    }
    ownGrievanceDetails(id) {
        return this.http.get(`${environment.apiUrl}/grievance/own-details/${id}`);
    }

    downloadGrievanceFile(id) {
        return this.http.get(`${environment.apiUrl}/grievance/get-file/${id}`, { params: {}, responseType: 'blob' });
    }

    getCalendarYearList() {
        return this.http.get(`${environment.apiUrl}/dailyDiaryCalender/yearList`);
    }
    getCalendarMemberList(param) {
        return this.http.get(`${environment.apiUrl}/dailyDiaryCalender/member`, { params: param });
    }
    dueParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/due-para-details/${id}`);
    }
    overDueParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/overdue/para-details/${id}`);
    }
    addReopenCommunicate(data, headers) {
        return this.http.post(`${environment.apiUrl}/grievance/reopen`, data, { headers: headers });
    }

    grievanceListDetails(id) {
        return this.http.get(`${environment.apiUrl}/grievance/details/${id}`);
    }

    closeCommunicate(data, headers) {
        return this.http.post(`${environment.apiUrl}/grievance/close`, data, { headers: headers });
    }

    proposed(param) {
        return this.http.put(`${environment.apiUrl}/overdue/propose-para`, param);
    }

    getCommunicateDraftPara(param) {
        return this.http.get(`${environment.apiUrl}/communicateDraftPara/dpParaListReport`, { params: param });
    }
    getCommunicateYearList() {
        return this.http.get(`${environment.apiUrl}/communicateDraftPara/yearList`);
    }
    getCommunicateUnitType() {
        return this.http.get(`${environment.apiUrl}/communicateDraftPara/unitTypelist`);
    }
    getReportPublishedParaList(param) {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/para-list`, { params: param });
    }
    getPublishParaYearList() {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/year`);
    }
    getPublishParaUnitName(param) {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/auditunits`, { params: param });
    }
    getPublishParaOfficeList() {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/offices
        `);
    }

    communicateDraftParaZipDownload(param) {
        return this.http.get(`${environment.apiUrl}/communicateDraftPara/zip-download`, { params: param, responseType: 'blob' });
    }
    communicateDraftParaSendMail(param) {
        return this.http.post(`${environment.apiUrl}/communicateDraftPara/communicate`, param);
    }

    approveCommunicateDraftPara(param) {
        return this.http.put(`${environment.apiUrl}/communicateDraftPara/approve`, param);
    }

    communicateDraftParaPublish(param) {
        return this.http.put(`${environment.apiUrl}/communicateDraftPara/publish`, param);
    }

    getComplyDraftPara(param) {
        return this.http.get(`${environment.apiUrl}/complyDP/dpParaListAdministrative`, { params: param })
    }
    addComplyDraftPara(data, headers) {
        return this.http.post(`${environment.apiUrl}/complyDP/createComply`, data, { headers: headers });
    }

    complyDraftParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/complyDP/complianceList/${id}`);
    }

    communicateDraftParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/communicateDraftPara/complianceList/${id}`);
    }

    reportPublishedParaSendMail(param) {
        return this.http.post(`${environment.apiUrl}/annual-report-published-para/communicate`, param);
    }

    insertForwardData(param) {
        return this.http.post(`${environment.apiUrl}/annual-report-published-para/forward`, param);
    }

    publishParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/complianceList/${id}`);
    }

    getReportPublishedComplianceParaList(param) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/para-list`, { params: param });
    }

    getPublishComplyParaOfficeList() {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/offices`);
    }

    getPublishComplyParaUnitName(param) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/auditunits`, { params: param });
    }
    getPublishComplyParaYearList() {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/year`);
    }

    insertComplyForwardData(param) {
        return this.http.put(`${environment.apiUrl}/annual-report-compliance/forward`, param);
    }
    addPublishComplyPara(data, headers) {
        return this.http.put(`${environment.apiUrl}/annual-report-compliance/comply`, data, { headers: headers });
    }
    approvePublishComplyPara(param) {
        return this.http.put(`${environment.apiUrl}/annual-report-compliance/approveReject`, param);
    }
    getRemark(param) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/compliance-detail-for-approval`, { params: param });
    }

    getManagePublishPara(param) {
        return this.http.get(`${environment.apiUrl}/lbpric/list`, { params: param })
    }

    getManageParaOfficeList() {
        return this.http.get(`${environment.apiUrl}/lbpric/region-office`);
    }

    getManageParaUnitName(param) {
        return this.http.get(`${environment.apiUrl}/lbpric/audit-unit-list`, { params: param });
    }
    getManageParaYearList() {
        return this.http.get(`${environment.apiUrl}/lbpric/year-list`);
    }
    getDistrictData(id) {
        return this.http.get(`${environment.apiUrl}/district/basiclist/${id}`)
    }

    dropManagePublishPara(param) {
        return this.http.put(`${environment.apiUrl}/lbpric/drop`, param);
    }

    getQuestionList(param) {
        return this.http.get(`${environment.apiUrl}/lbpric/questions-list`, { params: param });
    }
    savePublishQuestionAnswer(param) {
        return this.http.put(`${environment.apiUrl}/lbpric/answer`, param);
    }
    addDiscussActionDetails(param) {
        return this.http.put(`${environment.apiUrl}/lbpric/discus-action-taken`, param);
    }
    getDiscussActionDetails(param) {
        return this.http.get(`${environment.apiUrl}/lbpric/discus-action-details`, { params: param });
    }
    managePublishParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/lbpric/details/${id}`)
    }
    getManageParaCategory() {
        return this.http.get(`${environment.apiUrl}/lbpric/category-list`);
    }

    downloadParaFile(id) {
        return this.http.get(`${environment.apiUrl}/lbpric/para-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadComplianceFile(id) {
        return this.http.get(`${environment.apiUrl}/lbpric/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadPublishParaFile(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/para-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadPublishComplianceParaFile(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadParaFiles(id) {
        return this.http.get(`${environment.apiUrl}/paraComplianceApproval/para-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadComplyFiles(id) {
        return this.http.get(`${environment.apiUrl}/paraComplianceApproval/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadComplyDPParaFile(id) {
        return this.http.get(`${environment.apiUrl}/complyDP/para-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadComplyComplianceFile(id) {
        return this.http.get(`${environment.apiUrl}/complyDP/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }

    viewProfile() {
        return this.http.get(`${environment.apiUrl}/userProfile/userDetails`);
    }
    getChecklistTransaction(param) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/transactions`, { params: param });
    }

    downloadMemoFile(id) {
        return this.http.get(`${environment.apiUrl}/lbpric/memo-file-download/${id}`, { params: {}, responseType: 'blob' });
    }

    downloadMemoResponseFile(id) {
        return this.http.get(`${environment.apiUrl}/lbpric/memo-response-file-download/${id}`, { params: {}, responseType: 'blob' });
    }

    getDocumentCatalogue(param) {
        return this.http.get(`${environment.apiUrl}/document-catalogue/file-list`, { params: param });
    }
    getRoleData() {
        return this.http.get(`${environment.apiUrl}/catalogueUpload/roles`);
    }
    getUserData(param) {
        return this.http.get(`${environment.apiUrl}/catalogueUpload/users`, { params: param });
    }
    insertCatalogue(data, headers) {
        return this.http.post(`${environment.apiUrl}/catalogueUpload/upload`, data, { headers: headers });
    }
    getCatalogue(id) {
        return this.http.get(`${environment.apiUrl}/catalogueUpload/getFileDetails/${id}`);
    }
    updateCatalogue(param) {
        return this.http.put(`${environment.apiUrl}/catalogueUpload/edit`, param);
    }
    downloadCatalogueFile(id) {
        return this.http.get(`${environment.apiUrl}/catalogueUpload/download/${id}`, { params: {}, responseType: 'blob' });
    }

    getFsComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/complianceList/${id}`)
    }

    downloadFsParaComplianceFile(id) {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }

    downloadFsParaFile(id) {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/para-download/${id}`, { params: {}, responseType: 'blob' });
    }

    downloadOverdueParaComplianceFile(id) {
        return this.http.get(`${environment.apiUrl}/overdue/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }

    downloadOverdueParaFile(id) {
        return this.http.get(`${environment.apiUrl}/overdue/para-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadDueParaComplianceFile(id) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadDueParaFile(id) {
        return this.http.get(`${environment.apiUrl}/complianceparadue/para-download/${id}`, { params: {}, responseType: 'blob' });
    }

    getArchivedList(param) {
        return this.http.get(`${environment.apiUrl}/document-catalogue/archived-file-list`, { params: param });
    }
    doArchive(param) {
        return this.http.put(`${environment.apiUrl}/catalogueUpload/archive`, param);
    }
    publishParaComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/complianceList/${id}`)
    }
    downloadReportPublishParaFile(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/para-download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadReportParaComplianceFile(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/para-compliance-download/${id}`, { params: {}, responseType: 'blob' });
    }

    getDueCompliance(param) {
        return this.http.get(`${environment.apiUrl}/mis-report/due-compliance-list`, { params: param });
    }
    getDueComplianceOfficeList() {
        return this.http.get(`${environment.apiUrl}/mis-report/region-office`);
    }
    getDueCompliancePartyList(param) {
        return this.http.get(`${environment.apiUrl}/auditParty/partyByOfficeId`, { params: param });
    }
    getDueComplianceUnitName(param) {
        return this.http.get(`${environment.apiUrl}/mis-report/audit-unit-list`, { params: param });
    }
    getDueComplianceCategory() {
        return this.http.get(`${environment.apiUrl}/mis-report/category-list`);
    }
    getFinancialYearList() {
        return this.http.get(`${environment.apiUrl}/pending-paras-and-report/financialYearList`);
    }
    getMonthList() {
        return this.http.get(`${environment.apiUrl}/balance-sheet-report/monthList`);
    }
    getMonthListSeriousParaComplianceOverdue(param) {
        return this.http.get(`${environment.apiUrl}/serious-para-compliance-overdue/monthList`, { params: param });
    }
    getUnitTypeListSeriousParaComplianceOverdue(param) {
        return this.http.get(`${environment.apiUrl}/serious-para-compliance-overdue/roWiseUnitTypeList`, { params: param });
    }
    downloadDueComplianceFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-report/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadOverDueFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-report/over-due/export-file`, { params: param, responseType: 'blob', headers: headers });
    }
    getOverdueList(param) {
        return this.http.get(`${environment.apiUrl}/mis-report/overDueList`, { params: param });
    }
    getOverdueOfficeList() {
        return this.http.get(`${environment.apiUrl}/mis-report/regionOffice`);
    }
    getOverdueCategory() {
        return this.http.get(`${environment.apiUrl}/mis-report/categorylist`);
    }
    getOverdueUnit(param) {
        return this.http.get(`${environment.apiUrl}/mis-report/auditUnit-list`, { params: param });
    }
    getPendingAuditReport(param) {
        return this.http.get(`${environment.apiUrl}/mis-report/pending-audit-report-list`, { params: param });
    }
    getAuditReportIssued(param) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/stateViewList`, { params: param });
    }
    getPendingParaAndReport() {
        return this.http.get(`${environment.apiUrl}/pending-paras-and-report/stateViewList`);
    }
    getPendingParaAndReportRegional(param) {
        return this.http.get(`${environment.apiUrl}/pending-paras-and-report/regionalViewList`, { params: param });
    }
    getBalanceSheetRegional(param) {
        return this.http.get(`${environment.apiUrl}/balance-sheet-report/regionalViewList`, { params: param });
    }
    getFirstComplianceDueList(param) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/stateViewList`, { params: param });
    }
    getCategorywiseSeriousParasList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/stateViewList`, { params: param });
    }
    getCategorywiseSeriousParasRegionalViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/regionalViewList`, { params: param });
    }
    getCategorywiseSeriousParasUnitTypeViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/unitTypeViewList`, { params: param });
    }
    getCategorywiseSeriousParasUnitViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/unitViewList`, { params: param });
    }
    getCategoryWiseComplianceDueList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/stateViewList`, { params: param });
    }
    getSeriousParaComplianceOverDueList(param) {
        return this.http.get(`${environment.apiUrl}/serious-para-compliance-overdue/stateViewList`, { params: param });
    }
    getCategoryUnitTypeTotalObjAmt(param) {
        return this.http.get(`${environment.apiUrl}/category-unittype-total-objamount/stateViewList`, { params: param });
    }
    getCategoryUnitTypeTotalObjAmtRegionalView(param) {
        return this.http.get(`${environment.apiUrl}/category-unittype-total-objamount/regionalViewList`, { params: param });
    }
    getCategoryUnitTypeTotalObjAmtUnitTypeView(param) {
        return this.http.get(`${environment.apiUrl}/category-unittype-total-objamount/unitTypeViewList`, { params: param });
    }

    getCategoryUnitTypeTotalObjAmtUnitView(param) {
        return this.http.get(`${environment.apiUrl}/category-unittype-total-objamount/unitViewList`, { params: param });
    }
    getUnitTypeWiseUnitList(param) {
        return this.http.get(`${environment.apiUrl}/category-unittype-total-objamount/unitTypeWiseUnitList`, { params: param });
    }
    getSeriousParaComplianceOverDueRegionalViewList(param) {
        return this.http.get(`${environment.apiUrl}/serious-para-compliance-overdue/regionalViewList`, { params: param });
    }
    getSeriousParaComplianceOverDueUnitTypeViewList(param) {
        return this.http.get(`${environment.apiUrl}/serious-para-compliance-overdue/unitTypeViewList`, { params: param });
    }
    getFirstComplianceDueRegionViewList(param) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/regionalViewList`, { params: param });
    }
    getCategoryWiseComplianceDueRegionViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/regionalViewList`, { params: param });
    }

    getFirstComplianceDuePartyViewList(param) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/partyViewList`, { params: param });
    }
    getCategoryWiseComplianceDuePartyViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/partyViewList`, { params: param });
    }

    getFirstComplianceDueUnitTypeViewList(param) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/unitTypeViewList`, { params: param });
    }
    getCategoryWiseComplianceDueUnitTypeViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/unitTypeViewList`, { params: param });
    }

    getFirstComplianceDueUnitViewList(param) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/unitViewList`, { params: param });
    }
    getCategoryWiseComplianceDueUnitViewList(param) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/unitViewList`, { params: param });
    }

    getAuditReportIssuedRegionalView(param) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/regionalOfficeView`, { params: param });
    }
    getAuditReportIssuedPartyView(param) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/partyView`, { params: param });
    }
    getAuditReportIssuedUnitTypeView(param) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/unitTypeView`, { params: param });
    }
    getAuditReportIssuedUnitView(param) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/unitView`, { params: param });
    }
    downloadPendingAuditReportFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-report/pending-audit-report/export-file`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadFirstComplianceDueFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/stateViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategorywiseSeriousParasFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/stateViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategorywiseSeriousParasRegionalViewFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/regionalViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategorywiseSeriousParasUnitTypeViewFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/unitTypeViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategorywiseSeriousParasUnitViewFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise-status-of-sertious-paras/unitViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategoryWiseComplianceDueFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/stateViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadFirstComplianceDueRegionalFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/regionalViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadAuditReportIssuedRegionalFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/regionalViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategoryWiseComplianceDueRegionalFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/regionalViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategoryWiseComplianceDuePartyFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/partyViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadFirstComplianceDuePartyFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/partyViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadAuditReportIssuedPartyFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/partyView/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadAuditReportIssuedUnitTypeFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/unitTypeView/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadAuditReportIssuedUnitFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/unitView/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategoryWiseComplianceDueUnitTypeFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/unitTypeViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadFirstComplianceDueUnitTypeFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/unitTypeViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadCategoryWiseComplianceDueUnitFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/category-wise -compliance-due/unitViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadFirstComplianceDueUnitFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-first-compliance-due/unitViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadAuditReportIssuedFile(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-audit-report-issued/stateViewList/download-excel`, { params: param, responseType: 'blob', headers: headers });
    }
    downloadDocumentFile(id, param) {
        return this.http.get(`${environment.apiUrl}/catalogueUpload/download/${id}`, { params: param, responseType: 'blob' });
    }

    getAuditManDays(param) {
        return this.http.get(`${environment.apiUrl}/auditManDay/list`, { params: param });
    }

    getMandaysAuditType() {
        return this.http.get(`${environment.apiUrl}/auditManDay/auditTypeList`);
    }
    getManDayAuditUnitType() {
        return this.http.get(`${environment.apiUrl}/auditManDay/auditUnitTypeList`);
    }

    getArchiveOfficeList() {
        return this.http.get(`${environment.apiUrl}/document-catalogue/office-list`);
    }
    getArchiveUnitType() {
        return this.http.get(`${environment.apiUrl}/document-catalogue/unit-type-list`);
    }
    getArchiveYearList() {
        return this.http.get(`${environment.apiUrl}/document-catalogue/year-list`);
    }
    getUpdateManDays(auditTypeId, unitTypeId) {
        return this.http.get(`${environment.apiUrl}/auditManDay/editlist/${auditTypeId}/${unitTypeId}`);
    }
    editManDays(param) {
        return this.http.put(`${environment.apiUrl}/auditManDay/edit`, param);
    }
    getDownloadCatalogue(param) {
        return this.http.get(`${environment.apiUrl}/catalogue-download/list`, { params: param });
    }
    downloadDocument(id) {
        return this.http.get(`${environment.apiUrl}/catalogue-download/download/${id}`, { responseType: 'blob' });
    }
    rejectPara(param) {
        return this.http.put(`${environment.apiUrl}/auditreport/para-reject`, param);
    }

    getParaReportEditData(id) {
        return this.http.get(`${environment.apiUrl}/auditreport/para/${id}`);
    }

    EditParareport(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/auditreport/update-para', data, { headers: headers });
    }

    getAllProcessesParaReport(id) {
        return this.http.get(`${environment.apiUrl}/auditreport/process-list/${id}`);
    }

    getPendingAuditYear() {
        return this.http.get(`${environment.apiUrl}/para/pendingAuditYearListForAdd`);
    }
    getPendingAuditYearPara() {
        return this.http.get(`${environment.apiUrl}/para/pendingAuditYearListForEdit`);
    }
    getPendingAuditYearEditPara(param) {
        return this.http.get(`${environment.apiUrl}/para/pendingAuditYearListForEdit`, { params: param });
    }
    getDropType() {
        return this.http.get(`${environment.apiUrl}/lbpric/drop-type-list`);
    }
    downloadSentItemFile(id) {
        return this.http.get(`${environment.apiUrl}/inbox/get-file/${id}`, { responseType: 'blob' });
    }

    getObservationTypeData() {
        return this.http.get(`${environment.apiUrl}/auditmemo/observationTypeList`);
    }

    getAuditUnitGroup() {
        return this.http.get(`${environment.apiUrl}/auditUnitType/unit-group`);
    }

    getBudgetAccount(param) {
        return this.http.get(`${environment.apiUrl}/budgetAccountHead/list`, { params: param });
    }

    getObservationTypeList() {
        return this.http.get(`${environment.apiUrl}/budgetAccountHead/observationTypeList`);
    }

    getParaObservationTypeList(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/observation-type-list`, { params: param })
    }
    getParaObservationType(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/para-edit-observation-type-list`, { params: param });
    }

    insertBudget(param) {
        return this.http.post(`${environment.apiUrl}/budgetAccountHead/create`, param);
    }
    updateBudget(param) {
        return this.http.put(`${environment.apiUrl}/budgetAccountHead/edit`, param);
    }
    getBudgetById(id) {
        return this.http.get(`${environment.apiUrl}/budgetAccountHead/editlist/${id}`)
    }
    approveBudget(param) {
        return this.http.put(`${environment.apiUrl}/budgetAccountHead/approveBudget`, param);
    }
    deleteBudget(id) {
        return this.http.delete(`${environment.apiUrl}/budgetAccountHead/delete/${id}`);
    }

    getParaObservationTeamplte(param) {
        return this.http.get(`${environment.apiUrl}/para-observation-template/list`, { params: param });
    }

    insertParaObservationTemplate(param) {
        return this.http.post(`${environment.apiUrl}/para-observation-template/create`, param);
    }
    updateParaObservationTemplate(param) {
        return this.http.put(`${environment.apiUrl}/para-observation-template/edit`, param);
    }
    getParaTemplateById(id) {
        return this.http.get(`${environment.apiUrl}/para-observation-template/editlist/${id}`)
    }
    deleteParaObservationTeamplte(id) {
        return this.http.delete(`${environment.apiUrl}/para-observation-template/delete/${id}`);
    }
    approveParaObservationTeamplte(param) {
        return this.http.put(`${environment.apiUrl}/para-observation-template/approve-reject`, param);
    }
    getObservationTemplateType(param) {
        return this.http.get(`${environment.apiUrl}/auditmemo/observationTypeList`, { params: param });
    }
    getObservationTemplateTypeEdit(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/observationTypeList`)
    }
    getDetails(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/observation-list-template`, { params: param })
    }

    getDetailsParaDescription(param) {
        return this.http.get(`${environment.apiUrl}/convert-to-para/para-edit-observation-list-template`, { params: param })
    }

    openingMeetingDetailsPdfDownload(id) {
        return this.http.get(`${environment.apiUrl}/openingMeeting/edit-list-pdf/${id}`, { responseType: 'blob' });
    }

    getBudget(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/budgetAccountHeadList/${id}`)
    }

    getPointDiscussion(param) {
        return this.http.get(`${environment.apiUrl}/pointOfDiscussion/list`, { params: param });
    }

    getGroupList() {
        return this.http.get(`${environment.apiUrl}/pointOfDiscussion/group-list`);
    }
    getAuditUnitType(id) {
        return this.http.get(`${environment.apiUrl}/pointOfDiscussion/unit-type-list/${id}`);
    }

    insertPointDiscussion(param) {
        return this.http.post(`${environment.apiUrl}/pointOfDiscussion/create`, param);
    }

    pointDiscussionDataById(id) {
        return this.http.get(`${environment.apiUrl}/pointOfDiscussion/editlist/${id}`);
    }

    savePointOfDiscussion(param) {
        return this.http.put(`${environment.apiUrl}/pointOfDiscussion/edit`, param);
    }

    getTemplateUnitType() {
        return this.http.get(`${environment.apiUrl}/auditUnitType/basiclist`);
    }

    openingMeetingSignOff(param) {
        return this.http.put(`${environment.apiUrl}/openingMeeting/sign-off`, param);
    }

    observationTypeList(param) {
        return this.http.get(`${environment.apiUrl}/observationType/list`, { params: param });
    }

    insertObservationType(param) {
        return this.http.post(`${environment.apiUrl}/observationType/create`, param);
    }
    getObservationTypeById(id) {
        return this.http.get(`${environment.apiUrl}/observationType/editlist/${id}`);
    }
    updateObservationType(param) {
        return this.http.put(`${environment.apiUrl}/observationType/edit`, param);
    }
    deleteObservationType(id) {
        return this.http.delete(`${environment.apiUrl}/observationType/delete/${id}`);
    }
    observationTypeapproveReject(param) {
        return this.http.put(`${environment.apiUrl}/observationType/approve-reject`, param);
    }
    getPointOfDiscussion(id) {
        return this.http.get(`${environment.apiUrl}/openingMeeting/point-of-discussion-List/${id}`);
    }

    getMemoObservationType(param) {
        return this.http.get(`${environment.apiUrl}/auditmemo/add-memo-observation-type-list`, { params: param });
    }

    getAllEntity(param) {
        return this.http.get(`${environment.apiUrl}/entityManagement/list`, { params: param });
    }
    getEntityYearList() {
        return this.http.get(`${environment.apiUrl}/entityManagement/year-list`);
    }

    getEntityUnit() {
        return this.http.get(`${environment.apiUrl}/entityManagement/unit-list`);
    }
    getEntityDetails(id) {
        return this.http.get(`${environment.apiUrl}/entityManagement/unit-details/${id}`);
    }
    createEntity(param) {
        return this.http.post(`${environment.apiUrl}/entityManagement/addEntity`, param);
    }

    getAllEntityTransaction(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/entity-list`, { params: param });
    }

    getEntityTransactionDetail(id) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/api-details/${id}`);
    }
    getIFMSAllTransactionList(param) {
        return this.http.get(`${environment.apiUrl}/ifms/ifms-transaction-data-list/`, { params: param });
    }

    getSummaryDetail(id) {
        return this.http.get(`${environment.apiUrl}/key-classification/key-details?entityId=${id}`);
    }

    getAllMateriality(param) {
        return this.http.get(`${environment.apiUrl}/materiality/entity-list`, { params: param });
    }

    getMaterialityDetailFunc(id) {
        return this.http.get(`${environment.apiUrl}/materiality/details?entityId=${id}`);
    }

    saveMaterialityPlan(id, params) {
        return this.http.post(`${environment.apiUrl}/materiality/save-planning?entityId=${id}`, params);
    }

    saveMaterialityPerformance(id, params) {
        return this.http.post(`${environment.apiUrl}/materiality/save-performance?entityId=${id}`, params);
    }

    getAllEntityTransactionList(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/api-transaction-list`, { params: param });
    }

    entityApiTransactionSync(planMapId) {
        let param = {};
        return this.http.post(`${environment.apiUrl}/entity-transaction/sync-api-transaction?planMapId=${planMapId}`, param);
    }

    entityApiTransactionDelaration(entityId) {
        let param = {};
        return this.http.put(`${environment.apiUrl}/entity-transaction/provide-api-declaration?entityId=${entityId}`, param);
    }

    getEntityApiTransactionAuditAreaList(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/audit-area-list`, { params: param });
    }

    mapEntityTransactionAuditArea(param) {
        return this.http.put(`${environment.apiUrl}/entity-transaction/map-api-audit-area`, param);
    }
    mapEntityTransactionAuditAreaIFMS(param) {
        return this.http.put(`${environment.apiUrl}/ifms/save-Transactions-data`, param);
    }

    getAllEntityExcelTransaction(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/entity-excel-list`, { params: param });
    }

    getAllClassificationAudit(param) {
        return this.http.get(`${environment.apiUrl}/key-classification/entity-list`, { params: param });
    }

    getEntityExcelTransactionDetail(id) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/excel-details/${id}`);
    }

    getAllEntityExcelTransactionList(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/excel-transaction-list`, { params: param });
    }

    mapEntityExcelTransactionAuditArea(param) {
        return this.http.put(`${environment.apiUrl}/entity-transaction/map-excel-audit-area`, param);
    }

    entityExcelTransactionDelaration(entityId) {
        let param = {};
        return this.http.put(`${environment.apiUrl}/entity-transaction/provide-excel-declaration?entityId=${entityId}`, param);
    }

    uploadEntityExcel(param) {
        return this.http.post(`${environment.apiUrl}/entity-transaction/upload-excel`, param);
    }

    downloadEntityExcelTemplate() {
        return this.http.get(`${environment.apiUrl}/entity-transaction/download-template`, { params: {}, responseType: 'blob' });
    }

    downloadBankTransactions(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/download-bank-transactions`, { params: param, responseType: 'blob' });
    }

    getTransactionSummaryList(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/get-summary`, { params: param });
    }

    saveTransSummaryAuditTotal(param) {
        return this.http.put(`${environment.apiUrl}/entity-transaction/give-audit-area-wise-total`, param);
    }

    saveTransSummaryEntityTotal(param) {
        return this.http.put(`${environment.apiUrl}/entity-transaction/give-entity-wise-total`, param);
    }

    getTransactionSummarySamplingList(param) {
        return this.http.get(`${environment.apiUrl}/entity-transaction/transaction-for-sampling-list`, { params: param });
    }

    saveSamplingDeclaration(entityId, transType) {
        return this.http.put(`${environment.apiUrl}/entity-transaction/select-for-sampling?entityId=${entityId}&transactionDataType=${transType}`, {});
    }

    programManagementList(param) {
        return this.http.get(`${environment.apiUrl}/program/list`, { params: param });
    }

    programApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/program/approve-reject`, param);
    }
    createProgram(param) {
        return this.http.post(`${environment.apiUrl}/program/create`, param);
    }
    editProgramManagement(id) {
        return this.http.get(`${environment.apiUrl}/program/editlist/${id}`);
    }
    updateProgram(param) {
        return this.http.put(`${environment.apiUrl}/program/edit`, param);
    }

    getUnitTypeProgram() {
        return this.http.get(`${environment.apiUrl}/auditUnitType/basiclist`);
    }
    deleteProgram(id) {
        return this.http.delete(`${environment.apiUrl}/program/delete/${id}`);
    }
    getUnitData() {
        return this.http.get(`${environment.apiUrl}/entitySelection/unit-list`);
    }

    getAdditionalUnitData() {
        return this.http.get(`${environment.apiUrl}/auditunit/list/auditional-audit-units`);
    }

    getYearData() {
        return this.http.get(`${environment.apiUrl}/entitySelection/year-list`);
    }
    addEntity(param) {
        return this.http.post(`${environment.apiUrl}/entitySelection/selectEntity`, param);
    }
    getEntity() {
        return this.http.get(`${environment.apiUrl}/entitySelection/selected-entity`);
    }

    eventManagementList(param) {
        return this.http.get(`${environment.apiUrl}/event/list`, { params: param });
    }
    eventApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/event/approve`, param);
    }
    deleteEvent(id) {
        return this.http.delete(`${environment.apiUrl}/event/delete/${id}`);
    }
    addEvent(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/event/create', data, { headers: headers });
    }
    editEvent(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/event/edit-event', data, { headers: headers });
    }

    eventEditList(id) {
        return this.http.get(`${environment.apiUrl}/event/editlist/${id}`);
    }
    getAlertMsg(param) {
        return this.http.get(`${environment.apiUrl}/entitySelection/validate-openingmeeting-unit-mapping-in-select`, { params: param });
    }
    getAlertMsgUnit(param) {
        return this.http.get(`${environment.apiUrl}/entitySelection/validate-openingmeeting-unit-in-select`, { params: param });
    }
    getViewAllAlertMsg() {
        return this.http.get(`${environment.apiUrl}/entitySelection/validate-openingmeeting-in-add`);
    }
    getAuditPartyDetail() {
        return this.http.get(`${environment.apiUrl}/openingMeeting/party-member-List`);
    }
    getPublicEvent() {
        return this.http.get(`${environment.apiUrl}/public-event/list`);
    }

    latestNewsList(param) {
        return this.http.get(`${environment.apiUrl}/latest-news/list`, { params: param });
    }
    newsApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/latest-news/approve-reject`, param);
    }
    deleteNews(id) {
        return this.http.delete(`${environment.apiUrl}/latest-news/delete/${id}`);
    }
    addNews(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/latest-news/add', data, { headers: headers });
    }
    editNews(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/latest-news/edit', data, { headers: headers });
    }
    newsEditList(id) {
        return this.http.get(`${environment.apiUrl}/latest-news/details/${id}`);
    }

    getPublicNews() {
        return this.http.get(`${environment.apiUrl}/latest-news-public/list`);
    }

    getPublicNewsImage(id) {
        return this.http.get(`${environment.apiUrl}/latest-news-public/get-img/${id}`, { responseType: 'blob' });
    }

    publicHomePageContentList() {
        return this.http.get(`${environment.apiUrl}/public-home-page-content/list`);
    }
    getPublicHomePageImage(id) {
        return this.http.get(`${environment.apiUrl}/public-home-page-content/get-img/${id}`, { responseType: 'blob' });
    }

    editHomePageContent(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/home-page/update', data, { headers: headers });
    }

    homePageData() {
        return this.http.get(`${environment.apiUrl}/home-page/details`);
    }
    memoSignOff(param) {
        return this.http.put(`${environment.apiUrl}/auditmemo/sign-off`, param);
    }

    ruleGuidelinesList(param) {
        return this.http.get(`${environment.apiUrl}/rules-and-guidelines/list`, { params: param });
    }

    deleteRuleGuideline(id) {
        return this.http.delete(`${environment.apiUrl}/rules-and-guidelines/delete/${id}`);
    }

    getUnitTypeRules() {
        return this.http.get(`${environment.apiUrl}/rules-and-guidelines/unit-type-list`);
    }

    getcategoryRules() {
        return this.http.get(`${environment.apiUrl}/rules-and-guidelines/categoryList`);
    }
    addRules(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/rules-and-guidelines/addRule', data, { headers: headers });
    }
    editRule(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/rules-and-guidelines/edit-rule', data, { headers: headers });
    }

    ruleEditList(id) {
        return this.http.get(`${environment.apiUrl}/rules-and-guidelines/editlist/${id}`);
    }

    ruleApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/rules-and-guidelines/approve`, param);
    }
    otherLinkList(param) {
        return this.http.get(`${environment.apiUrl}/otherLinks/list`, { params: param });
    }
    deleteOtherLink(id) {
        return this.http.delete(`${environment.apiUrl}/otherLinks/${id}`);
    }
    otherLinkApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/otherLinks/approval`, param);
    }
    getOtherLinkType() {
        return this.http.get(`${environment.apiUrl}/otherLinks/types`);
    }
    addOtherLink(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/otherLinks/create', data, { headers: headers });
    }
    editOtherLink(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/otherLinks/edit', data, { headers: headers });
    }
    otherLinkEditList(id) {
        return this.http.get(`${environment.apiUrl}/otherLinks/${id}`);
    }
    getPublicOtherLink() {
        return this.http.get(`${environment.apiUrl}/public-other-links`);
    }
    downloadPublicOtherLinkFile(id) {
        return this.http.get(`${environment.apiUrl}/public-other-links/get-file/${id}`, { params: {}, responseType: 'blob' });
    }

    menuStatusList(param) {
        return this.http.get(`${environment.apiUrl}/status-type`, { params: param });
    }
    nonUnitReturn(param) {
        return this.http.put(`${environment.apiUrl}/paraComplianceApproval/returnToNonUnit`, param);
    }
    returnToUnit(param) {
        return this.http.put(`${environment.apiUrl}/paraComplianceApproval/returnToUnit`, param);
    }
    forwardHo(param) {
        return this.http.put(`${environment.apiUrl}/paraComplianceApproval/forward`, param);
    }

    getRulesList() {
        return this.http.get(`${environment.apiUrl}/public-rules-and-guidelines/category-list`);
    }

    getcategoryTypeList() {
        return this.http.get(`${environment.apiUrl}/public-rules-and-guidelines/category-list`);
    }

    getTypeList(catId) {
        return this.http.get(`${environment.apiUrl}/public-rules-and-guidelines/all-unit-type/${catId}`);
    }

    showDownloadTypeList(param) {
        return this.http.get(`${environment.apiUrl}/public-rules-and-guidelines/list`, { params: param });
    }

    getrulesRegulationList(id) {
        return this.http.get(`${environment.apiUrl}/public-rules-and-guidelines/list/${id}`, { responseType: 'blob' });
    }
    downloadPublicrulesandGuidelineFile(id) {
        return this.http.get(`${environment.apiUrl}/public-rules-and-guidelines/download/${id}`, { responseType: 'blob' });
    }
    getContentTypeDetaile(cId) {
        return this.http.get(`${environment.apiUrl}/public-other-links/get-content/${cId}`);
    }
    getAllLatestNews(param) {
        return this.http.get(`${environment.apiUrl}/latest-news-public/view-all-list`, { params: param });
    }
    getAllLatestNewsImage(id) {
        return this.http.get(`${environment.apiUrl}/latest-news-public/details-img/${id}`, { responseType: 'blob' });
    }

    getNewsDetailsContent(nID) {
        return this.http.get(`${environment.apiUrl}/latest-news-public/details/${nID}`);
    }

    getNewsDetailsImage(id) {
        return this.http.get(`${environment.apiUrl}/latest-news-public/details-img/${id}`, { responseType: 'blob' });
    }

    getAllEventsList(param) {
        return this.http.get(`${environment.apiUrl}/public-event/page`, { params: param });
    }
    getEventDetailsID(id) {
        return this.http.get(`${environment.apiUrl}/public-event/details/${id}`);
    }
    downloadEventDetailsFile(id) {
        return this.http.get(`${environment.apiUrl}/public-event/download/${id}`, { responseType: 'blob' });
    }
    inboxParaComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/paraComplianceApproval/inboxDetails/${id}`);
    }
    inboxSubmitParaComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/paraCompliance/inboxDetails/${id}`);
    }
    inboxFsComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/fsParaCompliance/inboxDetails/${id}`);
    }
    inboxComplyDPDetails(id) {
        return this.http.get(`${environment.apiUrl}/complyDP/inboxDetails/${id}`);
    }
    inboxCommunicateDraftParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/communicateDraftPara/inboxDetails/${id}`);
    }
    inboxPublishParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-published-para/inboxDetails/${id}`);
    }
    inboxPublishParaComplianceDetails(id) {
        return this.http.get(`${environment.apiUrl}/annual-report-compliance/inboxDetails/${id}`);
    }
    inboxManagePublishParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/lbpric/inboxDetails/${id}`);
    }
    inboxMemoDetails(id) {
        return this.http.get(`${environment.apiUrl}/auditmemo/inboxDetails/${id}`);
    }
    inboxParaDetails(id) {
        return this.http.get(`${environment.apiUrl}/para/inboxDetails/${id}`);
    }
    getConductYearList() {
        return this.http.get(`${environment.apiUrl}/auditreport/last-conduct-year-list`);
    }
    saveAuditReportFormat(param) {
        return this.http.post(`${environment.apiUrl}/audit-report-format/save`, param);
    }
    getAuditReportFormatData() {
        return this.http.get(`${environment.apiUrl}/audit-report-format/details`);
    }
    getExecutionUnitList() {
        return this.http.get(`${environment.apiUrl}/execution-checklist/unit-list`);
    }
    getExecutionProcesses(id) {
        if (id == 0) {
            return this.http.get(`${environment.apiUrl}/execution-checklist/process-list`);
        } else {
            let param = {
                planMapId: id
            };
            return this.http.get(`${environment.apiUrl}/execution-checklist/process-list`, { params: param });
        }
    }
    saveExecutionCheckList(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/execution-checklist/save', data, { headers: headers });
    }
    downloadExecutionFile(id) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/download/${id}`, { params: {}, responseType: 'blob' });
    }
    downloadTemplate(id) {
        return this.http.get(`${environment.apiUrl}/riskprocess/download/${id}`, { params: {}, responseType: 'blob', observe: 'response' });
    }

    downloadExcelTemplate(planMapId, processId) {
        return this.http.get(`${environment.apiUrl}/execution-checklist/download-execution-transactions?planMapId=${planMapId}&processId=${processId}`, { params: {}, responseType: 'blob', observe: 'response' });
    }

    getAuditTypeList() {
        return this.http.get(`${environment.apiUrl}/executionChecklistQuestionManagement/unit-type-list`);
    }
    getSsoUserProfile() {
        return this.http.get(`${environment.apiUrl}/sso/userProfile`);
    }
    getRoleList() {
        return this.http.get(`${environment.apiUrl}/sso/roleList`);
    }
    getOffice() {
        return this.http.get(`${environment.apiUrl}/sso/officeList`);
    }
    getDistrictList(param) {
        return this.http.get(`${environment.apiUrl}/sso/districtList`, { params: param });
    }
    getAdministrativeOffice() {
        return this.http.get(`${environment.apiUrl}/administrative-office/basiclist`);
    }
    getUnitName(param) {
        return this.http.get(`${environment.apiUrl}/sso/unitList`, { params: param });
    }
    getUnitNameForUserManagement(param): Observable<any> {
        return this.http.get(`${environment.apiUrl}/user/unitList`, { params: param });
    }
    getUnitNameForUserProfile(param) {
        return this.http.get(`${environment.apiUrl}/userProfile/unitList`, { params: param });
    }
    saveSsoUserProfile(param) {
        return this.http.post(`${environment.apiUrl}/sso/userProfile`, param);
    }
    getHelpData(param) {
        return this.http.get(`${environment.apiUrl}/help/list`, { params: param });
    }
    getEditHelpData(id) {
        return this.http.get(`${environment.apiUrl}/help/edit/${id}`);
    }
    updateHelpData(param) {
        return this.http.put(`${environment.apiUrl}/help/update`, param);
    }
    getPrapatraList() {
        return this.http.get(`${environment.apiUrl}/upload-prapatra/details`);
    }
    downloadPrapatraFile(id) {
        return this.http.get(`${environment.apiUrl}/upload-prapatra/download/${id}`, { responseType: 'blob' });
    }
    saveUploadPrapatraData(data, headers) {
        return this.http.post(`${environment.apiUrl}` + '/upload-prapatra/save', data, { headers: headers });
    }
    getUserRoleList() {
        return this.http.get(`${environment.apiUrl}/userProfile/roleList`);
    }
    getUserOffice() {
        return this.http.get(`${environment.apiUrl}/userProfile/officeList`);
    }
    getUserDistrictList(param) {
        return this.http.get(`${environment.apiUrl}/userProfile/districtList`, { params: param });
    }
    getUserAdministrativeOffice() {
        return this.http.get(`${environment.apiUrl}/administrative-office/basiclist`);
    }
    getUserUnitName(param) {
        return this.http.get(`${environment.apiUrl}/userProfile/unitList`, { params: param });
    }
    getUserProfileDetails() {
        return this.http.get(`${environment.apiUrl}/userProfile/userDetails`);
    }
    saveUserProfile(param) {
        return this.http.put(`${environment.apiUrl}/userProfile/userDetails`, param);
    }
    getRoleListUser() {
        return this.http.get(`${environment.apiUrl}/user/roleList`);
    }
    getUserOfficeList() {
        return this.http.get(`${environment.apiUrl}/user/officeList`);
    }
    getUserDesignation() {
        return this.http.get(`${environment.apiUrl}/user/designationList`);
    }
    getProfileDetails(id) {
        return this.http.get(`${environment.apiUrl}/user/editlist/${id}`);
    }
    updateUserProfile(param) {
        return this.http.put(`${environment.apiUrl}/user/edit`, param);
    }
    getHelp(shortCode) {
        return this.http.get(`${environment.apiUrl}/help/getValue/${shortCode}`);
    }
    getAllAuditFee(param) {
        return this.http.get(`${environment.apiUrl}/audit-fee/list`, { params: param });
    }
    approveAuditFee(param) {
        return this.http.put(`${environment.apiUrl}/audit-fee/approve-reject`, param);
    }
    deleteAuditFee(id) {
        return this.http.delete(`${environment.apiUrl}/audit-fee/delete/${id}`);
    }
    getAuditFeeAuditUnitTypeList() {
        return this.http.get(`${environment.apiUrl}/audit-fee/auditUnitTypeList`);
    }
    getAuditFeeYearList() {
        return this.http.get(`${environment.apiUrl}/audit-fee/yearList`);
    }
    getAuditFeeType() {
        return this.http.get(`${environment.apiUrl}/audit-fee/auditFeeType`);
    }
    getEditAuditFeeData(id) {
        return this.http.get(`${environment.apiUrl}/audit-fee/editlist/${id}`);
    }
    InsertAuditFee(param) {
        return this.http.post(`${environment.apiUrl}/audit-fee/create`, param);
    }
    updateAuditFee(param) {
        return this.http.put(`${environment.apiUrl}/audit-fee/edit`, param);
    }
    getPublicAuditcalendarYear() {
        return this.http.get(`${environment.apiUrl}/public-calendar/year-list`);
    }
    getPublicCalendarAuditTypeList() {
        return this.http.get(`${environment.apiUrl}/public-calendar/unit-type-list`);
    }
    getPublicCalendarDistrictList() {
        return this.http.get(`${environment.apiUrl}/public-calendar/district-list`);
    }
    getAuditCalendarList(param) {
        return this.http.get(`${environment.apiUrl}/public-calendar/list`, { params: param });
    }

    getCertificationRegion() {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/region-list`);
    }
    getCertificationAuditUnitType(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/unit-type-list`, { params: param });
    }
    getPartyListForCertificationAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/party-list`, { params: param });
    }
    savePartyListForCertificationAuditPlanning(param) {
        return this.http.post(`${environment.apiUrl}/certification-audit-planning/party-list`, param);
    }

    getUnitListForCertificationAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/unit-list`, { params: param });
    }

    saveUnitListForCertificationAuditPlanning(param) {
        return this.http.post(`${environment.apiUrl}/certification-audit-planning/unit-list`, param);
    }

    getRegionPlannedListForCertificationAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/region-planned-list`, { params: param });
    }
    getAllAuditPlans(regionId, yearId) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/plan-list/${regionId}/${yearId}`);
    }
    getAllAuditFeesCollection(param) {
        return this.http.get(`${environment.apiUrl}/audit-fee-collection/list`, { params: param });
    }
    getFeesCollectionData(id) {
        return this.http.get(`${environment.apiUrl}/audit-fee-collection/details/${id}`);
    }

    downloadAuditFeesCollectionFile(id) {
        return this.http.get(`${environment.apiUrl}/audit-fee-collection/download/${id}`, { params: {}, responseType: 'blob' });
    }
    saveAuditFeesCollectionList(data, headers) {
        return this.http.put(`${environment.apiUrl}` + '/audit-fee-collection/edit', data, { headers: headers });
    }
    getPaymentMOdeData() {
        return this.http.get(`${environment.apiUrl}/audit-fee-collection/auditFeeType`);
    }

    getAuditTrail(param) {
        return this.http.get(`${environment.apiUrl}/audit-trail/list`, { params: param });
    }

    getGPUnitListForCertificationAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/unit-list-for-gp`, { params: param });
    }
    saveGPUnitListForCertificationAuditPlanning(param) {
        return this.http.post(`${environment.apiUrl}/certification-audit-planning/unit-list-for-gp`, param);
    }
    approvePlans(regionId, yearId) {
        return this.http.put(`${environment.apiUrl}/certification-audit-planning-action/approve/${regionId}/${yearId}`, null);
    }
    generateCalendaForPlans(regionId, yearId) {
        return this.http.put(`${environment.apiUrl}/certification-audit-planning-action/generateCalendar/${regionId}/${yearId}`, null);
    }
    resetCalendarForPlans(regionId, yearId) {
        return this.http.put(`${environment.apiUrl}/certification-audit-planning-action/resetCalendar/${regionId}/${yearId}`, null);
    }
    SentForApprovalPlan(planId) {
        return this.http.put(`${environment.apiUrl}/certification-audit-planning-action/send-for-approval/${planId}`, null);
    }
    deletePlan(planId) {
        return this.http.delete(`${environment.apiUrl}/certification-audit-planning-action/delete/${planId}`);
    }
    rejectPlan(param) {
        return this.http.put(`${environment.apiUrl}/certification-audit-planning-action/reject`, param);
    }

    getDesignationList() {
        return this.http.get(`${environment.apiUrl}/sso/designationList`);
    }
    getUserDesignationList() {
        return this.http.get(`${environment.apiUrl}/userProfile/designationList`);
    }
    getAuditCertificationManday() {
        return this.http.get(`${environment.apiUrl}/audit-certification-man-day`);
    }

    saveAuditCertificationManDay(param) {
        return this.http.put(`${environment.apiUrl}/audit-certification-man-day`, param);
    }
    getAuditCertificationPartiesMappedList(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-parties-mapped/unit-party-list`, { params: param });
    }
    gePartyListForCertificationAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-parties-mapped/party-list`, { params: param });
    }

    getAuditFeeAuditTypeList() {
        return this.http.get(`${environment.apiUrl}/audit-fee/auditType`);
    }

    getMemoObservationTeamplte(param) {
        return this.http.get(`${environment.apiUrl}/memo-observation-template/list`, { params: param });
    }
    deleteMemoObservationTeamplte(id) {
        return this.http.delete(`${environment.apiUrl}/memo-observation-template/delete/${id}`);
    }

    insertMemoObservationTemplate(param) {
        return this.http.post(`${environment.apiUrl}/memo-observation-template/create`, param);
    }
    updateMemoObservationTemplate(param) {
        return this.http.put(`${environment.apiUrl}/memo-observation-template/edit`, param);
    }
    getMemoTemplateById(id) {
        return this.http.get(`${environment.apiUrl}/memo-observation-template/editlist/${id}`)
    }

    approveMemoObservationTeamplte(param) {
        return this.http.put(`${environment.apiUrl}/memo-observation-template/approve-reject`, param);
    }
    getMemoObservationTemplateType(param) {
        return this.http.get(`${environment.apiUrl}/auditmemo/observationTypeList`, { params: param });
    }
    getMemoAddDesignation(param) {
        return this.http.get(`${environment.apiUrl}/auditmemo/observation-list-template`, { params: param });
    }
    getOutstandingFirstCompliance(param) {
        return this.http.get(`${environment.apiUrl}/mis-report/due-first-compliance`, { params: param });
    }
    exportFirstCompliance(param, headers) {
        return this.http.get(`${environment.apiUrl}/mis-report/due-first-compliance/export-file`, { params: param, responseType: 'blob', headers: headers });
    }
    addAuditCertificate(id, param) {
        return this.http.post(`${environment.apiUrl}/audit-certificate/create/${id}`, param);
    }
    editAuditCertificate(id, param) {
        return this.http.put(`${environment.apiUrl}/audit-certificate/edit/${id}`, param);
    }
    getUnitListForAuditCretificateCreate() {
        return this.http.get(`${environment.apiUrl}/audit-certificate/unitlist-for-create`);
    }
    getLabelDataForAuditCretificateCreate(id) {
        return this.http.get(`${environment.apiUrl}/audit-certificate/label-data-for-create/${id}`);
    }
    getCertificateTypeListForAuditCretificate() {
        return this.http.get(`${environment.apiUrl}/audit-certificate/certificate-type-list`);
    }
    getCertificateData(param) {
        return this.http.get(`${environment.apiUrl}/audit-certificate/list`, param);
    }
    getAuditCertificateDetails(id) {
        return this.http.get(`${environment.apiUrl}/audit-certificate/detail/${id}`);
    }
    getFlyingQuestion(param) {
        return this.http.get(`${environment.apiUrl}/flying-squad-question/list`, { params: param });
    }
    createFlyingSquadQuestion(param) {
        return this.http.post(`${environment.apiUrl}/flying-squad-question/create`, param);
    }
    updateFlyingSquadQuestion(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-question/update`, param);
    }
    getFlyingSquadQuestionDetails(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-question/details/${id}`);
    }
    flyingSquadQuestionApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-question/approve-reject`, param);
    }

    getPendingYearList(param) {
        return this.http.get(`${environment.apiUrl}/auditmemo/pending-year`, { params: param });
    }
    getAuditUnitExecutedYearRegionList() {
        return this.http.get(`${environment.apiUrl}/auditunitexecutedyear/region-list`);
    }
    getAuditUnitExecutedYearDistrictList(param) {
        return this.http.get(`${environment.apiUrl}/auditunitexecutedyear/district-list`, { params: param });
    }
    getAuditUnitExecutedYearAuditUnitTypeList() {
        return this.http.get(`${environment.apiUrl}/auditunitexecutedyear/audit-unit-type-list`);
    }
    getAuditUnitExecutedYearAllFinancialYearList() {
        return this.http.get(`${environment.apiUrl}/auditunitexecutedyear/all-financial-year-list`);
    }
    getAuditUnitExecutedYearList(param) {
        return this.http.get(`${environment.apiUrl}/auditunitexecutedyear/executed-year-list`, { params: param });
    }
    saveAuditUnitExecutedYearList(param) {
        return this.http.post(`${environment.apiUrl}/auditunitexecutedyear/save`, param);
    }
    getROWiseAuditStageWiseStatus(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/ro-wise-audit-status`, { params: param });
    }
    getDistrictAuditStageWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/district-wise-audit-status`, { params: param });
    }
    getAuditStageWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/unit-type-wise-audit-status`, { params: param });
    }
    getTargetVsActualChart(param): Observable<any> {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/target-vs-actual`, { params: param });
    }
    getYearListForChart() {
        return this.http.get(`${environment.apiUrl}/year/list-till-current-execution`);
    }
    getChartTableDataForRoAuditStageWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/RO-wise-audit-stage-wise-status/${param.regionId}/${param.districtId}/${param.unitTypeId}/${param.yearId}/${param.dataType}`);
    }
    getChartTableDataForRoTargetVsActual(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/planned-vs-actual-audit-allocated-planed-list/${param.unitType}/${param.yearId}/${param.dataType}`);
    }
    getParaCategoryWiseFinancialImpactAnalysisChartData(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/para-category-wise-status-financial-impact`, { params: param });
    }
    getChartTableDataForParaCategoryWiseFinancialImpactAnalysis(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/para-category-wise-financial-impact-status/${param.yearId}/${param.compliedOrPending}/${param.xAxisIndex}`);
    }
    getAuditConductStatusOfUnitChart(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/audit-conduct-status`, { params: param });
    }
    getTotalParasOfUnit(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/total-paras-of-unit`, { params: param });
    }
    getObservationTypeWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-observation-type-wise`, { params: param });
    }
    getUnitTypeWiseFinancialImpactAnalysis(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/unit-type-wise-financial-impact`, { params: param });
    }
    getChartTableDataForUnitTypeWiseFinancialImpactAnalysis(param) {
        // return this.http.get(`${environment.apiUrl}/roho-dashboard/unit-type-wise-financial-impact-table`, { params: param });
        return this.http.get(`${environment.apiUrl}/roho-dashboard/unit-Type-wise-financial-impact-analysis/${param.unitTypeId}/${param.yearId}/${param.dataType}`);
    }
    getObservationTypeWiseObservationAmount(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/observation-type-wise-amount`, { params: param });
    }
    getObservationTypeWiseYearWiseChart(param) {
        console.log(param);
        // return this.http.get(`${environment.apiUrl}/roho-dashboard/observation-type-wise-amount-by-year`);
        return this.http.get(`${environment.apiUrl}/roho-dashboard/observation-type-wise-amount-by-year`,{ params: param });
    }
    getRiskAnalysisUnits(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/risk-analysis-of-units`, { params: param });
    }
    getYearWiseObservationAmountType(param): Observable<any> {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/observation-type-wise-amount-by-year`, { params: param });
    }
    getUnitTypeWisePendingParas(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/unit-type-wise-pending-paras`, { params: param });
    }
    getChartTableDataForYearWiseObservationAmountType(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/observation-type-wise-amount-by-year-table`, { params: param });
    }
    getChartTableDataForUnitTypeWisePendingParas(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/unit-type-wise-pending-paras-table`, { params: param });
    }
    getParaAnalysisAuditYearWise(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/para-analysis-by-year`, { params: param });
    }
    getComplianceNonComplianceStatusPara(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/para-by-category-year`, { params: param });
    }
    getChartTableDataForcomplianceNonComplianceStatusPara(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/para-by-category-year-table`,{ params: param });
    }
    getParaCategoryWiseAuditYearWise(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/para-by-year-category`, { params: param });
    }
    getObservationAmountUnit(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-year-wise`, { params: param });
    }
    getObservationAmountParaCatagoryWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-category-year-wise`, { params: param });
    }
    getChartTableDataForObservationAmountParaCatagoryWise(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-category-year-wise-drilldown`, { params: param });
    }
    getObservationAmountUnitObservationTypeWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-observation-type-and-year-wise`, { params: param });
    }
    getChartTableDataForObservationAmountUnitObservationTypeWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-observation-type-and-year-wise-drilldown`,{ params: param });
    }
    getYearWiseAuditAreaWiseObservationAmount(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-process-year-wise`, { params: param });
    }
    getChartTableDataForYearWiseAuditAreaWiseObservationAmount() {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-process-year-wise-table`);
    }
    getAmountOfUnitProcessType(param) {
        return this.http.get(`${environment.apiUrl}/unit-dashboard/observation-amount-process-wise`, { params: param });
    }
    getAuditStatusChart(param) {
        var yearData = {
            'year':param.yearId
        };
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/audit-status`, { params: yearData });
    }
    getAllocatedUnitChart(param) {
        // return this.http.get(`${environment.apiUrl}/audit-party-dashboard/allocated-unit/${param.yearId}`);
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/allocated-unit`,{ params: param });
    }
    getUnitsAuditedTillPresent(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/audited-unit`, { params: param });
    }
    getUniteAuditedTillPresentChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/audited-unit-by-unit-type`, { params: param });
    }
    getTotalAuditedUnitRiskWise(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/risk-wise-audited-unit`, { params: param });
    }
    getTotalAuditedUnitRiskWiseChartChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/audited-unit-by-risk`, { params: param });
    }
    getChartTableDataForTotalAuditedUnitRiskWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/audited-unit-by-risk-table1`, { params: param });
    }
    getOutstandingParasByParaCategoryWiseAndYearWise(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/outstanding-para-by-category-year`, { params: param });
    }
    getOutstandingParaByCategoryTypeChartChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/outstanding-para-by-category-type`, { params: param });
    }
    getChartTableDataForOutstandingParaByCategoryTypeChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/outstanding-para-by-category-type-drilldown`, { params: param });
    }
    getOutstandingParasUnitTypeWise(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/para-by-unit-type`, { params: param });
    }
    getOutstandingParasAuditYearWiseParticularUnitTypeChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/para-by-unit-type-year-wise`, { params: param });
    }
    getChartTableDataForoutstandingParasAuditYearWiseParticularUnitType(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/para-by-unit-type-year-wise-drilldown`, { params: param });
    }
    getRecoveredAmoutCompliedParas(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/year-wise-recovery-amount`, { params: param });
    }
    getRecoveredAmountUnitTypeWiseParticularYear(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/unit-type-wise-recovery-amount`, { params: param });
    }
    getChartTableDataForRecoveredAmountUnitTypeWiseParticularYear(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/unit-type-wise-recovery-amount-table`, { params: param });
    }
    getObservactionAmoutOutstandingParas(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-amount-of-pending-paras`, { params: param });
    }
    getObservationAmountOutstandingParaUnitTypeWise(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-amount-of-pending-paras-unit-type-wise`, { params: param });
    }
    getReportSubmitted(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/unit-type-wise-report`, { params: param });
    }
    getChartTableDataForReportSubmitted(param) {
        console.log(param);

        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/report-list-by-unit-type-table`, { params: param });
    }
    getObservationTypeWiseObservationAmountChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-type-wise-amount`, { params: param });
    }
    getYearWiseObservationTypeWiseObservationAmount(param):Observable<any> {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-type-wise-amount-by-year`, { params: param });
    }
    // getChartTableDataForYearWiseObservationTypeWiseObservationAmount(param) {
    //     return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-type-wise-amount-by-year-table`, { params: param });
    // }
    getAuditStatusWiseChart(auditUnitTypeId) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/audit-status-by-unit-type/${auditUnitTypeId}`);
    }
    getAuditStatusAuditYearWise(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/year-wise-audit-status`, { params: param });
    }
    getAuditPartiesYearwise(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/year-wise-audit-party-count`, { params: param });
    }
    getConductionInvestigationByVigilanceTeam(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/year-wise-conducted-flying-squad`, { params: param });
    }
    getTotalOutstandingParasAuditUnits(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/year-wise-pending-paras`, { params: param });
    }
    getObservationAmountOfOutstandingParas(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-amount-of-pending-paras`, { params: param });
    }
    getRecoveredAmountFromCompliedParas(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/year-wise-recovery-amount`, { params: param });
    }
    getPendingParas() {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/admin-office-wise-pending-paras`);
    }
    getObservationAmoutofOutstandingPara() {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/audit-year-observation-amount-admin-office-wise`);
    }
    getObservationTypeWiseObservationamount() {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-type-wise-amount`);
    }
    getYearWiseObservationAmountAndType(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-type-wise-amount-by-year`, { params: param });
    }
    getChartTableDataForYearWiseObservationAmountTypeTable(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-type-wise-amount-by-year-table`, { params: param });
    }
    getAuditStatusRowiseChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/ro-wise-audit-status`, { params: param });
    }
    getDistrictWiseAuditStatusChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/district-wise-audit-status`, { params: param });
    }
    getAuditPartyRowiseChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/region-wise-audit-party-count`, { params: param });
    }
    getDistrictWiseFlyingSquadDetailsChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/region-wise-conducted-flying-squad`, { params: param });
    }
    getTotalOutstandingParaWiseChartChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/administrative-office-wise-pending-paras`, { params: param });
    }
    getTotalOutstandingParaUnitTypeWiseChartChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/unit-type-wise-pending-paras`, { params: param });
    }
    getChartTableDataForTotalOutstandingParaUnitTypeWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/unit-type-wise-pending-paras-table`, { params: param });
    }
    getObservationAmountOfPendingParasAdministrationDeptWise(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-amount-of-pending-paras-admin-office-wise`, { params: param });
    }
    getObservationAmountOfPendingParasUnitTypeWise(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-amount-of-pending-paras-unit-type-wise`, { params: param });
    }
    getChartTableDataForObservationAmountOfPendingParasUnitTypeWise(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/observation-amount-of-pending-paras-table`, { params: param });
    }
    getAuditPartyDetailsRowiseChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/audit-party-wise-members-count`, { params: param });
    }
    getOutstandingNumberOfParasAdministrativeDepartmentWise(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/admin-office-wise-recovery-amount`, { params: param });
    }
    getRecoveryAmountOfCompliedParasUnitTypeWiseOfSelectedAdministrationDept(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/unit-type-wise-recovery-amount`, { params: param });
    }
    getUnitTypeWisePendingParasChart(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/unit-type-wise-pending-paras-count`, { params: param });
    }
    getObservationAmountOfPendingParasUnitTypeWiseOfSelectedAdministrationDept(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/audit-year-observation-amount-unit-type-wise`, { params: param });
    }
    getFlyingParty(param) {
        return this.http.get(`${environment.apiUrl}/flying-squad-party/list`, { params: param });
    }
    deleteFLyingParty(id) {
        return this.http.delete(`${environment.apiUrl}/flying-squad-party/${id}`);
    }
    approveFlyingParty(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-party/approve`, param);
    }
    getMemberListForFlyingParty(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-party/available-member-list?officeId=` + id);
    }
    getOfficeListForFlyingParty() {
        return this.http.get(`${environment.apiUrl}/flying-squad-party/office-list`);
    }
    updateFlyingPartyData(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-party/edit`, param);
    }
    insertFlyingPartyData(param) {
        return this.http.post(`${environment.apiUrl}/flying-squad-party/create`, param);
    }
    getFlyingPartyDataById(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-party/${id}`);
    }
    getAuditPartyForCertificateCaiendar(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-plan/audit-party`, { params: param });
    }
    getAuditUnitForCertificateCalendar(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-plan/audit-unit`, { params: param });
    }
    getCalendarDataForAuditCertificate(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-plan/calendar`, { params: param });
    }
    getMapDataForAuditCertification(id) {
        return this.http.get(`${environment.apiUrl}/certification-audit-plan/route-map/${id}`);
    }
    communicationPartForAuditCertificate(param, id) {
        return this.http.get(`${environment.apiUrl}/certification-audit-communication/unit-party-list/${id}`, { params: param });
    }
    downloadPdfForAuditCertificate(id) {
        return this.http.get(`${environment.apiUrl}/certification-audit-communication/pdf-download/${id}`, { responseType: 'blob' });
    }
    getFlyingSquadAuditType() {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/audit-type`);
    }
    getFlyingSquadRegion() {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/region`);
    }
    getFlyingSquad(param) {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/flying-squad-party`, { params: param });
    }
    getFlyingSquadAuditParty(param) {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/audit-party`, { params: param });
    }
    insertFlyingSquadPlan(param) {
        return this.http.post(`${environment.apiUrl}/flying-squad-plan/create-plan`, param);
    }
    updateFlyingSquadPlan(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-plan/edit-plan`, param);
    }
    getFlyingSquadPlan(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/findById/${id}`);
    }
    getFlyingSquadPlanData(param) {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/plan-list`, { params: param });
    }
    deleteFLyingPlan(id) {
        return this.http.delete(`${environment.apiUrl}/flying-squad-plan/delete/${id}`);
    }
    approveFlyingPlan(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-plan/approveOrReject`, param);
    }
    flyingSquadPlanSendForApprove(id) {
        return this.http.put(`${environment.apiUrl}/flying-squad-plan/send-for-approval/${id}`, {});
    }

    getRiskBasedAuditPlans(param) {
        return this.http.get(`${environment.apiUrl}/financial-audit-planning/financial-plan-list`, { params: param });
    }
    getRiskBasedMapData(id) {
        return this.http.get(`${environment.apiUrl}/financial-audit-planning/route-map/${id}`);
    }
    getRiskBasedCalendarData(param) {
        return this.http.get(`${environment.apiUrl}/financial-audit-planning/calendar`, { params: param });
    }
    riskBasedCommunicationPart(param, id) {
        return this.http.get(`${environment.apiUrl}/financial-audit-communication/unit-party-list/${id}`, { params: param });
    }
    getRiskBasedAuditParty(param) {
        return this.http.get(`${environment.apiUrl}/financial-audit-planning/audit-party`, { params: param });
    }
    getRiskBasedAuditUnit(param) {
        return this.http.get(`${environment.apiUrl}/financial-audit-planning/audit-unit`, { params: param });
    }
    downloadPdfRiskBasedCommunication(id) {
        return this.http.get(`${environment.apiUrl}/financial-audit-communication/pdf-download/${id}`, { responseType: 'blob' });
    }
    sentMailRiskBasedCommunication(param) {
        return this.http.post(`${environment.apiUrl}/financial-audit-communication/send-mail`, param);
    }
    getRiskBasedRegion() {
        return this.http.get(`${environment.apiUrl}/financialplan-add/region-list`);
    }
    getRiskBasedAuditUnitType(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-add/unit-type-list`, { params: param });
    }
    getTourNoteRigionList(){
        return this.http.get(`${environment.apiUrl}/tourNote/region-list`);
    }
    getTourNoteUnitTypeList(param){
        return this.http.get(`${environment.apiUrl}/tourNote/unit-type-list`, { params: param });
    }
    getAllTourNoteAuditUnit(param){
        return this.http.get(`${environment.apiUrl}/tourNote/unitList`, { params: param });
    }
    getPartyListForRiskBasedAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-add/parties`, { params: param });
    }
    savePartyListForRiskBasedAuditPlanning(idList, param) {
        return this.http.post(`${environment.apiUrl}/financialplan-add/parties`, idList, { params: param });
    }
    getRiskParam(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/riskparams`, { params: param });
    }
    getUnitListForRiskBasedAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/units`, { params: param });
    }
    getPartyListForRiskBasedAuditPlanningForEdit(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/parties`, { params: param });
    }
    savePartyListForRiskBasedAuditPlanningForEdit(idList, param) {
        return this.http.put(`${environment.apiUrl}/financialplan-edit/parties`, idList, { params: param });
    }

    saveRiskParamForRiskBased(param) {
        return this.http.put(`${environment.apiUrl}/financialplan-risk-assess/save-param`, param);
    }
    downloadExcelRiskBasedParam(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-risk-assess/download-excel`, { params: param, responseType: 'blob' });
    }
    uploadExcelRiskBasedParam(param) {
        return this.http.put(`${environment.apiUrl}/financialplan-risk-assess/upload-excel`, param);
    }
    getYearListForRiskBased(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/pendingYears`, { params: param });
    }
    saveUnitListForRiskBasedAuditPlanning(param) {
        return this.http.post(`${environment.apiUrl}/financialplan-edit/units`, param);
    }
    savePendingYearUnitListForRiskBasedAuditPlanning(param) {
        return this.http.post(`${environment.apiUrl}/financialplan-edit/unitss`, param);
    }

    getGPUnitListForRiskBasedAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/gp-units`, { params: param });
    }
    saveGPUnitListForRiskBasedAuditPlanning(param) {
        return this.http.post(`${environment.apiUrl}/financialplan-edit/gp-units`, param);
    }
    saveGPUnitListForRiskBasedAuditPlannings(param) {
        return this.http.post(`${environment.apiUrl}/financialplan-edit/gp-unitss`, param);
    }
    uploadExcelRiskBasedGPParam(param) {
        return this.http.put(`${environment.apiUrl}/financialplan-risk-assess/upload-excel-for-gp`, param);
    }
    downloadExcelGpRiskBasedParam(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-risk-assess/download-excel-for-gp`, { params: param, responseType: 'blob' });
    }

    downloadauditCertificate(id) {
        return this.http.get(`${environment.apiUrl}/audit-certificate/pdf-download/${id}`, { responseType: 'blob' });
    }
    sendForApproveRiskBased(id) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/send-for-approval/${id}`, { params: '' });
    }
    sendForCancelRiskBased(id,reason) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/send-for-cancellation/${id}?reason=`+ reason, { params: '' });
    }
    sendForApproveCancellationRiskBased(param) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/cancel-approve-reject`, param);
    }
    sendForRejectCancellationRiskBased(param) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/cancel-approve-reject`, param);
    }
    riskBasedAuditPlanApproveReject(param) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/approve-reject`, param);
    }
    approveCancellationAuditPlan(param) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/cancel-approve-reject`, param);
    }
    rejectCancellationAuditPlan(param) {
        return this.http.put(`${environment.apiUrl}/financial-audit-planning/cancel-approve-reject`, param);
    }
    deleteRiskBasedAuditPlan(id, reason) {
        return this.http.delete(`${environment.apiUrl}/financial-audit-planning/delete-plan/${id}?reason=` + reason);
    }
    getRiskBasedAuditPartiesMappedList(param) {
        return this.http.get(`${environment.apiUrl}/financial-audit-parties-mapped/list`, { params: param });
    }
    gePartyListForRiskBasedAuditPlanning(param) {
        return this.http.get(`${environment.apiUrl}/financial-audit-parties-mapped/party-list`, { params: param });
    }
    savePartiesForRiskBased(param) {
        return this.http.put(`${environment.apiUrl}/financial-audit-parties-mapped/update-party-audit-date`, param);
    }
    requestUnitInchargeForRiskBased(param) {
        return this.http.put(`${environment.apiUrl}/financialplan-edit/request-unit-incharge`, param);
    }
    savePartiesForAuditCertification(param) {
        return this.http.put(`${environment.apiUrl}/certification-audit-parties-mapped/update-party-audit-date`, param);
    }
    getAuditPartyForFlyingExecution() {
        return this.http.get(`${environment.apiUrl}/flying-squad-execution/audit-party-list`);
    }

    getFlyingSquadExecution(param) {
        return this.http.get(`${environment.apiUrl}/flying-squad-execution/list`, { params: param });
    }
    getFlyingSquadMember() {
        return this.http.get(`${environment.apiUrl}/flying-squad-execution/flying-squad-member-list`);
    }

    getFlyingExecutionQuestion() {
        return this.http.get(`${environment.apiUrl}/flying-squad-execution/all-question`);
    }
    addExecutionFlyingSquad(param) {
        return this.http.post(`${environment.apiUrl}/flying-squad-execution/create`, param);
    }
    editExecutionFlyingSquad(param) {
        return this.http.put(`${environment.apiUrl}/flying-squad-execution/edit`, param);
    }
    deleteFlyingSquadExecution(id) {
        return this.http.delete(`${environment.apiUrl}/flying-squad-execution/${id}`);
    }
    flyingSquadExecutionSignOff(id) {
        return this.http.put(`${environment.apiUrl}/flying-squad-execution/sign-off-report/${id}`, { params: '' });
    }
    flyingSquadExecutionEditList(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-execution/list/${id}`);
    }

    auditCertificateSignOff(id) {
        return this.http.put(`${environment.apiUrl}/audit-certificate/sign-off/${id}`, { params: '' });
    }
    flyingSquadExecutionView(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-execution/flying-squad-report-list/${id}`);
    }
    getFlyingSquadPlanForView(id) {
        return this.http.get(`${environment.apiUrl}/flying-squad-plan/viewById/${id}`);
    }
    getPendingAuditYearForReport(id) {
        return this.http.get(`${environment.apiUrl}/auditreport/pending-year-list/${id}`);
    }
    getDistrictListForCertification(param) {
        return this.http.get(`${environment.apiUrl}/certification-audit-planning/district-filter-list`, { params: param });
    }
    getDistrictListForRiskBased(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/district-filter-list`, { params: param });
    }


    /**
     * Date:        07-12-2020
     * Author:      Pinaki Majumder
     */
    getAllClassificationOfKeyAudit(param) {
        return this.http.get(`${environment.apiUrl}/classification-of-key-audit-area/entity-list`, { params: param });
    }

    getClassificationKeyAuditDetails(id) {
        return this.http.get(`${environment.apiUrl}/classification-of-key-audit-area/key-audit-area-list?entityId=` + id);
    }

    getClassificationKeyAuditQuestion(id) {
        return this.http.get(`${environment.apiUrl}/classification-of-key-audit-area/question-list?auditAreaId=` + id);
    }

    getClassificationKeyAuditOptions() {
        return this.http.get(`${environment.apiUrl}/classification-of-key-audit-area/options-list`);
    }

    saveClassificationKeyAuditAnswers(param, entityId, auditAreaId) {
        return this.http.post(`${environment.apiUrl}/classification-of-key-audit-area/save-answers?entityId=${entityId}&auditAreaId=${auditAreaId}`, param);
    }

    getClassificationKeyAuditAnswers(entityId, auditAreaId) {
        return this.http.get(`${environment.apiUrl}/classification-of-key-audit-area/saved-answers-list?entityId=${entityId}&auditAreaId=${auditAreaId}`);
    }

    getSelectionOfTransactionsData(param) {
        return this.http.get(`${environment.apiUrl}/selection-of-transaction/entity-list`, { params: param });
    }

    getSelectionOfTransactionsDetails(id) {
        return this.http.get(`${environment.apiUrl}/selection-of-transaction/audit-area-list?entityId=` + id);
    }
    getAuditReportOpinionList(id) {
        return this.http.get(`${environment.apiUrl}/auditreport/opinion-list/${id}`);
    }

    getAuditReportOpinionText(id, listId) {
        return this.http.get(`${environment.apiUrl}/auditreport/opinion-content?reportId=${id}&opinionId=${listId}`);
    }

    getAuditYearListForOption(id) {
      console.log("Serv id ",id);
        return this.http.get(`${environment.apiUrl}/auditreport/pending-year-list-for-opinion/${id}`);
    }

    getAuditPlanRiskParamOfTheAuditingYear(auditUnitType,reportId) {
        return this.http.get(`${environment.apiUrl}/planrisk/getAuditPlanRiskParamOfTheAuditingYear?auditUnitType=${auditUnitType}&reportId=${reportId}`);
    }

    getUserManagementFilters() {
        return this.http.get(`${environment.apiUrl}/user/get-filters`);
    }

    getAuditUnitFilters() {
        return this.http.get(`${environment.apiUrl}/auditunit/get-filters`);
    }

    getParamApiForEditGP(param) {
        return this.http.get(`${environment.apiUrl}/financialplan-edit/gp-riskparams?planId=${param}`);
    }

    getSelectedTransactionData(entityId, auditAreaId) {
        return this.http.get(`${environment.apiUrl}/selection-of-transaction/entity-audit-area-details?entityId=${entityId}&auditAreaId=${auditAreaId}`);
    }

    getCheckboxData(entityId, auditAreaId) {
        return this.http.get(`${environment.apiUrl}/selection-of-transaction/rule-list?entityId=${entityId}&auditAreaId=${auditAreaId}`);
    }

    saveSelectedTransactionCheckbox(param) {
        return this.http.post(`${environment.apiUrl}/selection-of-transaction/select-rule`, param);
    }

    getTransactionList(param) {
        return this.http.get(`${environment.apiUrl}/selection-of-transaction/transaction-list`, { params: param });
    }

    saveTransactionListTable(param) {
        return this.http.put(`${environment.apiUrl}/selection-of-transaction/select-individual-transactions`, param);
    }

    getMRLEntity(param) {
        return this.http.get(`${environment.apiUrl}/mrl/entity-list`, { params: param });
    }

    getParentListDropdown(id, offId) {
        return this.http.get(`${environment.apiUrl}/auditunit/parent-unit-filter?unitTypeId=${id}&regionalOfficeId=${offId}`);
    }

    getMemorandumEntityList(param: any): any {
        return this.http.get(`${environment.apiUrl}/planning-memorandum/entity-list`, { params: param });
    }

    getMemorandumDetailsList(id: number): any {
        return this.http.get(`${environment.apiUrl}/planning-memorandum/detail?entityId=${id}`);
    }

    getManagementParamKeyValue(id: number) {
        return this.http.get(`${environment.apiUrl}/mrl/get-params?entityId=${id}`);
    }

    saveManagementparams(id: number, params) {
        return this.http.post(`${environment.apiUrl}/mrl/save-params?entityId=${id}`, params);
    }

    sendManagementLetterMail(id: number) {
        return this.http.post(`${environment.apiUrl}/mrl/send-mail?entityId=${id}`, {});
    }

    downloadManagementLetterPDF(id: number) {
        return this.http.get(`${environment.apiUrl}/mrl/download-pdf?entityId=${id}`, { responseType: 'blob' });
    }

    downloadPlanningMemorandumExcel(lang: string, id: number): any {
        return this.http.get(`${environment.apiUrl}/planning-memorandum/download-memorandum-excel/${lang}?entityId=${id}`, { responseType: 'blob' });
    }

    downloadPlanningMemorandumPDF(lang: string, id: number): any {
        return this.http.get(`${environment.apiUrl}/planning-memorandum/download-memorandum-pdf/${lang}?entityId=${id}`, { responseType: 'blob' });
    }

    getTransactionSummaryDataList(param: any): any {
        return this.http.get(`${environment.apiUrl}/entity-transaction/transaction-summary-entity-list`, { params: param });
    }

    getInformationSheetEntityList(): any {
        return this.http.get(`${environment.apiUrl}/information-sheet/entity-list`);
    }

    getInformationSheetTemplate(id: string): any {
        return this.http.get(`${environment.apiUrl}/information-sheet/download-template?entityId=${id}`, { responseType: 'blob' });
    }

    downloadInformationSheet(id: string): any {
        return this.http.get(`${environment.apiUrl}/information-sheet/download/${id}`, { responseType: 'blob' });
    }

    uploadInformationSheet(params) {
        return this.http.post(`${environment.apiUrl}/information-sheet/upload-excel`, params);
    }

    getEntityForkeyAuditArea(entityId: number, auditAreaId: any): any {
        return this.http.get(`${environment.apiUrl}/classification-of-key-audit-area/entity-details?entityId=${entityId}&auditAreaId=${auditAreaId}`);
    }

    getEntityForSelectionTransaction(id: number): any {
        return this.http.get(`${environment.apiUrl}/selection-of-transaction/entity-details?entityId=${id}`);
    }

    getEntityForMateriality(id: number): any {
        return this.http.get(`${environment.apiUrl}/materiality/entity-details?entityId=${id}`);
    }

    getEntityInformationFile(id: number): any {
        return this.http.get(`${environment.apiUrl}/entityManagement/download/${id}`, { responseType: 'blob' });
    }

    downloadAuditReportPDF(lang: string, id: number): any {
        if (lang === 'en') {
            return this.http.get(`${environment.apiUrl}/auditreport/pdf-download/${id}`, { responseType: 'blob' });
        } else {
            return this.http.get(`${environment.apiUrl}/auditreport/pdf-download-hindi/${id}`, { responseType: 'blob' });
        }
    }

    downloadAuditReport(name: string): any {
        return this.http.get(`${environment.apiUrl}/file/download/${name}`, { responseType: 'blob' });
    }
    getChartTableDataForRiskAnalysisUnits(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/risk-analysis-of-units/${param.risk}/${param.yearId}/${param.dataType}`);
    }
    getChartTableDataForobservationTypeWiseYearWiseChart(param) {
        return this.http.get(`${environment.apiUrl}/roho-dashboard/observation-type-wise-amount-drilldown/${param.yearId}/${param.observationTypeId}`);
    }
    getChartTableDataForAllocatedUnitChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/audit_status_graph/${param.unitTypeId}/${param.yearId}/${param.dataType}`);
    }
    getChartTableDataForUnitAuditedTillPresentChart(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/unitAuditedTillPresentgraph/${param.unitTypeId}/${param.yearId}`);
    }
    getObservationAmountOutstandingParaUnitTypeWiseTable(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-amount-of-pending-paras-unit-type-wise-record`,{ params: param });
    }
    getPartyInchargeObservationTypeWiseObservationAmountTable(param) {
        return this.http.get(`${environment.apiUrl}/audit-party-dashboard/observation-type-wise-amount-drilldown`,{ params: param });
    }
    getPartyROwiseDrilldownTable(param) {
        return this.http.get(`${environment.apiUrl}/finance-dashboard/region-wise-audit-party-count-drilldown`,{ params: param });
    }
    /**
     * Author : Sudhanshu Shrivastava
     * Date   : 26-09-2022
     * Year Module
     */
  getYearList1(param){
      return this.http.get(`${environment.apiUrl}/year/list-till-current-execution`, { params: param });
  }
  deleteYear(id) {
      return this.http.delete(`${environment.apiUrl}/year/delete/${id}`);
  }
  insertYearData(yearData) {
      return this.http.post(`${environment.apiUrl}/year/save`, yearData);
  }
  updateYearData(yearData) {
      return this.http.put(`${environment.apiUrl}/year/update/${yearData.id}`, yearData);
  }
  getYearById(id) {
      return this.http.get(`${environment.apiUrl}/year/list-till-current-execution/${id}`);
  }
  /**
     * Author : Sudhanshu Shrivastava
     * Date   : 13-10-2022
     * Holiday Module
     */
getHolidayList1(param) {
    return this.http.get(`${environment.apiUrl}/holiday/get-holiday-list`, { params: param });
}
deleteHoliday(id) {
    return this.http.delete(`${environment.apiUrl}/holiday/delete/${id}`);
}
insertHolidayData(holidayData) {
    return this.http.post(`${environment.apiUrl}/holiday/save`, holidayData);
}
updateHolidayData(holidayData) {
    return this.http.put(`${environment.apiUrl}/holiday/update/${holidayData.id}`, holidayData);
}
getHolidayById(id) {
    return this.http.get(`${environment.apiUrl}/holiday/get-holiday-list/${id}`);
}
 fetchData(query: string): any {
     return this.http.get(`${environment.apiUrl}/execute-query?query=${query}`);

 }
 getPendingParaAndReportsNew() {
    return this.http.get(`${environment.apiUrl}/pending-paras-and-report/pendingParaAndReport`);
 }

}
