import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private auth: AuthenticationService,
        private cookieService: CookieService
    ) {
        if (localStorage.getItem('language')) {
            this.translateService.setDefaultLang(localStorage.getItem('language'));
        } else {
            this.translateService.setDefaultLang('en');
        }

        var sessionStorage_transfer = function (event) {
            if (!event) {
                event = window.event;
            }
            // Ignore if no value to work with
            if (!event.newValue) return;
            if (event.key == 'getSessionStorage') {
                // Another tab asked for the sessionStorage -> send it
                localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
                // The other tab should now have it, so we're done with it.
                localStorage.removeItem('sessionStorage'); 
            } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
                // Another tab sent data -> get it
                var data = JSON.parse(event.newValue);
                for (var key in data) {
                    sessionStorage.setItem(key, data[key]);
                }
            }
        };
        // Listen for changes to localStorage
        if (window.addEventListener) {
            window.addEventListener("storage", sessionStorage_transfer, false);
        } else {
            // Use a type assertion here to inform TypeScript that attachEvent exists
            (window as any).attachEvent("onstorage", sessionStorage_transfer);
        }
        // Ask other tabs for session storage (this is ONLY to trigger event)
        if (!sessionStorage.length) {
            localStorage.setItem('getSessionStorage', 'token');
            localStorage.removeItem('getSessionStorage');
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];
        console.log(file);
        // Here you can do whatever you want with the selected file
        // For example, you could upload it to a server using a service
        if (file) {
            // Instantiate a new FileReader object
            const reader = new FileReader();

            // Define a callback function to handle the file reading operation
            reader.onload = (e: any) => {
                // e.target.result contains the data in the file
                console.log('File content:', e.target.result);
                // Here you can process the file content, e.g., send it to a server
            };

            // Start reading the file as text
            reader.readAsText(file);
        }
    }
}
