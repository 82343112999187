import { Component, OnInit ,OnDestroy} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../_services/common.service';
import { AuditService } from '../../_services/audit.service';
import 'rxjs/add/observable/interval';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {mergeMap, map, filter} from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
declare var jQuery:any

@Component({
	selector: 'app-dashboardtop',
	templateUrl: './dasboardtop.component.html',
	styleUrls: ['./dasboardtop.component.css']
})
export class DasboardtopComponent implements OnInit {
	accessToken = sessionStorage.getItem('access_token');
	userName:string;
	msg;
	language;
	notSeenCount;
	notificatonData :any =[];
	myIntervalSub;
  paraParam: any = {};

	msgSuccess:boolean = false;
    msgError:boolean = false;
    yearId:number = 0;
    unitId;
	selectedUnitId;
	showYear:boolean = false
    formSubmit:boolean = false;
    unitData:any = [];
    AdditionalunitData:any=[];
	YearData:any = [];
	entityChangeForm;
	pageInfo;
	ispartyRelated = localStorage.getItem('ispartyRelated');
	partyRelated = localStorage.getItem('partyRelated');
	public userDetails : any ;
	public ssoUrl : any ;
	public loginUrl : any ;
	isUnitIncharge:boolean;

	constructor(
		private router:Router,
		private auth:AuthenticationService,
		private translateService: TranslateService,
		private common : CommonService,
		private notifyData : AuditService,
		private http: HttpClient,
		private activatedRoute: ActivatedRoute,
		private titleService:Title,
		private cookieService:CookieService
	) {}

	ngOnInit(){
		this.getAdditionalUnitList();
		this.common.currLang.subscribe(
			res=>{
				this.language = res;
			}
		)
		if(this.language =='en'){
			this.userName = localStorage.getItem('userName');
		}else if(this.language =='hi'){
		    this.userName = localStorage.getItem('userNameHi');
		}else{
			this.userName = localStorage.getItem('userName');
		}
		console.log(this.userName);

   		// let logintype=this.userName.substring(this.userName.length-21,31);
   		let logintype=this.userName.match(/\(([^)]+)\)/)[1]; //matches and returns the string inside ()
    		console.log(logintype);
    		if(logintype=='Audit Unit In-charge'){
		 		this.isUnitIncharge=true;
		 	}else{
		 		this.isUnitIncharge=false;
		 	}
       console.log(this.isUnitIncharge);
		if(localStorage.getItem('userName') != null){

			this.myIntervalSub = Observable.interval(1000000).subscribe(
				data => {
					this.notification()
				}
			);
			this.notification()
		}
		this.entityChangeForm = new FormGroup({
			unitName: new FormControl(''),
			//year: new FormControl('',[Validators.required]),
		});

		this.ssoUrl = localStorage.getItem('backToSSOUrl')+'';
		this.loginUrl= localStorage.getItem('signOutUrl')+'';
		this.userDetails = localStorage.getItem('userdetails')+'' ;

	}

	 getSelectedUnitValue(){

    this.paraParam.page =0;
    this.paraParam.limit = 10;
    this.paraParam.status = true;
    this.paraParam.unitId = parseInt(this.selectedUnitId);
    this.notifyData.getParaComplyList(this.paraParam).subscribe(
      data=>{
  console.log("Check the data received after change of unit ---------->>> ", data);

      }
  );
	 	console.log("Selected unit id is --------->> ", this.selectedUnitId);
	 	localStorage.setItem('SelectedUnitId',this.selectedUnitId);
	 }
	/*logout(){
		this.commonCode();

		this.auth.logout().subscribe(
			res=>{
        localStorage.setItem("auditYear","0");
        localStorage.setItem("SelectedUnitId","0");
				var loginForm = <HTMLFormElement>document.getElementById('login_form');
				loginForm.submit();
				// window.location.href = ''+localStorage.getItem('signOutUrl')+'';
				//this.router.navigate(['/login']);
			},
			error=>{
				console.log(error)
			}
		);
	} */

	logout(){
		this.commonCode();

		this.auth.logout().subscribe(
			res=>{
        localStorage.setItem("auditYear","0");
        localStorage.setItem("SelectedUnitId","0");
				//var loginForm = <HTMLFormElement>document.getElementById('login_form');
				//loginForm.submit();
				// window.location.href = ''+localStorage.getItem('signOutUrl')+'';
        localStorage.clear();
				this.router.navigate(['login']);
			},
			error=>{
				console.log(error)
			}
		);
	}

	backToSso(){
		this.commonCode();

		this.auth.logout().subscribe(
			res=>{
				var ssoForm = <HTMLFormElement>document.getElementById('sso_form');
				ssoForm.submit();
				// window.location.href = ''+localStorage.getItem('backToSSOUrl')+'';
			},
			error=>{
				console.log(error)
			}
		);
	}


	commonCode(){
		localStorage.removeItem('language');
		localStorage.removeItem('isIndex');
		localStorage.removeItem('isAuditCalendar');
		localStorage.removeItem('isDocumentCatalogue');
		localStorage.removeItem('isMyGrievances');
		sessionStorage.removeItem('access_token');
		localStorage.removeItem('menuStatus');
		localStorage.removeItem('defaultMenuLink');
		localStorage.removeItem('url');
		localStorage.removeItem('menu');
		localStorage.removeItem('permission');
		localStorage.removeItem('userName');
		localStorage.removeItem('userNameHi');
		localStorage.removeItem('ispartyRelated');
		localStorage.removeItem('partyRelated');
		localStorage.removeItem('viewAll');
		localStorage.removeItem('pageUrl');
		localStorage.removeItem('refreshForSelection');
		localStorage.removeItem('userdetails');

		this.common.currLang.next( this.language);
	}

	switchLanguage(language: string) {
		localStorage.setItem('language',language)
		this.common.currLang.next(language);
		this.language = language;
		this.translateService.use(this.language);
		if(this.language =='en'){
			this.userName = localStorage.getItem('userName');
		}else if(this.language =='hi'){
		    this.userName = localStorage.getItem('userNameHi');
		}else{
			this.userName = localStorage.getItem('userName');
		}
	}

	notification(){
		//alert();
		this.notifyData.getInboxNotification().subscribe(
			data=>{
				//console.log(data);
        if(data != null){
          this.notificatonData = data['entries'];
          this.notSeenCount = data['notSeenCount'];
        }
			}
		)
	}

	onChangeUnit(event){
        //console.log(event)
        if(event == 'viewAll'){
            this.unitId = event
        }else{
            this.unitId = event
        }
    }

    getUnitIds(units){
      let ele = '';
      for (let index = 0; index < units.length; index++) {
        const element = units[index];
        ele = ele + element.id+ (index!=units.length-1?",":"");
      }
      return ele ;
    }
    getAdditionalUnitList(){

      this.notifyData.getAdditionalUnitData().subscribe(
			res=>{
				this.AdditionalunitData = res;

        if(this.AdditionalunitData && this.AdditionalunitData.length>0 && !this.selectedUnitId){
          let unitIds = this.getUnitIds(this.AdditionalunitData);
          if(unitIds!=''){
            localStorage.setItem('SelectedUnitId',unitIds);
            this.notifyData.eventEmitter.emit(unitIds);
          }
        }
				console.log(this.AdditionalunitData);

			}
         )
      }

    getUnitList(){
      this.notifyData.getUnitData().subscribe(
      res=>{
          this.unitData = res
       }
      )
    }

    // getYearList(){
    //     this.notifyData.getYearData().subscribe(
    //         res=>{
    //             this.YearData = res['entries']
    //         }
    //     )
    // }

	onYearChange(event){
        this.yearId = event;
    }

	changeEntity(){
		jQuery("#Mymodal").modal("show");
		this.getUnitList();
		//this.getYearList();
		this.notifyData.getEntity().subscribe(
			res=>{
				//console.log(res);
				this.onChangeUnit(res['planMapId']);
				this.unitId =  res['planMapId'];
				if(res['planMapId'] == null && res['yearId'] == null ){
					this.entityChangeForm.setValue({
						unitName: 'viewAll',
					})
				}else{
					this.entityChangeForm.setValue({
						unitName: res['planMapId'],
					})
				}
			}
		)
	}

	onSubmit(formValue){

		this.formSubmit = true
		this.unitId = this.unitName.value
        if(this.unitId == 'viewAll'){
			var unitData = '';
			localStorage.setItem('viewAll','true')
        }else{
			unitData = this.unitId;
			localStorage.setItem('viewAll','false')
        }
		var url = localStorage.getItem('pageUrl')
		localStorage.setItem('auditPlanUnitPartyMapId', unitData);
			var entityObj={
				planMapId:unitData,
				status:"changeEntity"
			}
			this.notifyData.addEntity(entityObj).subscribe(
				data => {
					this.msg = data['message'];
                    this.msgSuccess = true;
					if(this.unitId == 'viewAll'){
						this.router.navigate(['/memolist']);
					}else{
						this.router.navigate(['/memolistch']);
					}
					setTimeout(() => {
						this.msgSuccess = false;
						jQuery("#Mymodal").modal("hide");
					}, this.common.timeOut);
				},
				error=>{
					this.msg = error['message'];
					this.msgError  = true
					setTimeout(() =>{
						this.msgError  = false;
					}, this.common.timeOut);
				}
			);
		//}
    }

	ngOnDestroy() {
		if (this.myIntervalSub) {
		    this.myIntervalSub.unsubscribe();
		}
	}

	get unitName() {
		return this.entityChangeForm.get('unitName');
	}
	// get year() {
	// 	return this.entityChangeForm.get('year');
	// }
	// backToSso(){

	// 	//document.getElementById("sso_form").submit();
	// 	var myForm = <HTMLFormElement>document.getElementById('sso_form');
	// 	myForm.submit();
	// }

  ChangeUnitSave()
  {
    localStorage.setItem('SelectedUnitId',this.selectedUnitId);
    this.notifyData.eventEmitter.emit(this.selectedUnitId);
    document.getElementById("UnitChangeClose").click();
  }

}
