import { Component, AfterViewInit, OnInit, ViewEncapsulation  } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './leftmenu-items';
import { RouteInfo} from './leftsidebar.metadata';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../_services/common.service';

declare var $: any;

@Component({
  selector: 'ap-leftnav',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeftsidebarComponent implements OnInit, AfterViewInit {
    menu = localStorage.getItem('menu');
    notLogIn:boolean = false
    language;
    menuList;
    showMenu: string = '';
    showSubMenu: string = '';
    public sidebarnavItems: any[];

    showSubmenu: boolean = false;
    //expandedIcon:boolean = false;
    status: string;
    menuId: number = 0;

    
    //this is for the open close

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
            
        } else {
            this.showMenu = element; 
        }
    }
    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';

        } else {
            this.showSubMenu = element; 
        }
    }

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private common: CommonService
    ) { }

    // End open close
    ngOnInit() {
        if(localStorage.getItem('userName') != null) {
            this.notLogIn = true;
        }else {
            this.notLogIn = false;
        }

        var windowWidth = $(window).width();

        if(windowWidth <= 767){
            $("#sidebar").addClass("active");
            $('body').addClass('mini-navbar');
        }

        this.menuList = JSON.parse(this.menu);
        for(var i=0; i<this.menuList.length; i++) {
            this.menuList[i]['expandedIcon'] = false;
        }

        this.common.currLang.subscribe(
            res=>{
                this.language = res;
            }
        );

        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
        // $(function () {
        //     $(".sidebartoggler").on('click', function() {
        //         if ($("#main-wrapper").hasClass("mini-sidebar")) {
        //             $("body").trigger("resize");
        //             $("#main-wrapper").removeClass("mini-sidebar");

        //         } else {
        //             $("body").trigger("resize");
        //             $("#main-wrapper").addClass("mini-sidebar");
        //         }
        //     });

        // });

        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').toggleClass('active');
        });
        // Collapse ibox function
        $('#sidebar ul li').on('click', function () {
            var button = $(this).find('i.fa.indicator-mn');
            button.toggleClass('fa-plus').toggleClass('fa-minus');
        });

        $('#sidebarCollapse').on('click', function () {
            $("body").toggleClass("mini-navbar");
            $('.submenu').toggleClass("expanded");
        });
        $(document).on('click', '.header-right-menu .dropdown-menu', function (e) {
            e.stopPropagation();
        });

    }

    ngAfterViewInit(): void {
        const leftMenuID = this.common.getMenuID();
        if (leftMenuID) {
            setTimeout(() => {
                $('#' + leftMenuID).click();
            }, 1);
        }
    }

    onClick(event, expandedIconDown) {
        this.menuId = event;
        this.checkValue(event, this.menuList);

        if (this.status == 'Exist' && expandedIconDown == false) {
            // this.expandedIcon = true;
            for(var i = 0; i < this.menuList.length; i++) {
                if (this.menuList[i].id == event) {
                    this.menuList[i]['expandedIcon'] = true;
                    $('#'+event).toggleClass("rotated");
                } else {
                    this.menuList[i]['expandedIcon'] = false;
                }
            }
        }else if(this.status == 'Exist' && expandedIconDown == true) {
            $('.submenu').toggleClass("expanded");
            // this.expandedIcon = false
            for(var i = 0; i < this.menuList.length; i++){
                if(this.menuList[i].id == event ) {
                    this.menuList[i]['expandedIcon'] = false;
                    $('#'+event).toggleClass("rotated");
                }
            }
        }
    }

    checkValue(value,arr){
        this.status = 'Not exist';

        for(var i = 0; i < arr.length; i++) {
            var id = arr[i].id;
            if(id == value){
                this.status = 'Exist';
                break;
            }
        }

        return this.status;
    }

}
