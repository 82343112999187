
export class Unit{
id: number;
unitName:string;
unitNameHi:string;
isSelected:boolean;
}


export class Pi{
    id: number;
    name:string;
    nameHi:string;
    isSelected:boolean;
    }