import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from "./navigation.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../_services/common.service';
import { AuthenticationService } from "../../_services/authentication.service";

declare var $: any;
@Component({
  selector: 'ap-nav',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
  
})
export class NavigationComponent implements OnInit {
	
    language = 'en';
    showMenu: string = '';
    showSubMenu: string = '';
    public sidebarnavItems: any[];
    public userDetails: any = '';
	public ssoUrl: any ;
    public facebook: any ;
    public twitter: any ;

    // this is for the open close
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element; 
        }
    }
    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }

    constructor(
        private modalService: NgbModal, 
        private router: Router,
        private route: ActivatedRoute,
        private common:CommonService,
        private translateService: TranslateService,
        private auth : AuthenticationService

    ) {
        translateService.setDefaultLang(this.language);
    }

    switchLanguage(language: string) {
		localStorage.setItem('language', language);
		this.common.currLang.next(language);
		this.language = language;
		this.translateService.use(this.language);
	}
    // End open close
    ngOnInit() {
        this.common.currLang.subscribe(
            res=>{
                this.language = res;
            }
        );
        this.auth.getSocialAndContact().subscribe(
            ( response : any ) =>{
                this.facebook = response.facebookUrl;
                this.twitter = response.twitterUrl;
             }
        );
        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
        $(function () {
            $(".sidebartoggler").on('click', function() {
                if ($("#main-wrapper").hasClass("mini-sidebar")) {
                    $("body").trigger("resize");
                    $("#main-wrapper").removeClass("mini-sidebar");
                     
                } else {
                    $("body").trigger("resize");
                    $("#main-wrapper").addClass("mini-sidebar");
                }
            });

        });

        //this.ssoUrl = localStorage.getItem('backToSSOUrl')+'';
        this.userDetails = localStorage.getItem('userdetails')+'' ;
        this.ssoUrl=''+this.common.ssoUrl+'';

    }
    sso(){
        this.commonCode();
        var ssoForm = <HTMLFormElement>document.getElementById('sso_form');
		ssoForm.submit();
    }

    commonCode(){
		localStorage.removeItem('language');
		localStorage.removeItem('isIndex');
		localStorage.removeItem('isAuditCalendar');
		localStorage.removeItem('isDocumentCatalogue');
		localStorage.removeItem('isMyGrievances');
		sessionStorage.removeItem('access_token');
		localStorage.removeItem('menuStatus');
		localStorage.removeItem('defaultMenuLink');
		localStorage.removeItem('url');
		localStorage.removeItem('menu');
		localStorage.removeItem('permission');
		localStorage.removeItem('userName');
		localStorage.removeItem('userNameHi');
		localStorage.removeItem('ispartyRelated');
		localStorage.removeItem('partyRelated');
		localStorage.removeItem('viewAll');
		localStorage.removeItem('pageUrl');
		localStorage.removeItem('refreshForSelection');
		localStorage.removeItem('userdetails');

		this.common.currLang.next( this.language);
	}

}
