import { Component, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { mergeMap, map, filter } from "rxjs/operators";
import { JsonPipe } from "@angular/common";
import { jsonpCallbackContext } from "@angular/common/http/src/module";
import { CommonService } from "../../_services/common.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxUiLoaderModule, NgxUiLoaderService, SPINNER } from "ngx-ui-loader";
import { AuditService } from "../../_services/audit.service";

@Component({
  selector: "app-commonpanel",
  templateUrl: "./commonpanel.component.html",
  styleUrls: ["./commonpanel.component.css"],
})
export class CommonpanelComponent implements AfterViewInit {
  subtitle: string;
  pageInfo;
  roleId: any = localStorage.getItem("roleId");
  engTitle: string = "";
  hinTitle: string = "";
  language;
  breadCrumbArr = [];
  menuList = JSON.parse(localStorage.getItem("menu"));
  isIndex;
  roId;
  msg;
  regionList: any = [];
  regionId: number = 0;
  successMsg: boolean = false;
  errorMsg: boolean = false;
  certificationForm: FormGroup;
  isAuditCalendar;
  isDocumentCatalogue;
  isMyGrievances;
  constructor(
    private router: Router,
    private data: AuditService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private common: CommonService,
    private ngxService: NgxUiLoaderService
  ) {
    this.isIndex = localStorage.getItem("isIndex");
    this.isAuditCalendar = localStorage.getItem("isAuditCalendar");
    this.isDocumentCatalogue = localStorage.getItem("isDocumentCatalogue");
    this.isMyGrievances = localStorage.getItem("isMyGrievances");
    this.common.currLang.subscribe((res) => {
      this.language = res;
      this.setTitle();
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.titleService.setTitle(event["title"]);
        this.pageInfo = event;
        localStorage.setItem(
          "pageUrl",
          this.pageInfo["urls"][0].url.split("/")[1]
        );
        //var matchUrl = this.pageInfo['urls'][0].url.split("/");
        this.breadCrumbArr["parent"] = [];
        this.breadCrumbArr["child"] = [];
        for (let i in this.menuList) {
          // this.breadCrumbArr['parent'] = [];
          // this.breadCrumbArr['child'] = [];
          if (
            this.pageInfo["urls"][0].url.split("/")[1] == this.menuList[i].link
          ) {
            //this.titleService.setTitle(this.menuList[i].menuName);
            localStorage.setItem(
              "refreshForSelection",
              this.menuList[i].refreshForSelection
            );
            this.engTitle = this.menuList[i].menuName;
            this.hinTitle = this.menuList[i].menuNameHi;
            this.setTitle();

            this.breadCrumbArr["parent"].push({
              title: this.menuList[i].menuName,
              titleHi: this.menuList[i].menuNameHi,
              url: this.menuList[i].link,
            });
          } else if (this.menuList[i].subMenu.length > 0) {
            for (let item of this.menuList[i].subMenu) {
              if (this.pageInfo["urls"][0].url.split("/")[1] == item.link) {
                localStorage.setItem(
                  "refreshForSelection",
                  item.refreshForSelection
                );
                this.engTitle = item.menuName;
                this.hinTitle = item.menuNameHi;
                this.setTitle();
                //this.titleService.setTitle(item.menuName);
                this.breadCrumbArr["parent"].push({
                  title: this.menuList[i].menuName,
                  titleHi: this.menuList[i].menuNameHi,
                  url: this.menuList[i].link,
                });

                this.breadCrumbArr["child"].push({
                  title: item.menuName,
                  titleHi: item.menuNameHi,
                  url: item.link,
                });
              }
            }
          }
        }

        if (this.breadCrumbArr["parent"][0] !== undefined) {
          for (let index = 0; index < this.menuList.length; index++) {
            if (
              this.menuList[index].menuName ===
              this.breadCrumbArr["parent"][0].title
            ) {
              if (this.menuList[index].subMenu.length > 0) {
                this.common.setMenuID(this.menuList[index].id);
              }
            }
          }
        }
      });
  }

  ngOnInit() {
    this.certificationForm = new FormGroup({
      auditUnitType: new FormControl("", [Validators.required]),
      auditYear: new FormControl("", [Validators.required]),
      regionId: new FormControl("", [Validators.required]),
    });
    if (this.roleId == 1) {
      this.getRiskBasedRegion();
    }
  }

  popup: boolean = false;
  onChangeRegion(event) {
    this.popup = false;
    this.regionId = event;
    this.router.navigate(["/auditcalendar/" + event]);
    //window.location.href="/#/auditcalendar/"+event;
  }

  popupClose() {
    this.popup = false;
  }

  getRiskBasedRegion() {
    this.data.getRiskBasedRegion().subscribe(
      (res) => {
        this.regionList = res;
      },
      (error) => {
        this.msg = error["message"];
        this.errorMsg = true;
        setTimeout(() => {
          this.errorMsg = false;
        }, this.common.timeOut);
      }
    );
  }

  onSubmit() {
    this.regionId;
    console.log("regionId: ", this.regionId);
    this.router.navigate(["/auditcalendar"]);
  }

  setTitle() {
    if (this.engTitle != "" && this.hinTitle != "") {
      if (this.language == "en") {
        this.titleService.setTitle(this.engTitle);
      } else {
        this.titleService.setTitle(this.hinTitle);
      }
    }
  }
  ngAfterViewInit() {}
}
