import { Injectable, Component, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";
import { AuditService } from "./audit.service";
import { SnackbarComponent } from "../shared/snackbar/snackbar.component";
import {
  MatSort,
  MatTableDataSource,
  MatPaginator,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
} from "@angular/material";
import { environment } from "../../environments/environment";
export interface DialogData {
  data;
}
@Injectable({
  providedIn: "root",
})
export class CommonService {
  menu;
  url = `${environment.apiUrl}`;
  language;
  timeOut = 6000;
  currLang = new BehaviorSubject<string>("en");
  homeClass = new BehaviorSubject<boolean>(false);
  websiteValid = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
  phoneNoValid = "^([+][9][1]|[9][1]|[0]){0,1}([7-9]{1})([0-9]{9})$"; 
  spaceValidator = "[^s]*";
  currencyMatchPattern = /^((\d+(\.\d{1,2})?)|(\.\d{1,2}))$/;
  namePattern = "[a-zA-Z0-9][A-Za-z0-9._-]*";

  // ssoUrl = 'http://localhost:4200/#/login'; //local
    //  ssoUrl = "http://10.70.231.83/AMS/#/starter" //staging 22-11-2024 11:23
  ssoUrl = "https://sso.rajasthan.gov.in/sso"; //production on 28-11-2024 11:12

  statusMenu: any = [];
 
  statusData: any = [];
  selectedItem = "";
  shortCodeParam: any = {};
  statusList;
  menuStatus = [];
  dialogRef: any;
  leftMenuID;

  constructor(
    // private data:AuthenticationService,
    private router: Router,
    private data: AuditService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    if (localStorage.getItem("language")) {
      this.currLang = new BehaviorSubject<string>(
        localStorage.getItem("language")
      );
    } else {
      this.currLang = new BehaviorSubject<string>("en");
    }
  }

  getMenuPermission() {
    this.menu = localStorage.getItem("menu");
    var menuList = JSON.parse(this.menu);
    var mainMenu = [];

    for (let item of menuList) {
      var permission = [];
      var submenus = [];
      for (let per of item.permissions) {
        permission.push(per);
      }

      if (item.subMenu.length > 0) {
        for (let submenu of item.subMenu) {
          var subPermission = [];
          for (let ptype of submenu.permissions) {
            subPermission.push(ptype);
          }
          submenus.push({
            subMenuShortCode: submenu.shortCode,
            subPermission: subPermission,
          });
        }
      }
      mainMenu.push({
        shortCode: item.shortCode,
        permission: permission,
        subMenus: submenus,
      });
    }
    return mainMenu;
  }

  changeDateFormat(string) {
    var d = new Date(string);
    var month = (d.getMonth() + 1).toString();
    if (month.length == 1) {
      month = "0" + month;
    }
    var date = d.getDate().toString();
    if (date.length == 1) {
      date = "0" + date;
    }
    var hour = d.getHours().toString();
    if (hour.length == 1) {
      hour = "0" + date;
    }
    var minute = d.getMinutes().toString();
    if (minute.length == 1) {
      minute = "0" + minute;
    }
    var second = d.getSeconds().toString();
    if (second.length == 1) {
      second = "0" + second;
    }

    var dateStr = date + "-" + month + "-" + d.getFullYear();
    return dateStr;
  }

  getTime(string) {
    var d = new Date(string);

    var hour = d.getHours().toString();
    if (hour.length == 1) {
      hour = "0" + hour;
    }
    var minute = d.getMinutes().toString();
    if (minute.length == 1) {
      minute = "0" + minute;
    }
    var second = d.getSeconds().toString();
    if (second.length == 1) {
      second = "0" + second;
    }

    var timeStr = hour + ":" + minute + ":" + second;
    return timeStr;
  }

  getmenuStatus(statusList, menuShortCode) {
    let menu_arr: any = [];
    for (let item of statusList) {
      if (item.shortCode == undefined) {
        menu_arr.push({
          shortCode: menuShortCode,
          status: item.status,
          title: item.title,
          titleHi: item.titleHi,
        });
      }
    }
    let menuStatusArray: any = [];
    if (localStorage.getItem("menuStatus") != null) {
      menuStatusArray = JSON.parse(localStorage.getItem("menuStatus"));
    }
    menuStatusArray.push(menu_arr);
    localStorage.setItem("menuStatus", JSON.stringify(menuStatusArray));
  }

  openSnackBar(message: string, panelClass: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: 4000,
    });
  }

  openHelp(shortCode) {
    this.currLang.subscribe((res) => {
      this.language = res;
    });
    this.data.getHelp(shortCode).subscribe((res) => {
      this.dialogRef = this.dialog.open(DialogHelpComponent, {
        width: "1000px",
        // data:{domainValue:res['domainValue'],domainValueHi:res['domainValueHi'],systemValue:res['systemValue'],systemValueHi:res['systemValueHi'],language:this.language}
      });
      this.dialogRef.componentInstance.domainValue = res["domainValue"];
      this.dialogRef.componentInstance.domainValueHi = res["domainValueHi"];
      this.dialogRef.componentInstance.systemValue = res["systemValue"];
      this.dialogRef.componentInstance.systemValueHi = res["systemValueHi"];
      this.dialogRef.componentInstance.language = this.language;
      this.dialogRef.afterClosed().subscribe((response) => {});
    });
  }

  setMenuID(leftMenuID: number): void {
    this.leftMenuID = leftMenuID;
  }

  getMenuID(): number {
    return this.leftMenuID;
  }
}

@Component({
  selector: "app-dialog-help",
  templateUrl: "../dashboard/dialog-help/dialog-help.component.html",
})
export class DialogHelpComponent {
  domainValue;
  domainValueHi;
  systemValue;
  systemValueHi;
  language;

  constructor(
    public dialogRef: MatDialogRef<DialogHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onCloseClick() {
    this.dialogRef.close();
  }
}
