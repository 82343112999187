import { Component, AfterViewInit } from '@angular/core';

@Component({

	templateUrl: './aumlist.component.html',
	styleUrls: ['./aumlist.component.css']
})
export class AumlistComponent implements AfterViewInit {
	subtitle:string;	
	constructor() {
		this.subtitle = "This is some text within a card block."
	}

	ngAfterViewInit(){}
}