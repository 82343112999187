import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MaterialModule } from './material.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule, MatDialogModule, MatButtonModule, MatTableModule, MatToolbarModule, MatListModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MAT_SNACK_BAR_DATA } from '@angular/material';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ChartsModule } from 'ng2-charts'
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { SliderComponent } from './slider/slider.component';
import { InnersliderComponent } from './innerslider/innerslider.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftsidebarComponent } from './dashboard/leftsidebar/leftsidebar.component';
import { DasboardtopComponent } from './dashboard/dasboardtop/dasboardtop.component';
import { AumlistComponent } from './dashboard/aumlist/aumlist.component';
import { CommonpanelComponent } from './dashboard/commonpanel/commonpanel.component';
import { TokenInterceptor } from './_helpers/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { DialogHelpComponent } from './_services/common.service';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './component/confirmation-dialog/confirmation-dialog.service';
import { Unit } from './models/unit';
import { EditorModule } from '@tinymce/tinymce-angular';

export function HttpLoaderFactory(HttpClient: HttpClient) {
  return new TranslateHttpLoader(HttpClient, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SliderComponent,
    InnersliderComponent,
    DashboardComponent,
    LeftsidebarComponent,
    DasboardtopComponent,
    CommonpanelComponent,
    AumlistComponent,
    FooterComponent,
    DialogHelpComponent,
    SnackbarComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: false, onSameUrlNavigation: 'reload' }),
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatToolbarModule,
    NgxUiLoaderModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ChartsModule,
    EditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [RouterModule],
  providers: [
    CookieService,
    ConfirmationDialogService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    Unit,
    { provide: MAT_SNACK_BAR_DATA, useValue: {} }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogHelpComponent,
    SnackbarComponent,
    ConfirmationDialogComponent
  ]
})
export class AppModule { }
