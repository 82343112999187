import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './common.service';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RequestOptions,Headers } from '@angular/http';
declare var jQuery:any;


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    language;
    deviceId;

    isLoggedin$ = new BehaviorSubject<any>(this.hasAccessToken());
    constructor(
        private http: HttpClient,
        private  router : Router,
        private common:CommonService,
        private cookieService:CookieService,
       
    ) {}

    hasAccessToken() {
        return !!sessionStorage.getItem('access_token');
    }

    getAccessToken() {
        return sessionStorage.getItem('access_token');
    }

    loginData(username, password): Observable<any> {
        if(localStorage.getItem('deviceId') == null){
            this.deviceId = Math.random();
            localStorage.setItem('deviceId',this.deviceId)
        }else{
            this.deviceId = localStorage.getItem('deviceId')
        }
       
        this.common.currLang.subscribe(
            res=>{
                this.language = res;
            }
        )
        
        // let options = {
        // headers: new HttpHeaders()
        //     .set('Accept-Language', this.language)
        //     .set('Content-Type', 'application/x-www-form-urlencoded')
        //     .set('Authorization', 'Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXRlLWNsaWVudDpzcHJpbmctc2VjdXJpdHktb2F1dGgyLXJlYWQtd3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA==')
        // };

        // const body = new HttpParams()
        // .set('grant_type', 'password')
        // .set('username', username)
        // .set('password', password)
        // .set('client_id', 'spring-security-oauth2-read-write-client')
        // .set('device_id', this.deviceId);
        // return this.http.post(`${environment.apiUrl}/oauth/token`, body.toString(), options);
        
        return this.http.post(`${environment.apiUrl}/login`,
            {
            userName: username, device_id:this.deviceId
            }, {
            withCredentials: true
            }
        );
    }
    

    logout(){
        this.common.currLang.subscribe(
            res=>{
                this.language = res;
            }
        )
        let param={
            param:''
        }
        // var access_token = localStorage.getItem('access_token');
        // let options = {
        // headers: new HttpHeaders()
        //     .set( 'Authorization', `Bearer ${access_token}`)
        // };
        return this.http.post(`${environment.apiUrl}/session-logout`,param);
      
    }

    logoutForError(){
        // var access_token = localStorage.getItem('access_token');
        // let options = {
        // headers: new HttpHeaders()
        //     .set( 'Authorization', `Bearer ${access_token}`)
        // };
        let param={
            param:''
        }
		localStorage.removeItem('language')
        sessionStorage.removeItem('access_token');
        localStorage.removeItem('menu');
        localStorage.removeItem('defaultMenuLink');
        localStorage.removeItem('permission');
        localStorage.removeItem('userName');
        localStorage.removeItem('userNameHi');
        localStorage.removeItem('url');
        localStorage.removeItem('ispartyRelated');
        localStorage.removeItem('pageUrl');
        localStorage.removeItem('refreshForSelection');
        localStorage.removeItem('partyRelated');
        localStorage.removeItem('viewAll');
        localStorage.removeItem('menuStatus');
        this.common.currLang.next( this.language);

        this.http.post(`${environment.apiUrl}/session-logout`,param);
        this.common.currLang.subscribe(
            res=>{
                this.language = res;
            }
        )
        window.location.href = ''+localStorage.getItem('backToSSOUrl')+'';
        //this.router.navigate(['/login']);
    }

    getMenu(){
        return this.http.get(`${environment.apiUrl}/access/get-permited-menus`);
    }

    getSsoVerifyLoginDetails(){
        return this.http.get(`${environment.apiUrl}/verifyLogin`);
    }

    backToSSO(url ,param){
        let formData  = new HttpParams()
        .set('userdetails', param)
        // let formData  = new FormData();
        // formData.append("userdetails", param);

        return this.http.post(url , formData.toString() ,{
            headers: new HttpHeaders()
              .set('Content-Type', 'application/x-www-form-urlencoded')
          });
    }


    getSocialAndContact(){
        return this.http.get(`${environment.apiUrl}/public-home-page-content/login-public-content`);
    }

}


