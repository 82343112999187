import { Component, AfterViewInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from "../../_services/authentication.service";
declare var $: any;
@Component({
  selector: 'ap-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})


export class FooterComponent implements AfterViewInit {
  name: string;
  public date = new Date();
  public contacts : any ;
  public facebook : any ;
  public twitter : any ;

  constructor(private modalService: NgbModal,
    private auth : AuthenticationService
    ) {

  }

  ngOnInit() {
    this.auth.getSocialAndContact().subscribe(
     ( response : any ) =>{
         this.contacts = response.contactUs;
         this.facebook = response.facebookUrl;
         this.twitter = response.twitterUrl ;
         
         
      }
    )

  }
  ngAfterViewInit() {


  }
}



