import { Injectable, HostListener } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Rx';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { CommonService } from '../_services/common.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    language;
    access_token;
    refresh_token;
    constructor(
        private auth: AuthenticationService,
        private router : Router,
        private common : CommonService,
        private cookieService : CookieService
    ) { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        // if (this.auth.hasAccessToken()) {
        //     this.access_token = this.auth.getAccessToken();
        //     //console.log(req.headers.get('Authorization'))
        //     if((request.headers.get('Authorization')) == null){
        //         request = request.clone({
        //             setHeaders: {
        //                 'X-Auth-Token': `Bearer ${this.access_token}`
        //             },
        //         });
        //     }
        // }
        request = request.clone({

            withCredentials :  true,
			//setHeaders: {
            //	'X-Frame-Options': "SAMEORIGIN"
            //},
        });

        return next.handle(request).map((event: HttpEvent<any>) => {
            return event;
        }).catch((err: any, caught) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.auth.logoutForError();
                    
                }else if(err.status === 403){
                    this.router.navigateByUrl('/forbidden');
                } 
                else {
                    //document.getElementsByClassName('page-wrapper')[0].scroll(0, 0);
                    return Observable.throw(err.error);
                }
            }
        });
    }
}