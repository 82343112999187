import { RouteInfo } from './leftsidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard', title: 'Dashboard', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
    {
        path: '/aboutus', title: 'About Us', icon: '', class: '', label: '', labelClass: 'dfdsfsdfsdfds', extralink: false, submenu: [

            {
                path: '/sankar', title: 'Sankar', icon: '', class: '', label: '', labelClass: 'mini-click-non', extralink: false, submenu: []
            }
        ]
    } 
   
];

