import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'; 
import { CommonService } from '../../_services/common.service';

@Component({
    selector: 'full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
    
})
export class FullComponent implements OnInit {

    color = 'defaultdark';
    showSettings = false;
    showMinisidebar = false; 
    showDarktheme = false;
    hideImg : boolean ;

	public config: PerfectScrollbarConfigInterface = {};

    constructor(
        public router: Router, 
        private common : CommonService,
        private cdRef:ChangeDetectorRef
        
    ) { }

    ngOnInit() {
        if (this.router.url === '/') {
            this.router.navigate(['/dashboard/dashboard1']);
        }

        this.common.homeClass.subscribe(
            res=>{
                //alert(res)
                this.hideImg = res;
            }
        )

        if(this.router.url === '/starter'){
            this.hideImg = true;
        }else{
            this.hideImg = false;
        }
    }

    ngAfterViewChecked()
    {
        //console.log( "! changement de la date du composant !" );
        //this.dateNow = new Date();
        this.cdRef.detectChanges();
    }

}
