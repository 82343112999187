import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { CommonService } from '../_services/common.service';
import { AuditService } from '../_services/audit.service';

declare var $:any
declare var jQuery:any

@Component({
    selector: 'dashboard-layout',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
    
})
export class DashboardComponent implements OnInit {
    workForm;
    language;
    color = 'defaultdark';
    showSettings = false;
    showMinisidebar = false; 
    showDarktheme = false;
    showYear:boolean = false
    formSubmit:boolean = false;
    unitData:any = [];
    YearData:any = [];
    msg;
    msgSuccess:boolean = false;
    msgError:boolean = false;
    yearId:number = 0;
    unitId;
	unitName = 'viewAll';
    unitError:boolean = false;
    yearError:boolean = false;
    public config: PerfectScrollbarConfigInterface = {};
  
    constructor(
        public router: Router,
        private common : CommonService,
        private data:AuditService,
        private route:ActivatedRoute,
    ) { }

    ngOnInit() {
        this.common.currLang.subscribe(
            res=>{
                this.language = res;
            }
		)
        if (this.router.url === '/') {
            this.router.navigate(['/dashboard/dashboard1']);
        }

        if(localStorage.getItem('ispartyRelated') == 'true'){
            this.getPopUpLoginBtn();
            this.getUnitList();
           // this.getYearList();
        }
		//this.entityChangeForm = new FormGroup({
		//	unitName: new FormControl(''),
		//});
        
    }

    getPopUpLoginBtn(){
        $(document).ready(function(){
            $('#MymodalPreventScript').modal({
                backdrop: 'static',
                keyboard: false
            });
        });
    }

    onChangeUnit(event){
        if(event == 'viewAll'){
            this.unitId = event
            this.unitError = false
        }else{
            this.unitId = event
        }
    }

    getUnitList(){
        this.data.getUnitData().subscribe(
            res=>{
                this.unitData = res
            }
        )
    }

    // getYearList(){
    //     this.data.getYearData().subscribe(
    //         res=>{
    //             this.YearData = res['entries']
    //         }
    //     )
    // }

    onYearChange(event){
        this.yearId = event;
        this.yearError = false
    }

    onSubmit(){
        
		this.unitId = this.unitName
        if(this.unitId == undefined){
            this.unitError = true
        }else if(this.unitId == 'viewAll'){
            var unitData = '';
            localStorage.setItem('viewAll','true')
            this.unitError = false
        }else{
            unitData = this.unitId;
            this.unitError = false;
            localStorage.setItem('unitId',this.unitId)
            localStorage.setItem('viewAll','false')
        }

		if(this.unitError == false){
           
			var entityObj={
				planMapId:unitData,
			}
			//console.log(entityObj);
			this.data.addEntity(entityObj).subscribe(
				data => {
					this.msg = data['message'];
					//console.log(this.msg);
                    this.msgSuccess = true;
                    localStorage.removeItem('ispartyRelated');
                    localStorage.setItem('partyRelated','false');
					setTimeout(() => {
						this.msgSuccess = false;
						jQuery("#MymodalPreventScript").modal("hide");
						
					}, this.common.timeOut);
				},
				error=>{
					this.msg = error['message'];
					//console.log(this.msg);
					this.msgError  = true
					setTimeout(() =>{
						this.msgError  = false;
					}, this.common.timeOut);
				}
			);
		}
    }
	//get unitName() {
	//	return this.entityChangeForm.get('unitName');
	//}

}
